import { shallowEqual, useSelector } from 'react-redux';
import { BaseAppState } from '../../redux';
import { NavFilters } from '../../types';

const navFiltersSelector = (state: BaseAppState): NavFilters =>
  state.nav.filters;

const useNavFiltersSelector = (): NavFilters =>
  useSelector(navFiltersSelector, shallowEqual);

export default useNavFiltersSelector;
