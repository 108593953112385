import React, { ReactNode } from 'react';
import { Select } from 'antd';
import { Manager } from '../../types';

const { Option } = Select;

export const getManagerOptions = (iterable: Manager[]): ReactNode[] => {
  return iterable.map((manager) => (
    <Option key={'manager-' + manager.id} value={manager.id}>
      {manager.firstName} {manager.lastName}
    </Option>
  ));
};
