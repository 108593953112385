import React, { useState, useMemo } from 'react';
import {
  Col,
  Table,
  Tag,
  Space,
  Badge,
  Tooltip,
  Input,
  Button,
  Select,
  DatePicker,
} from 'antd';
import moment from 'moment';
import { formatLocaleDate } from '../../../../../utils/dateUtils';
import { VacationRequest, VacationRequestStatuses } from '../../../types';
import deleteVacationRequestQuery from '../../../graphql/deleteVacationRequestQuery.graphql';
import userUpdateVacationRequestQuery from '../../../graphql/userUpdateVacationRequestQuery.graphql';
import { useMutation } from '@apollo/client';
import { Profile } from '../../../../melt-connect-reviews/types';
import {
  ExclamationCircleOutlined,
  EditOutlined,
  SaveOutlined,
  UndoOutlined,
} from '../../../../../kit/icons';
import { Typography } from 'antd';
import { calculateRequestedTotalDays } from '../../../utils/calculateRequestedTotalDays';
import ConfirmDialogBox from '../../../../../components/common/confirm_dialog_box';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { disableDateCondition } from '../../../utils/disableDateCondition';
import { getManagerOptions } from '../optionsManager';
import useManagers from '../../../hooks/use_managers';

const { Text } = Typography;
const { TextArea } = Input;

type DateValue = string | null;

interface InitialValues {
  startDate: DateValue;
  finishDate: DateValue;
  reason: string;
  managerId: string;
  id: number;
}
interface VacationRequestRow extends VacationRequest {
  key: number;
}

interface VacationRequestProps {
  vacationRequests: VacationRequest[];
  loading: boolean;
  profile: Profile;
  refetch: () => void;
}
const initialValues: InitialValues = {
  startDate: null,
  finishDate: null,
  reason: '',
  managerId: '',
  id: 0,
};

const parseToLocaleDate = (date: string): string =>
  formatLocaleDate(moment(date));
const colorVacationRequestState = (state: string): string => {
  let color = 'default';

  switch (state) {
    case VacationRequestStatuses.PENDING:
      color = 'default';
      break;
    case VacationRequestStatuses.APPROVED:
      color = 'success';
      break;
    case VacationRequestStatuses.DECLINED:
      color = 'error';
      break;
  }

  return color;
};

export const RequestsTable: React.VFC<VacationRequestProps> = ({
  vacationRequests,
  loading,
  refetch,
  profile,
}) => {
  const [deteleVacationRquest] = useMutation(deleteVacationRequestQuery);
  const [updateVacationRquest] = useMutation(userUpdateVacationRequestQuery);

  const { managers, loading: loadingManagers } = useManagers();
  const [editingRow, setEditingRow] = useState<number>();

  const showEdit = (id: number) => {
    setEditingRow(id);
  };
  const formSchema = Yup.object().shape({
    startDate: Yup.string().nullable().required('Dates are required'),
    finishDate: Yup.string().nullable().required('Dates are required'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      updateVacationRquest({
        variables: {
          ...values,
        },
        onCompleted: () => {
          refetch();
        },
      });
      setEditingRow(-1);
    },
    validationSchema: formSchema,
  });

  function handleDelete(payload: { id: number }) {
    deteleVacationRquest({
      variables: {
        id: payload.id,
      },
      onCompleted: () => {
        refetch();
      },
    });
  }

  const tableColumns = useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'id',
        sorter: (
          firstRow: VacationRequestRow,
          nextRow: VacationRequestRow
        ): number => firstRow.id - nextRow.id,
        defaultSortOrder: 'descend' as const,
      },
      {
        title: 'From',
        dataIndex: 'startDate',
        render: (
          startDate: VacationRequestRow['startDate'],
          data: VacationRequest
        ) => {
          if (editingRow === data.id) {
            return (
              <Col span={12}>
                <DatePicker.RangePicker
                  onChange={(_, dateString) => {
                    formik.handleChange({
                      target: {
                        name: 'startDate',
                        value: dateString[0],
                      },
                    });
                    formik.handleChange({
                      target: {
                        name: 'finishDate',
                        value: dateString[1],
                      },
                    });
                  }}
                  disabledDate={disableDateCondition}
                  defaultValue={[
                    moment(formik.values.startDate),
                    moment(formik.values.finishDate),
                  ]}
                />
                {formik.errors.startDate && formik.touched.startDate && (
                  <div style={{ color: 'red', marginTop: '1rem' }}>
                    {formik.errors.startDate}
                  </div>
                )}
              </Col>
            );
          }
          return <Text>{parseToLocaleDate(startDate)}</Text>;
        },
      },
      {
        title: 'To',
        dataIndex: 'finishDate',
        render: (
          finishDate: VacationRequestRow['finishDate'],
          data: VacationRequest
        ) => {
          if (editingRow === data.id) {
            return null;
          }
          return <Text>{parseToLocaleDate(finishDate)}</Text>;
        },
      },
      {
        title: 'Total Days',
        dataIndex: 'id',
        render: (_id: VacationRequestRow['id'], row: VacationRequest) => {
          if (editingRow === row.id) {
            return (
              <Text>
                {calculateRequestedTotalDays(
                  moment(moment(formik.values.startDate)),
                  moment(moment(formik.values.finishDate)),
                  profile?.country || 'Colombia'
                )}
              </Text>
            );
          }
          return (
            <Text>
              {calculateRequestedTotalDays(
                moment(row.startDate),
                moment(row.finishDate),
                profile?.country || 'Colombia'
              )}
            </Text>
          );
        },
      },
      {
        title: 'Manager',
        dataIndex: 'manager',
        render: (
          field: VacationRequestRow['manager'],
          data: VacationRequestRow
        ) => {
          if (editingRow === data.id) {
            return (
              <Select
                defaultActiveFirstOption={true}
                value={formik.values.managerId}
                onChange={(manager) => {
                  formik.handleChange({
                    target: {
                      name: 'managerId',
                      value: manager,
                    },
                  });
                }}
                loading={loadingManagers}
                disabled={loadingManagers}
              >
                {getManagerOptions(managers)}
              </Select>
            );
          }
          return (
            <Text>
              {field.firstName} {field.lastName}
            </Text>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'state',
        render: (
          field: VacationRequestRow['state'],
          row: VacationRequestRow
        ) => (
          <Space size="middle">
            <Badge
              count={
                row.feedback ? (
                  <Tooltip placement="bottom" title={row.feedback}>
                    <ExclamationCircleOutlined style={{ color: 'gold' }} />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <Tag color={colorVacationRequestState(field)}>{field}</Tag>
            </Badge>
          </Space>
        ),
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        render: (
          reason: VacationRequestRow['reason'],
          data: VacationRequestRow
        ) => {
          if (editingRow === data.id) {
            return (
              <TextArea
                name="reason"
                onChange={(reason) => {
                  formik.handleChange(reason);
                }}
                value={formik.values.reason}
              ></TextArea>
            );
          }
          return <Text style={{ textTransform: 'capitalize' }}> {reason}</Text>;
        },
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id: VacationRequestRow['id'], row: VacationRequest) => {
          if (
            row.state === VacationRequestStatuses.PENDING &&
            editingRow === id
          ) {
            return (
              <>
                <Tooltip title="Save" color={'#26bacc'}>
                  <Button type="text" onClick={() => formik.submitForm()}>
                    <SaveOutlined
                      style={{
                        color: '#26bacc',
                        marginRight: '1rem',
                      }}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Undo" color={'#e73125'}>
                  <UndoOutlined
                    onClick={() => showEdit(NaN)}
                    style={{
                      color: '#e73125',
                    }}
                  />
                </Tooltip>
              </>
            );
          } else if (row.state === VacationRequestStatuses.PENDING) {
            return (
              <>
                <Tooltip title="Edit" color={'#fdb619'}>
                  <EditOutlined
                    onClick={() => {
                      showEdit(id);
                      formik.setValues({
                        id: row.id,
                        startDate: row.startDate,
                        finishDate: row.finishDate,
                        managerId: row.manager.id.toString(),
                        reason: row.reason || '',
                      });
                    }}
                    style={{ color: '#fdb619', marginRight: '1rem' }}
                  />
                </Tooltip>
                <ConfirmDialogBox task={() => handleDelete({ id: id })} />
              </>
            );
          }
          return null;
        },
      },
    ],
    [editingRow, formik.values, formik.errors]
  );
  const tableData: VacationRequestRow[] = useMemo(
    () =>
      vacationRequests.map((props) => ({
        ...props,
        key: props.id,
      })),
    [vacationRequests]
  );

  return (
    <Col span={24} style={{ marginTop: '1rem' }}>
      <h2>My Requests</h2>
      <form onSubmit={formik.handleSubmit}>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          loading={loading}
        />
      </form>
    </Col>
  );
};
