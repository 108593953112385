// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:f2334864-ec25-4614-9e71-6d6416e3e270',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_w8bvA41NR',
  aws_user_pools_web_client_id: 'q997j2vp15378jkv7s854cksc',
  oauth: {
    domain: 'melt-network-auth-production.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    responseType: 'code',
    redirectSignIn: 'https://connect.meltstudio.co/',
    redirectSignOut: 'https://connect.meltstudio.co/',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint:
    'https://k4mvu5vxvbhu7dz4wi3nmffvzm.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_content_delivery_bucket: 'melt-network-hosting-production',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url:
    'https://melt-network-hosting-production.s3.amazonaws.com',
  aws_user_files_s3_bucket: 'melt-network-storage-production',
  aws_user_files_s3_bucket_region: 'us-east-1',
  defaultHandbookTopicId: 'general',
  strapi: {
    uri: 'https://ssl.api.connect.meltstudio.co',
    graphQlEndpoint: 'https://ssl.api.connect.meltstudio.co/graphql',
  },
};

export default awsmobile;
