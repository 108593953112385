import { takeEvery, ForkEffect, put, PutEffect } from 'redux-saga/effects';
import { setFilters, PROFILE_SET_FILTERS } from './actions';
import { SagaCreator } from '../../../settings';

const createSaga: SagaCreator = ({ setUrlFilters }) => {
  function* setFiltersSaga({
    payload,
  }: ReturnType<typeof setFilters>): Generator<PutEffect> {
    try {
      yield put(setUrlFilters(payload));
    } catch (e) {
      throw new Error(e as string);
    }
  }

  function* peopleSaga(): Generator<ForkEffect, void, void> {
    yield takeEvery(PROFILE_SET_FILTERS, setFiltersSaga);
  }
  return peopleSaga;
};

export default createSaga;
