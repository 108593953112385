import React from 'react';
import moment from 'moment';
import { Spin, Alert, Typography, List, Avatar } from 'antd';
import { Profile } from '../../types';
import { DEFAULT_AVATAR } from '../../../../kit/images';
import useBirthdays from '../../hooks/use_birthday';

const { Text } = Typography;

const ThisMonthsBirthdays: React.FC = () => {
  const { birthdays, loading, error } = useBirthdays();

  if (loading) return <Spin />;
  if (error) return <Alert message={error} type="error" />;
  if (!birthdays || !birthdays.length) {
    return <Text type="secondary">No known birthdays this month</Text>;
  }

  const getAvatar = (profile: Profile) => profile.avatar?.url ?? DEFAULT_AVATAR;

  return (
    <List size="small">
      {birthdays
        .slice()
        .sort((a, b) => moment(a.birthday).date() - moment(b.birthday).date())
        .map((profile: Profile, idx: number) => (
          <List.Item
            key={idx}
            extra={
              moment(profile.birthday).isSame(moment(), 'day') ? '🥳 🎂' : ''
            }
          >
            <List.Item.Meta
              avatar={<Avatar src={getAvatar(profile)} />}
              title={
                <a
                  href={`/u/${profile.id}`}
                >{`${profile.firstName} ${profile.lastName}`}</a>
              }
              description={`🗓  ${moment(profile.birthday).format('MMMM DD')}`}
            />
          </List.Item>
        ))}
    </List>
  );
};

export default ThisMonthsBirthdays;
