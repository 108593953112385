import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Spin, Alert } from 'antd';
import { ArrowRightOutlined } from '../../../../kit/icons';
import ReviewCard from '../../components/review_card';
import getLatestReviews from '../../graphql/getLatestReviews.graphql';
import { useQuery } from '@apollo/client';

const { Content } = Layout;

const LatestReviews: React.FC = () => {
  const { loading, data, error } = useQuery(getLatestReviews);

  if (loading) return <Spin />;
  if (error) return <Alert message={error} type="error" />;
  const reviews = data.reviews || [];
  return (
    <Content>
      <ReviewCard reviews={reviews} />
      <br />
      <NavLink to={`/reviews/coins-table`}>
        Go to coins table <ArrowRightOutlined />
      </NavLink>
    </Content>
  );
};

export default LatestReviews;
