import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface Props {
  children: React.ReactElement;
}

const CustomThemeProvider: React.FC<Props> = ({ children }) => {
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
