import React from 'react';
import { Input } from 'antd';
import { Field } from 'redux-form';
import FieldTools, { BaseProps } from './base';

const { TextArea } = Input;

export interface Props {
  name: string;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
  normalize?: Function;
  value?: string;
}

const RenderTextField: React.FC<BaseProps> = (props) => {
  const { input, type, ...rest } = props;
  return (
    <>
      {type === 'text' ? (
        <Input {...input} {...rest} />
      ) : (
        <TextArea {...input} {...rest} />
      )}
      {FieldTools.renderErrorMessage(props)}
      {FieldTools.renderWarningMessage(props)}
    </>
  );
};

const TextField: React.FC<Props> = (props) => {
  return (
    <Field
      component={RenderTextField}
      type="text"
      normalize={props.normalize}
      {...props}
    />
  );
};

export default TextField;
