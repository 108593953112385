import { VacationRequest } from './../types';
import { useQuery, ApolloError } from '@apollo/client';
import listAdminVacationRequestsQuery from '../graphql/listAdminVacationRequestsQuery.graphql';

interface VacationRequestData {
  vacationRequests: VacationRequest[];
  loading: boolean;
  error: ApolloError | null;
  refetch: () => void;
}

const useVacationRequestManager = (managerID: string): VacationRequestData => {
  const { data, loading, error, refetch } = useQuery(
    listAdminVacationRequestsQuery,
    {
      variables: {
        managerID,
      },
    }
  );

  if (loading) {
    return {
      vacationRequests: [],
      loading,
      error: null,
      refetch,
    };
  }

  if (error) {
    return {
      vacationRequests: [],
      loading,
      error,
      refetch,
    };
  }

  if (!data) {
    return {
      vacationRequests: [],
      loading,
      error: null,
      refetch,
    };
  }

  return {
    vacationRequests: data.vacationRequests,
    loading,
    error: null,
    refetch,
  };
};

export default useVacationRequestManager;
