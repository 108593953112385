import React, { Fragment } from 'react';
import type { Review, Profile } from '../../types';
import { List, Avatar, Comment, Divider } from 'antd';
import coinLogo from '../../icons/melt-coin.png';
import { DEFAULT_AVATAR } from '../../../../kit/images';

interface Props {
  reviews: Review[];
}

const ReviewCard: React.FC<Props> = ({ reviews }) => {
  const getAvatar = (profile: Profile) => profile.avatar?.url ?? DEFAULT_AVATAR;

  return (
    <List size="small" itemLayout="vertical">
      {reviews.map((review, index) => (
        <Fragment key={index}>
          <Comment
            author={
              <>
                <div>
                  <a href={`/u/${review.fromUserId.id}`}>
                    {`${review.fromUserId.firstName} ${review.fromUserId.lastName}`}
                  </a>{' '}
                  to <Avatar src={getAvatar(review.toUserId)} />{' '}
                  <a
                    href={`/u/${review.toUserId.id}`}
                    style={{ marginLeft: 5 }}
                  >{`${review.toUserId.firstName} ${review.toUserId.lastName}`}</a>
                </div>
                <div>
                  sent {review.coins}
                  <Avatar src={coinLogo} />
                </div>
              </>
            }
            avatar={<Avatar src={getAvatar(review.fromUserId)} />}
            content={<p>{review.description}</p>}
          />
          <Divider></Divider>
        </Fragment>
      ))}
    </List>
  );
};

export default ReviewCard;
