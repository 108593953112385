export const groupBy = <T, TKey, TElement, TResult>(
  keySelector: (x: T) => TKey,
  elementSelector: (x: T) => TElement,
  resultSelector: (x: TKey, xs: TElement[]) => TResult,
  source: T[]
): TResult[] =>
  Array.from(
    source
      .reduce((map, value) => {
        const key = keySelector(value);

        const values = map.get(key) ?? [];

        return new Map([...map, [key, values.concat(elementSelector(value))]]);
      }, new Map() as ReadonlyMap<TKey, TElement[]>)
      .entries()
  ).map(([key, values]) => resultSelector(key, values));
