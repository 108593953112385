import React from 'react';
import HallOfFame from '../components/hall_of_fame';
import { Layout } from 'antd';

const { Content } = Layout;

const HallOfFameScreen: React.FC<{}> = () => {
  return (
    <Layout>
      <Layout>
        <Content style={{ height: `calc(100vh - 64px)` }}>
          <HallOfFame />
        </Content>
      </Layout>
    </Layout>
  );
};

export default HallOfFameScreen;
