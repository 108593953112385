import React, { ReactElement } from 'react';
import { List, Spin } from 'antd';

import createWidget from '../widgets';
import withSettings from '../../settings/withSettings';
import type { AppSettings } from '../../settings';
import WidgetErrorBoundary from './error-boundary';
import useAuthSelector from '../../hooks/store/use_auth_selector';

const WidgetGrid: React.FC<{ settings: AppSettings }> = ({ settings }) => {
  const user = useAuthSelector();

  const { homeWidgets } = settings;
  if (!user) return <Spin />;
  const name = user.attributes.email;
  const widgets = homeWidgets.widgets.map((spec) =>
    createWidget(spec.meta, spec.component)
  );
  return (
    <>
      <h1>Hi, {name}</h1>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          lg: 2,
          xl: 2,
          xxl: 3,
        }}
        dataSource={widgets}
        renderItem={(Widget): ReactElement => (
          <List.Item>
            <WidgetErrorBoundary>
              <Widget />
            </WidgetErrorBoundary>
          </List.Item>
        )}
      />
    </>
  );
};

export default withSettings(WidgetGrid);
