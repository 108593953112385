import { ApolloError, useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { Review } from '../types';
import getReviewsByUsernameQuery from '../graphql/getReviewsByUsernameQuery.graphql';

interface ReceivedReviews {
  receivedReviews?: Review[];
  loading: boolean;
  error: ApolloError | null;
  refetch: () => void;
}
const useReceivedReviews = (id: string): ReceivedReviews => {
  const [receivedReviews, setReceivedReviews] = useState<Review[]>();
  const { data, loading, error, refetch } = useQuery(
    getReviewsByUsernameQuery,
    {
      variables: {
        id,
      },
    }
  );

  if (error) {
    return { receivedReviews, loading, error, refetch };
  }

  useEffect(() => {
    if (data) {
      setReceivedReviews(data.reviews);
    }
  }, [data]);

  return { receivedReviews, loading, error: null, refetch };
};

export default useReceivedReviews;
