import { ApolloError, useQuery } from '@apollo/client';
import { Profile } from '../types';
import getPeopleSlotMachine from '../graphql/getPeopleSlotMachine.graphql';
import { useEffect, useState } from 'react';

interface ProfilesData {
  profiles?: Profile[];
  loading: Boolean;
  error: ApolloError | null;
}

const useSlotMachine = (): ProfilesData => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const { data, loading, error } = useQuery(getPeopleSlotMachine);

  useEffect(() => {
    if (data) {
      setProfiles(data.people);
    }
  }, [data]);

  if (loading) return { profiles, loading, error: null };
  if (error) return { profiles, loading: false, error };

  return { profiles, loading: false, error: null };
};

export default useSlotMachine;
