import MedicalReport from './widgets/medical_report';

export default {
  dependsOn: [],
  routes: [],
  homepageWidgets: [
    {
      meta: {
        title: 'Medical Leave Report',
      },
      component: MedicalReport,
    },
  ],
  store: {
    namespace: 'medical',
  },
};
