interface Params {
  [key: string]: string;
}

export function qsToObj(qs: string): Params {
  if (!qs) return {};
  return qs
    .replace(/^\?/, '')
    .split('&')
    .reduce((acc: Params, entry: string) => {
      const [key, val] = entry.split('=');
      acc[key] = val;
      return acc;
    }, {} as Params);
}

export function buildQs(params: Params): string | null {
  if (!params) return null;
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
}
