import { Routine } from 'redux-saga-routines';
import { Action } from 'redux';
import { Model } from '../types';

export interface ActionWithPayload extends Action {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

// This type is for list into redux states
export type PluralBasicState<T> = {
  loading: boolean;
  items: Array<T>;
  error: string | null;
};

export type DetailBasicState<T> = {
  loading: boolean;
  item: T | null;
  error: string | null;
};

export const detailReducer = <
  TModel extends Model,
  TAction extends ActionWithPayload
>(
  detailDraft: DetailBasicState<TModel>,
  routine: Routine,
  action: TAction
): void => {
  switch (action.type) {
    case routine.REQUEST:
      detailDraft.loading = true;
      break;
    case routine.SUCCESS:
      detailDraft.item = action.payload;
      break;
    case routine.FAILURE:
      detailDraft.error = action.payload;
      break;
    case routine.FULFILL:
      detailDraft.loading = false;
      break;
    default:
      break;
  }
};
export const listReducer = <
  TModel extends Model,
  TAction extends ActionWithPayload
>(
  listDraft: PluralBasicState<TModel>,
  routine: Routine,
  action: TAction
): void => {
  switch (action.type) {
    case routine.REQUEST:
      listDraft.loading = true;
      break;
    case routine.SUCCESS:
      listDraft.items = action.payload;
      break;
    case routine.FAILURE:
      listDraft.error = action.payload;
      break;
    case routine.FULFILL:
      listDraft.loading = false;
      break;
    default:
      break;
  }
};
