import moment, { Moment } from 'moment';
import { nationalHolidays } from './nationalHolidays';

/* calcWorkWeekBetweenDates
* Calculate days between given dates that are not weekend nor holiday
props:
  dateFrom: initial date
  dateTo: final date
returns:
  workweek days
*/
type HolidaysInRange = {
  (dateFrom: Moment, dateTo: Moment, country: string | null): Moment[];
};
type CalcWorkWeekBetweenDates = {
  (dateFrom: Moment, dateTo: Moment, country: string | null): number;
};

const isWeekend = (date: Moment): boolean =>
  ![6, 7].includes(date.isoWeekday());
export const holidaysInRange: HolidaysInRange = (dateFrom, dateTo, country) => {
  if (!country) return [];

  const holidays = nationalHolidays[country];
  const holidaysInRange = holidays.reduce(
    (filtered: Moment[], datestring: string) => {
      const date = moment(datestring); // Convert to Moment
      // Verify if in range
      if (date.isBetween(dateFrom, dateTo) && isWeekend(date)) {
        return filtered.concat(date); // Return filtered array + this element
      } else {
        return filtered;
      }
    },
    []
  );

  return holidaysInRange;
};

export const calcWorkWeekBetweenDates: CalcWorkWeekBetweenDates = (
  dateFrom,
  dateTo,
  country
) => {
  // Validate dateFrom is not higher than dateTo
  if (dateFrom > dateTo) return 0;

  let daysBetween = dateTo.diff(dateFrom, 'day') + 1; // Include initial day (+1)

  let daysOff = 0;

  // Remove weekends
  daysOff += Math.floor(daysBetween / 7) * 2; // Sabado y domingo por cada semana
  if (daysBetween % 7 != 0 && dateFrom.isoWeekday() > dateTo.isoWeekday())
    daysOff += 2; // Sabado y domingo sueltos

  // Remove holidays
  if (country) {
    // Validate holiday is in workweek
    daysOff += holidaysInRange(dateFrom, dateTo, country).length;
  }

  daysBetween -= daysOff;
  return daysBetween;
};

export const formatLocaleDate = (date: Moment): string => date.format('LL');
