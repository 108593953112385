import {
  DatePicker as OriginalDatePicker,
  LocalizationProvider,
} from '@mui/lab';
import React from 'react';
import DateAdapter from '@mui/lab/AdapterMoment';
import { TextField } from '@mui/material';
import moment from 'moment';

interface Props {
  label: string;
  value: moment.Moment;
  onChange: React.Dispatch<React.SetStateAction<moment.Moment>>;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
}

const DatePicker: React.FC<Props> = ({
  value,
  onChange,
  label,
  fullWidth = false,
  variant = 'standard',
}) => (
  <LocalizationProvider dateAdapter={DateAdapter}>
    <OriginalDatePicker
      value={value}
      label={label}
      onChange={(date) => onChange(date ?? moment())}
      renderInput={(props) => (
        <TextField {...props} fullWidth={fullWidth} variant={variant} />
      )}
    />
  </LocalizationProvider>
);

export default DatePicker;
