import React, { useCallback, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment, { Moment } from 'moment';
import { Button, Layout } from 'antd';
import { Form, DatePicker } from 'antd';
import getAllUsersCoinsQuery from '../graphql/getAllUsersCoinsQuery.graphql';
import LeaderBoardView from '../components/leaderboard';
import { TEAM_LEADER } from '../../../utils/constans';
import useCurrentprofile from '../../melt-connect-people/hooks/use_current_profile';
import { useLazyQuery } from '@apollo/client';

const { Content } = Layout;

type DateValue = Moment | null;
type DateStateProps = {
  startDate: DateValue;
  finishDate: DateValue;
};

const initialDateState: DateStateProps = {
  startDate: null,
  finishDate: null,
};
const { Item } = Form;
const LeaderBoardScreen: React.FC = () => {
  const [dateRangeFrom, setDateRangeFrom] = useState<DateValue>();
  const [dateRangeTo, setDateRangeTo] = useState<DateValue>();
  const [start, setStart] = useState(0);
  const [getAllUsersCoins, { loading }] = useLazyQuery(getAllUsersCoinsQuery, {
    variables: { from: dateRangeFrom, to: dateRangeTo, start },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
  });
  const [dateState, setDateState] = useState<DateStateProps>(initialDateState);
  const [csvArray, setCsvArray] = useState<Array<object>>([]);
  const { userProfile: profile } = useCurrentprofile();

  const handleChangeInDateRange = useCallback(
    async (dateRange: [DateValue, DateValue] | null) => {
      // Validate date range is setted
      if (!dateRange) {
        setDateState(initialDateState);
        return;
      }
      const [dateRangeFrom, dateRangeTo] = dateRange;
      // Validate vals (they are never null, it's just to satisfy TS)
      if (!dateRangeFrom || !dateRangeTo) return;
      setDateRangeFrom(dateRangeFrom);
      setDateRangeTo(dateRangeTo);
      setDateState({
        startDate: dateRangeFrom,
        finishDate: dateRangeTo,
      });

      let hasMoreCoindQuery = true;
      const coinReportsResponse = [];
      while (hasMoreCoindQuery) {
        const { data } = await getAllUsersCoins();
        if (data.currencyTransactions.length === 100) {
          setStart((prevState) => {
            return prevState + 100;
          });
        } else {
          hasMoreCoindQuery = false;
        }
        coinReportsResponse.push(...data.currencyTransactions);
      }

      const arrayExport: Array<{
        originUser: string;
        targetUser: string;
        recordType: string;
        amount: number;
        transactionDate: string;
      }> = [];

      interface MapCoinReportResponse {
        amount: number;
        recordType: string;
        created_at: string;
        originUser: { firstName: string; lastName: string };
        targetUser: { firstName: string; lastName: string };
      }

      coinReportsResponse.map((value: MapCoinReportResponse) => {
        arrayExport.push({
          originUser: `${value.originUser.firstName} ${value.originUser.lastName}`,
          targetUser: `${value.targetUser.firstName} ${value.targetUser.lastName}`,
          recordType: value.recordType,
          amount: value.amount,
          transactionDate: moment().format(value.created_at),
        });
      });

      setCsvArray(arrayExport);
    },
    [dateState]
  );

  return (
    <Layout>
      <Layout style={{ minHeight: '100vh', padding: '24px 0' }}>
        <Content
          style={{
            height: '100%',
          }}
        >
          <LeaderBoardView />
          <Form
            name="create-coin-report-request"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2rem',
              marginBottom: '-2rem',
            }}
          >
            {
              /* DATE RANGE */
              profile?.role === TEAM_LEADER && (
                <Item label="">
                  <DatePicker.RangePicker
                    value={[dateState.startDate, dateState.finishDate]}
                    onChange={handleChangeInDateRange}
                  />
                  <Button
                    loading={loading}
                    disabled={csvArray.length > 0 ? false : true}
                  >
                    <CSVLink data={csvArray} filename={'coin-report.csv'}>
                      run report
                    </CSVLink>
                  </Button>
                </Item>
              )
            }
          </Form>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LeaderBoardScreen;
