import React from 'react';

import { Avatar, Badge, Col, Row, Typography } from 'antd';

import { Profile } from '../../types';
import coinLogo from '../../icons/melt-coin.png';
import { DEFAULT_AVATAR } from '../../../../kit/images';
import { CrownOutlined } from '../../../../kit/icons';

const { Title, Text } = Typography;

export interface Props {
  person: Profile;
  coins: number;
  position: string;
}

const CardPosition: React.FC<Props> = ({ person, coins, position }) => {
  if (!person) return null;
  return (
    <Row gutter={[{}, { xs: 10, md: 10, lg: 20 }]} justify="space-around">
      <Col span={24} style={{ textAlign: 'center' }}>
        <Badge count={position} color="gold">
          <Avatar
            src={person.avatar?.url ?? DEFAULT_AVATAR}
            className={
              position === '1st'
                ? 'Leaderboad-list-person--1st'
                : 'Leaderboad-list-person'
            }
            style={{
              border: '3px solid #d89614',
              boxShadow:
                position === '1st'
                  ? '#d89614 0px 0px 6px'
                  : '0px 0px 6px #ffffff4e',
            }}
          />
          {position === '1st' && (
            <CrownOutlined className={'Leaderboard-list-crownBadge'} />
          )}
        </Badge>
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Title level={position === '1st' ? 4 : 5}>{person.firstName}</Title>
        <Text type="secondary">
          <Avatar src={coinLogo} /> {coins} coins
        </Text>
      </Col>
    </Row>
  );
};

export default CardPosition;
