import React from 'react';
import { debounce } from 'lodash';

import { Input, Typography, Layout } from 'antd';

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

export interface Props {
  filters: {
    q?: string;
  };
  setFilters: (filters: {}) => void;
}

const ProjectFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const handleChange = (field: string, value: string): void => {
    const newFilters = Object.assign({}, filters, { [field]: value });
    setFilters(newFilters);
  };

  const debouncedHandleChange = debounce(handleChange, 300);

  return (
    <Content style={{ padding: 24 }}>
      <Title level={4} type="secondary">
        Search
      </Title>
      <Search
        defaultValue={filters.q || ''}
        onChange={(value): void =>
          debouncedHandleChange('q', value.target.value)
        }
      />
    </Content>
  );
};

export default ProjectFilters;
