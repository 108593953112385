import React from 'react';
import { useLocation, Redirect, Link } from 'react-router-dom';
import { getConfig } from '../config';
import { getAuthToken } from '../redux/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthStateText } from '../redux/user/selectors';

export const config = getConfig();

const AuthCallback: React.FC = () => {
  // The ability to parse query strings was taken out of react-router-dom V4
  // because there have been requests over the years to support different
  // implementations. With that, the team decided it would be best for users
  // to decide what that implementation looks like.
  // https://github.com/ReactTraining/react-router/issues/4410

  const dispatch = useDispatch();
  const selectLoginStateText: string | null = useSelector(getAuthStateText);

  dispatch(getAuthToken(useLocation().search));

  if (selectLoginStateText === 'LOG_IN_FAILED') {
    return <h1>Log in failed.</h1>;
  } else if (selectLoginStateText === 'LOG_IN_SUCCESS') {
    return (
      <h2>
        Log in successful, you will be redirected automatically.If all else
        fails,
        <Link to={'/'}> click here. </Link>
        <Redirect to={'/'} />
      </h2>
    );
  } else if (selectLoginStateText === 'LOCAL_STORAGE_ERROR') {
    return <h2>Error saving user data to local storage.</h2>;
  } else {
    return <h2>Loading</h2>;
  }
};

export default AuthCallback;
