import { Countries } from '../types';

export const getCountries = (): Countries => [
  { name: 'Colombia', value: 'Colombia' },
  { name: 'Perú', value: 'Peru' },
  { name: 'Nicaragua', value: 'Nicaragua' },
  { name: 'Guatemala', value: 'Guatemala' },
  { name: 'Venezuela', value: 'Venezuela' },
  { name: 'USA', value: 'USA' },
  { name: 'Ecuador', value: 'Ecuador' },
  { name: 'Bolivia', value: 'Bolivia' },
];
