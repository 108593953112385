import { Card, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_AVATAR } from '../../../../../kit/images';
import { UploadedImage } from '../../../../../types';
import { Profile } from '../../../../melt-connect-people/types';

const { Title } = Typography;
const { Meta } = Card;

interface Props {
  loading: boolean;
  users: Profile[] | null;
  title: string;
}

const ProjectUsers: React.FC<Props> = ({ loading, users, title }) => {
  const getAvatar = (logo: UploadedImage | null) => logo?.url ?? DEFAULT_AVATAR;

  return (
    <Row style={{}} justify={'start'} className="Project-users">
      <Col span={23}>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <Title
            level={5}
            style={{
              borderBottom: '1px solid #cccccc1f',
              paddingBottom: '0.5rem',
            }}
          >
            {title}
          </Title>
          <Row
            gutter={[
              { xs: 10, xxl: 10 },
              { xs: 15, xxl: 15 },
            ]}
            style={{ marginLeft: '4px' }}
          >
            {users?.length || !loading ? (
              users?.map((item) => {
                return (
                  <Col key={item.id}>
                    <Link to={`/u/${item.id}`}>
                      <Card
                        hoverable
                        loading={loading}
                        style={{
                          width: 150,
                          backgroundColor: 'rgba(255,255,255, 0.1)',
                        }}
                        cover={
                          <img alt="example" src={getAvatar(item.avatar)} />
                        }
                      >
                        <Meta
                          title={item.firstName}
                          description={item.jobTitle}
                        />
                      </Card>
                    </Link>
                  </Col>
                );
              })
            ) : (
              <Col>
                <Card
                  hoverable
                  loading={loading}
                  style={{
                    width: 170,
                    backgroundColor: 'rgba(255,255,255, 0.1)',
                  }}
                  cover={<img />}
                >
                  <Meta title={'..'} description={'..'} />
                </Card>
              </Col>
            )}
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default ProjectUsers;
