import React, { useState } from 'react';
import { Build } from '@mui/icons-material';
import { Avatar, Badge } from '@mui/material';
import Uploader from '../uploader';

interface Props {
  src: string;
  onChange: (files: File) => void;
}

const AvatarUploader: React.FC<Props> = ({ src, onChange }) => {
  const [currentSrc, setCurrentSrc] = useState(src);

  const handleChangeAvatar = (files: FileList | null) => {
    if (!files) return;

    const reader = new FileReader();

    reader.onloadend = () => {
      setCurrentSrc(reader.result as string);
    };

    if (files[0]) {
      const [file] = files;
      reader.readAsDataURL(file);
      onChange(file);
    }
  };

  return (
    <div style={{ display: 'inline-block', padding: '10px' }}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Uploader
            style={{ background: 'gray', padding: '5px' }}
            accept="image/*"
            onChange={handleChangeAvatar}
          >
            <Build />
          </Uploader>
        }
      >
        <Avatar sx={{ height: '110px', width: '110px' }} src={currentSrc} />
      </Badge>
    </div>
  );
};

export default AvatarUploader;
