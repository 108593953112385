import { useState, useEffect } from 'react';
import getListEventsByProfileQuery from '../graphql/getListEventsByProfileQuery.graphql';
import { useQuery, ApolloError } from '@apollo/client';
import { useParams } from 'react-router';
import { MeltEvent } from '../../melt-connect-talks/types';

interface Talks {
  talks: MeltEvent[];
  loading: boolean;
  error: ApolloError | null;
}

const useProfileTalks = (): Talks => {
  const { id } = useParams<{ id?: string }>();
  const [talks, setTalks] = useState([]);
  const { data, loading, error } = useQuery(getListEventsByProfileQuery, {
    variables: {
      id,
    },
  });

  if (error) {
    return { talks, loading, error };
  }

  useEffect(() => {
    if (data) {
      setTalks(data.talks);
    }
  });

  return { talks, loading, error: null };
};

export default useProfileTalks;
