import React, { ReactElement, useEffect, useState } from 'react';
import {
  Avatar,
  Typography as TypographyAntd,
  Spin,
  Row,
  Col,
  Tag,
  Divider,
  Space,
  Card,
  List,
  Button,
} from 'antd';
import { GithubOutlined } from '../../../../../kit/icons';
import { Profile } from '../../../types';
import { DEFAULT_AVATAR } from '../../../../../kit/images';
import coinLogo from '../../../../melt-connect-reviews/icons/melt-coin.png';
import { MinusOutlined } from '../../../../../kit/icons';
import { Link } from 'react-router-dom';
import ProfileSkills from '../profile_skills';
import { Typography } from '@mui/material';
import nobookworm from '../../review-form/nobookworm.png';
import noevolution from '../../review-form/noevolution.png';
import nomeltalism from '../../review-form/nomeltalism.png';
import { useQuery } from '@apollo/client';
import getAchievementsByUsernameQuery from '../../../graphql/getAchievimentsByUsernameQuery.graphql';
const { Title, Text } = TypographyAntd;

interface Props {
  loading: boolean;
  profile: Profile;
}

const UserProfile: React.FC<Props> = ({ profile, loading }): ReactElement => {
  const {
    data: userAchievementsData,
    loading: userAchievementsLoading,
    error: userAchievementsError,
  } = useQuery(getAchievementsByUsernameQuery, {
    variables: {
      id: profile.id,
    },
  });

  const currentBadges = (() => {
    const userAchievements = [
      <Avatar key="1" src={noevolution} />,
      <Avatar key="2" src={nobookworm} />,
      <Avatar key="3" src={nomeltalism} />,
    ];
    if (userAchievementsLoading) {
      return [
        <Text key="achievements_loading" style={{ color: 'gray' }}>
          Loading...
        </Text>,
      ];
    }

    if (userAchievementsError) {
      return [
        <Text key="achievements_error" style={{ color: 'red' }}>
          Error loading achievements
        </Text>,
      ];
    }
    if (userAchievementsData) {
      for (const achievementTransaction of userAchievementsData.badgesTransactions) {
        const achievementId = achievementTransaction.achievement.id;
        const achievementUrl = achievementTransaction.achievement.image.url;
        userAchievements[achievementId - 1] = (
          <Avatar key={achievementId} src={achievementUrl} />
        );
      }
    }
    return userAchievements;
  })();

  if (loading)
    return (
      <div style={{ textAlign: 'center', marginTop: '2vh' }}>
        <Spin />
      </div>
    );
  const {
    firstName,
    id,
    lastName,
    organization,
    jobTitle,
    avatar,
    location,
    email,
    username,
    coins,
    projects,
    userQuestions,
    role,
    country,
    levelDescription,
    levelName,
  } = profile;

  let projectsView = null;

  if (projects) {
    const getProjectLink = (
      organization_id: number | null,
      project_id: number
    ): string => {
      if (organization_id) {
        return `/organization/${organization_id}/project/${project_id}`;
      } else {
        return `/u/${id}`;
      }
    };
    projectsView = (
      <div>
        <Typography
          variant="subtitle1"
          style={{ color: 'rgba(255, 255, 255, 0.45)' }}
        >
          Projects
        </Typography>
        <Title level={5} style={{ padding: '5px 0', margin: '0' }}>
          {projects.map((project) => (
            <Link
              key={project.name}
              to={getProjectLink(project.client?.id, project.id)}
            >
              <Tag
                style={{
                  margin: '3px',
                  fontWeight: 'bold',
                  fontSize: '13px',
                  whiteSpace: 'initial',
                }}
                icon={<GithubOutlined />}
              >
                {project.name}
              </Tag>
            </Link>
          ))}
        </Title>
      </div>
    );
  }

  const avatarUrl = avatar?.url ?? DEFAULT_AVATAR;
  const [questionCollapsed, setQuestionCollapsed] = useState(true);
  const [showOverShadow, setShowOverShadow] = useState(true);
  const getListClassName = () => {
    return (
      (userQuestions.length > 1 ? 'profile-question-container' : '') +
      (questionCollapsed ? ' profile-question-container-collapsed' : '')
    );
  };

  const isClient = () => role === 'Client';

  useEffect(() => {
    if (questionCollapsed) {
      setTimeout(() => {
        setShowOverShadow(true);
      }, 1100);
    } else {
      setShowOverShadow(false);
    }
  }, [questionCollapsed]);
  return (
    <Row
      style={{
        padding: '25px 15px',
        borderRadius: '10px',
      }}
      justify={'center'}
      className="Profile-aside"
    >
      <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'center' }}>
        <Space direction="vertical">
          <Avatar size={150} src={avatarUrl} shape={'square'} />
          <div>
            <Title level={3} style={{ textAlign: 'center' }}>
              {firstName} {lastName}
            </Title>
            <Text type="secondary">@{username}</Text>
          </div>

          <Tag
            color={isClient() ? 'cyan' : 'geekblue'}
            style={{ marginTop: '10px' }}
          >
            {jobTitle}
            <br />
          </Tag>
          <Tag
            color={isClient() ? 'cyan' : 'geekblue'}
            style={{ marginTop: '10px' }}
          >
            {currentBadges}
          </Tag>
          {levelName && levelDescription && (
            <Text type="secondary">{`${levelName} - ${levelDescription}`}</Text>
          )}
          {profile.skills?.length > 0 && (
            <ProfileSkills skills={profile.skills} />
          )}
        </Space>
      </Col>
      <Divider />
      <Col xs={24} sm={24} md={12} lg={24} style={{ padding: '10px 10px' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <Typography
              variant="subtitle1"
              style={{ color: 'rgba(255, 255, 255, 0.45)' }}
            >
              Company
            </Typography>
            <Title level={5} style={{ padding: '5px 0', margin: '0' }}>
              <Link to="/project/">{organization?.name}</Link>
            </Title>
          </div>
          {!isClient() && projects && projectsView}
          <div>
            <Typography
              variant="subtitle1"
              style={{ color: 'rgba(255, 255, 255, 0.45)' }}
            >
              Country
            </Typography>
            <Title level={5} style={{ padding: '5px 0', margin: '0' }}>
              {country}
            </Title>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ color: 'rgba(255, 255, 255, 0.45)' }}
            >
              Address
            </Typography>
            <Title level={5} style={{ padding: '5px 0', margin: '0' }}>
              {location?.location}
            </Title>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ color: 'rgba(255, 255, 255, 0.45)' }}
            >
              Email
            </Typography>
            <Title level={5} style={{ padding: '5px 0', margin: '0' }}>
              {email}
            </Title>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ color: 'rgba(255, 255, 255, 0.45)' }}
            >
              Questions
            </Typography>
            {userQuestions.length > 0 && (
              <List
                itemLayout="horizontal"
                dataSource={userQuestions}
                style={{ marginTop: '10px' }}
                className={getListClassName()}
                renderItem={(item) => (
                  <List.Item
                    onClick={() => setQuestionCollapsed(!questionCollapsed)}
                    style={{ cursor: 'pointer' }}
                  >
                    {userQuestions.length > 1 && showOverShadow && (
                      <div
                        className={
                          'overShadow' +
                          (!questionCollapsed ? ' overShadow--hidden' : '')
                        }
                      >
                        <div>
                          <p>Show more</p>
                        </div>
                      </div>
                    )}

                    <List.Item.Meta
                      avatar={<Avatar src={avatarUrl} />}
                      title={<span>{item.question}</span>}
                      description={item.answer}
                    />
                  </List.Item>
                )}
              />
            )}
            {userQuestions.length > 1 && !questionCollapsed && (
              <Row justify="center" style={{ marginTop: '20px' }}>
                <Col offset={1} span={9}>
                  <Button
                    size="small"
                    onClick={() => setQuestionCollapsed(!questionCollapsed)}
                    icon={<MinusOutlined />}
                  >
                    Hide
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </Space>
      </Col>
      {!isClient() && (
        <>
          <Divider />
          <Col span={24}>
            <Row justify="center">
              <Col style={{}}>
                <Card
                  size="small"
                  style={{
                    borderRadius: '100%',
                    width: '80px',
                    height: '80px',
                    backgroundColor: '#dadada49',
                  }}
                >
                  <Row justify={'center'}>
                    <Col span={23} style={{ textAlign: 'center' }}>
                      <Avatar size={50} src={coinLogo} />
                    </Col>
                  </Row>
                  <Text className="Profile-coins-card">{coins}</Text>
                </Card>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};

export default UserProfile;
