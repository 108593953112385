import React from 'react';
import moment from 'moment';
import { NavLink, useParams } from 'react-router-dom';
import { Player } from 'video-react';
import {
  PageHeader,
  Descriptions,
  Tag,
  Col,
  Row,
  Spin,
  Layout,
  Typography,
  Alert,
} from 'antd';
import { DEFAULT_AVATAR, DEFAULT_VIDEO_IMAGE } from '../../../../kit/images';
import getEventQuery from '../../graphql/getEventQuery.graphql';
import { useQuery } from '@apollo/client';

const { Content } = Layout;

export interface ContentProps {
  children: React.ReactChild;
  extraContent: React.ReactChild;
}

const Talk: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { loading, error, data } = useQuery(getEventQuery, {
    variables: { id: id },
  });

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="warning" />
    );
  }
  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }
  const { talk } = data;

  const authorName = talk.author
    ? `${talk.author.firstName} ${talk.author.lastName}`
    : `N/A`;
  return (
    <Content>
      <PageHeader
        ghost={false}
        onBack={(): void => window.history.back()}
        title={talk.title}
        subTitle={
          talk.author ? (
            <NavLink to={`/u/${talk.author.id}`}>By {authorName}</NavLink>
          ) : (
            <Typography>No author available</Typography>
          )
        }
        tags={<Tag color="green">Enjoy!</Tag>}
        avatar={{
          src: talk.author?.avatar?.url ?? DEFAULT_AVATAR,
        }}
      />
      <Row>
        <Col span={2} />
        <Col span={20}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={19} xl={19}>
              {talk.url ? (
                <Player source src={talk.url} poster={talk.thumbnail[0].url} />
              ) : (
                <img
                  style={{
                    alignItems: 'center',
                  }}
                  src={DEFAULT_VIDEO_IMAGE}
                  alt="content"
                  width="100%"
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={5} xl={5}>
              <Content style={{ padding: 24 }}>
                <Descriptions size="small" column={1} title={talk.title}>
                  <Descriptions.Item label="">
                    {moment(talk.beginAt).format('LL')}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions.Item label="Description">
                  {talk.description}
                </Descriptions.Item>
              </Content>
            </Col>
          </Row>
        </Col>
        <Col span={2} />
      </Row>
    </Content>
  );
};

export default Talk;
