import { Routine } from 'redux-saga-routines';
import { put, select, apply, call, Effect } from 'redux-saga/effects';
import { Model } from '../types';
import { BaseAppState } from '../redux';

interface RoutineCallArgs<T> {
  routine: Routine;
  context?: unknown;
  fn: () => Promise<T>;
}

interface RoutineCallArgsWithSelector<T, S, Q> {
  routine: Routine;
  context?: unknown;
  fn: (args: Q) => Promise<T>;
  selector: (s: BaseAppState & S) => Q;
}

export function* callRoutine<
  T extends Model | null | void,
  S extends {},
  Q = unknown
>(
  args: RoutineCallArgsWithSelector<T, S, Q> | RoutineCallArgs<T>
): Generator<Effect, void, Q> {
  try {
    yield put(args.routine.request());

    if ('selector' in args) {
      const input = yield select(args.selector);

      const data = yield apply(args.context, args.fn, [input]);

      yield put(args.routine.success(data));
    } else {
      const data = yield call([args.context, args.fn]);
      yield put(args.routine.success(data));
    }
  } catch (e) {
    yield put(args.routine.failure(e));
  } finally {
    yield put(args.routine.fulfill());
  }
}

export type RoutineGenerator = Generator<
  ReturnType<typeof callRoutine>,
  void,
  void
>;
