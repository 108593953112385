import React from 'react';
import { shallow } from 'enzyme';
import Form from '../form';

describe('Daily form', () => {
  let wrapper: ReturnType<typeof shallow>;

  it('Daily form should render without daily form 1', () => {
    <Form />;
    expect(wrapper).toMatchSnapshot();
  });
});
