import React from 'react';
import { Layout } from 'antd';
import VacationsView from '../components/dashboard';

const { Content } = Layout;

const VacationsScreen: React.VFC = () => {
  return (
    <Content style={{ minHeight: 600 }}>
      <VacationsView />
    </Content>
  );
};

export default VacationsScreen;
