export { default as SlackOutlined } from '@ant-design/icons/SlackOutlined';
export { default as GlobalOutlined } from '@ant-design/icons/GlobalOutlined';
export { default as DownOutlined } from '@ant-design/icons/DownOutlined';
export { default as UploadOutlined } from '@ant-design/icons/UploadOutlined';
export { default as GithubOutlined } from '@ant-design/icons/GithubOutlined';
export { default as ArrowRightOutlined } from '@ant-design/icons/ArrowRightOutlined';
export { default as LoadingOutlined } from '@ant-design/icons/LoadingOutlined';
export { default as LeftOutlined } from '@ant-design/icons/LeftOutlined';
export { default as RightOutlined } from '@ant-design/icons/RightOutlined';
export { default as DollarCircleOutlined } from '@ant-design/icons/DollarCircleOutlined';
export { default as EyeOutlined } from '@ant-design/icons/EyeOutlined';
export { default as ReloadOutlined } from '@ant-design/icons/ReloadOutlined';
export { default as MinusOutlined } from '@ant-design/icons/MinusOutlined';
export { default as CrownOutlined } from '@ant-design/icons/CrownOutlined';
export { default as FilterFilled } from '@ant-design/icons/FilterFilled';
export { default as CheckCircleOutlined } from '@ant-design/icons/CheckCircleOutlined';
export { default as CloseCircleOutlined } from '@ant-design/icons/CloseCircleOutlined';
export { default as DeleteFilled } from '@ant-design/icons/DeleteFilled';
export { default as EditOutlined } from '@ant-design/icons/EditOutlined';
export { default as SaveOutlined } from '@ant-design/icons/SaveOutlined';
export { default as UndoOutlined } from '@ant-design/icons/UndoOutlined';
export { default as ExclamationCircleOutlined } from '@ant-design/icons/ExclamationCircleOutlined';
