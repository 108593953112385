import { calcWorkWeekBetweenDates } from '../../../utils/dateUtils';
import type { Moment } from 'moment';

export const calculateRequestedTotalDays = (
  startDate: Moment,
  finishDate: Moment,
  country: string
): string => {
  const requestedDays = calcWorkWeekBetweenDates(
    startDate,
    finishDate,
    country
  );
  return `${requestedDays} ${requestedDays > 1 ? 'days' : 'day'}`;
};
