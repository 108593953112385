import { Badge, Col, Divider, Image, Row, Space, Typography } from 'antd';
import React from 'react';
import { ProjectInterface } from '../../../types';
import { DEFAULT_VIDEO_IMAGE } from '../../../../../kit/images';

const { Title, Text } = Typography;

interface Props {
  project: ProjectInterface | null;
}

const ProjectDetail: React.FC<Props> = ({ project }) => {
  const getLogo = project?.logo.length
    ? project.logo[0].url
    : DEFAULT_VIDEO_IMAGE;

  const getProjectStringDetail = (text?: string) =>
    text !== null ? text : '...';

  return (
    <Row
      style={{
        padding: '25px 15px',
        borderRadius: '10px',
      }}
      justify={'center'}
      className="Profile-aside"
    >
      <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'center' }}>
        <Space direction="vertical">
          <div style={{ paddingBottom: '20px' }}>
            <Image
              width={200}
              src={getLogo}
              style={{
                background: 'white',
                padding: '10px',
                borderRadius: '4px',
              }}
            />
          </div>
          <div>
            <Title level={3} style={{ textAlign: 'center' }}>
              {project?.name}
            </Title>
          </div>
        </Space>
      </Col>
      <Divider />
      <Col span={24} style={{ padding: '10px 10px' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div>
            <Text type="secondary" style={{ padding: '5px 0' }}>
              Website
            </Text>
            <Title level={5} style={{ padding: '5px 0', margin: '0' }}>
              <a
                href={project?.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getProjectStringDetail(project?.website)}
              </a>
            </Title>
          </div>
          <div>
            <Text type="secondary" style={{ padding: '5px 0' }}>
              Description
            </Text>
            <Title
              level={5}
              style={{
                padding: '5px 10px',
                margin: '8px 0',
                minHeight: '10vh',
                borderRadius: '4px',
                backgroundColor: '#d1d1d119',
              }}
            >
              {project?.description}
            </Title>
          </div>
          <div>
            <Text type="secondary" style={{ padding: '5px 0' }}>
              Stack
            </Text>
            <Title
              level={5}
              style={{
                padding: '5px 10px',
                margin: '8px 0',
                minHeight: '4vh',
                borderRadius: '4px',
                backgroundColor: '#d1d1d119',
              }}
            >
              {project?.stack.map((item) => {
                return (
                  <div key={item.name}>
                    <Badge color="blue" text={item.name} />
                  </div>
                );
              })}
            </Title>
          </div>
        </Space>
      </Col>
      <Divider />
    </Row>
  );
};

export default ProjectDetail;
