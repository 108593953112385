import { BaseAppState } from '../../../redux';
import type { PeopleState, Profile } from '../../melt-connect-people/types';
import { MeltDaily } from '../types';
import { DailyState, OwnState } from './reducer';

const getNextAndPreviousDaily = (
  dailyReposts: MeltDaily[],
  activeDaily: string
): {
  nextDaily: string | null;
  previousDaily: string | null;
} => {
  const isActiveDailyIndex = (element: MeltDaily) =>
    element.person.username === activeDaily;

  const index = dailyReposts.findIndex(isActiveDailyIndex);

  const nextIndex = index < dailyReposts.length - 1 ? index + 1 : index;

  const previousIndex = index > 0 ? index - 1 : 0;

  const nextDaily: string = dailyReposts[nextIndex].person.username;

  const previousDaily: string = dailyReposts[previousIndex].person.username;

  return {
    nextDaily: nextDaily,
    previousDaily: previousDaily,
  };
};

export const getCurrentUser = (state: PeopleState): Profile | null =>
  state.profiles.currentUserProfile.item;

export const getPreviousDaily = (
  state: BaseAppState & OwnState
): string | null => {
  const {
    daily: {
      daily: { items },
    },
    nav: {
      filters: { activeDaily },
    },
  } = state;

  if (items.length) {
    return getNextAndPreviousDaily(items, activeDaily).previousDaily;
  } else {
    return null;
  }
};

export const getNextDaily = (state: BaseAppState & OwnState): string | null => {
  const {
    daily: {
      daily: { items },
    },
    nav: {
      filters: { activeDaily },
    },
  } = state;

  if (items.length) {
    return getNextAndPreviousDaily(items, activeDaily).nextDaily;
  } else {
    return null;
  }
};

export const getDailyState = (state: OwnState): DailyState => state.daily;
