import React from 'react';
import {
  Layout,
  Breadcrumb,
  Spin,
  Alert,
  Typography,
  Divider,
  Button,
} from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import getTopicQuery from '../../graphql/getTopicQuery.graphql';
import { useQuery } from '@apollo/client';

const { Content } = Layout;
const { Title } = Typography;

const HandbookContent: React.FC = () => {
  const { slug } = useParams<{ slug?: string }>();
  const { data, loading, error } = useQuery(getTopicQuery, {
    variables: {
      slug: slug ? slug : 'general',
    },
  });

  if (loading) return <Spin size="large" tip="Loading..." />;
  if (error)
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );

  const topic = data?.topics[0] || {};

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <NavLink to="/handbook">Handbook</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <NavLink to={`/handbook/${topic.category.toLowerCase()}`}>
            {topic.category}
          </NavLink>
        </Breadcrumb.Item>
        {topic.title !== topic.category && (
          <Breadcrumb.Item>{topic.title}</Breadcrumb.Item>
        )}
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Title>{topic.title}</Title>
        <a
          href={`https://ssl.api.connect.meltstudio.co/admin/plugins/content-manager/collectionType/application::topic.topic/${topic.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="primary">Edit</Button>
        </a>
        <Divider />
        <ReactMarkdown source={topic.content} />
      </Content>
    </>
  );
};
export default HandbookContent;
