// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`User profile view should render loading spinner 1`] = `
<div
  style={
    Object {
      "marginTop": "2vh",
      "textAlign": "center",
    }
  }
>
  <Spin
    size="default"
    spinning={true}
    wrapperClassName=""
  />
</div>
`;

exports[`User profile view should render the screen 1`] = `
<Row
  className="Profile-aside"
  justify="center"
  style={
    Object {
      "borderRadius": "10px",
      "padding": "25px 15px",
    }
  }
>
  <Col
    lg={24}
    md={24}
    sm={24}
    style={
      Object {
        "textAlign": "center",
      }
    }
    xs={24}
  >
    <Space
      direction="vertical"
    >
      <Avatar
        shape="square"
        size={150}
        src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
      />
      <div>
        <Title
          level={3}
          style={
            Object {
              "textAlign": "center",
            }
          }
        >
          mockUser
           
          mockUser
        </Title>
        <Text
          type="secondary"
        >
          @
          mockUser
        </Text>
      </div>
      <Tag
        color="geekblue"
        style={
          Object {
            "marginTop": "10px",
          }
        }
      >
        JobTitle
        <br />
      </Tag>
      <Tag
        color="geekblue"
        style={
          Object {
            "marginTop": "10px",
          }
        }
      >
        <Avatar
          key="1"
          shape="circle"
          size="default"
          src="test-file-stub"
        />
        <Avatar
          key="2"
          shape="circle"
          size="default"
          src="test-file-stub"
        />
        <Avatar
          key="3"
          shape="circle"
          size="default"
          src="test-file-stub"
        />
      </Tag>
      <Text
        type="secondary"
      >
        E2 - Descripción del nivel
      </Text>
      <ProfileSkill
        skills={
          Array [
            Object {
              "id": "1",
              "name": "dummySkill",
            },
          ]
        }
      />
    </Space>
  </Col>
  <Divider />
  <Col
    lg={24}
    md={12}
    sm={24}
    style={
      Object {
        "padding": "10px 10px",
      }
    }
    xs={24}
  >
    <Space
      direction="vertical"
      style={
        Object {
          "width": "100%",
        }
      }
    >
      <div>
        <ForwardRef(Typography)
          style={
            Object {
              "color": "rgba(255, 255, 255, 0.45)",
            }
          }
          variant="subtitle1"
        >
          Company
        </ForwardRef(Typography)>
        <Title
          level={5}
          style={
            Object {
              "margin": "0",
              "padding": "5px 0",
            }
          }
        >
          <Link
            to="/project/"
          >
            mock LLC 
          </Link>
        </Title>
      </div>
      <div>
        <ForwardRef(Typography)
          style={
            Object {
              "color": "rgba(255, 255, 255, 0.45)",
            }
          }
          variant="subtitle1"
        >
          Projects
        </ForwardRef(Typography)>
        <Title
          level={5}
          style={
            Object {
              "margin": "0",
              "padding": "5px 0",
            }
          }
        >
          <Link
            key="Melt Connect"
            to="/u/mockId"
          >
            <Tag
              icon={<ForwardRef(GithubOutlined) />}
              style={
                Object {
                  "fontSize": "13px",
                  "fontWeight": "bold",
                  "margin": "3px",
                  "whiteSpace": "initial",
                }
              }
            >
              Melt Connect
            </Tag>
          </Link>
        </Title>
      </div>
      <div>
        <ForwardRef(Typography)
          style={
            Object {
              "color": "rgba(255, 255, 255, 0.45)",
            }
          }
          variant="subtitle1"
        >
          Country
        </ForwardRef(Typography)>
        <Title
          level={5}
          style={
            Object {
              "margin": "0",
              "padding": "5px 0",
            }
          }
        >
          Colombia
        </Title>
      </div>
      <div>
        <ForwardRef(Typography)
          style={
            Object {
              "color": "rgba(255, 255, 255, 0.45)",
            }
          }
          variant="subtitle1"
        >
          Address
        </ForwardRef(Typography)>
        <Title
          level={5}
          style={
            Object {
              "margin": "0",
              "padding": "5px 0",
            }
          }
        />
      </div>
      <div>
        <ForwardRef(Typography)
          style={
            Object {
              "color": "rgba(255, 255, 255, 0.45)",
            }
          }
          variant="subtitle1"
        >
          Email
        </ForwardRef(Typography)>
        <Title
          level={5}
          style={
            Object {
              "margin": "0",
              "padding": "5px 0",
            }
          }
        >
          workmate2@meltstudio.co
        </Title>
      </div>
      <div>
        <ForwardRef(Typography)
          style={
            Object {
              "color": "rgba(255, 255, 255, 0.45)",
            }
          }
          variant="subtitle1"
        >
          Questions
        </ForwardRef(Typography)>
        <List
          className=" profile-question-container-collapsed"
          dataSource={
            Array [
              Object {
                "answer": "",
                "id": 0,
                "question": "",
              },
            ]
          }
          itemLayout="horizontal"
          renderItem={[Function]}
          style={
            Object {
              "marginTop": "10px",
            }
          }
        />
      </div>
    </Space>
  </Col>
  <Divider />
  <Col
    span={24}
  >
    <Row
      justify="center"
    >
      <Col
        style={Object {}}
      >
        <Card
          size="small"
          style={
            Object {
              "backgroundColor": "#dadada49",
              "borderRadius": "100%",
              "height": "80px",
              "width": "80px",
            }
          }
        >
          <Row
            justify="center"
          >
            <Col
              span={23}
              style={
                Object {
                  "textAlign": "center",
                }
              }
            >
              <Avatar
                shape="circle"
                size={50}
                src="test-file-stub"
              />
            </Col>
          </Row>
          <Text
            className="Profile-coins-card"
          >
            100
          </Text>
        </Card>
      </Col>
    </Row>
  </Col>
</Row>
`;
