import React, {
  FormEventHandler,
  useCallback,
  forwardRef,
  useMemo,
} from 'react';
import {
  useForm,
  SubmitHandler,
  Controller,
  useFormState,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Input, Rate, Row, Col, Typography, InputNumber } from 'antd';
import { ReviewFormInput } from '../../types';
import { DollarCircleOutlined } from '../../../../kit/icons';
import { Profile } from '../../../melt-connect-reviews/types';

export interface ReviewFormProps {
  readonly onSubmit: SubmitHandler<ReviewFormInput>;
  readonly loading: boolean;
  profile: Profile;
}
const { TextArea } = Input;

const { Title } = Typography;
export const ReviewCoinsForm = forwardRef<HTMLFormElement, ReviewFormProps>(
  ({ onSubmit, loading, profile }, ref) => {
    const { handleSubmit, control, reset } = useForm<ReviewFormInput>({
      mode: 'all',
      defaultValues: {
        coins: 0,
        description: '',
      },
    });
    const validationSchema = {
      coins: { required: true, min: 1, max: 100 },
      description: {
        required: { value: true, message: 'Please enter description' },
        maxLength: {
          value: 500,
          message: 'Please enter a maximum of 500 characters',
        },
        minLength: {
          value: 50,
          message: 'Please enter at least 50 characters',
        },
      },
    };
    const { errors, isValid } = useFormState({ control });
    const isManager = useMemo(
      () => profile?.role === 'Team_Manager',
      [profile]
    );
    const onFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
      (e) => handleSubmit(onSubmit)(e).then(() => reset()),
      [onSubmit, handleSubmit]
    );
    const handleReset: FormEventHandler<HTMLFormElement> = useCallback(
      () => reset({ coins: 0, description: '' }),
      [reset]
    );
    return (
      <>
        <form
          ref={ref}
          noValidate
          autoComplete="off"
          onSubmit={onFormSubmit}
          onReset={handleReset}
        >
          <Title style={{ fontSize: 14 }} level={5}>
            Send Recognition or Feedback Using Melt-Coins:
          </Title>
          <Row gutter={[16, 16]}>
            <Col flex="auto">
              <Row gutter={[0, 16]}>
                <Col>
                  <Controller
                    name="coins"
                    control={control}
                    rules={validationSchema.coins}
                    render={({ field }) => (
                      <>
                        <Rate
                          {...field}
                          count={3}
                          disabled={loading}
                          tooltips={['1 coin', '2 coins', '3 coins']}
                          character={(index: number) => (
                            <DollarCircleOutlined key={index} />
                          )}
                        />
                        {isManager && (
                          <InputNumber
                            {...field}
                            min={1}
                            max={100}
                            type="number"
                            defaultValue={3}
                          />
                        )}
                      </>
                    )}
                  />
                </Col>
                <Col span={24}>
                  <Controller
                    name="description"
                    control={control}
                    rules={validationSchema.description}
                    render={({ field }) => (
                      <TextArea
                        style={{
                          borderRadius: '4px',
                          backgroundColor: '#68686819',
                        }}
                        {...field}
                        placeholder="Message"
                        rows={3}
                        disabled={loading}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="description"
                    errors={errors}
                    render={({ message }) => (
                      <div style={{ marginBlockStart: '5px' }}>{message}</div>
                    )}
                  />
                </Col>
                <Col span={24}>
                  <Row justify="end" gutter={[16, 16]}>
                    <Col>
                      <Button
                        htmlType="submit"
                        disabled={!isValid}
                        loading={loading}
                      >
                        {loading ? 'Sending...' : 'Send'}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </>
    );
  }
);
