import {
  listProfiles,
  loadProfile,
  ProfileActionTypes,
  loadCurrentUserProfile,
  listThisMonthsBirthdays,
  loadPeople,
  loadReviewsByProfile,
  createReview,
  loadTalksByProfile,
  loadReviewsGivenByProfile,
  loadSkills,
  updateProfile,
} from './actions';
import produce, { Draft } from 'immer';

import { Role, Profile, Review, Skill } from '../types';

import {
  listReducer,
  detailReducer,
  DetailBasicState,
  PluralBasicState,
} from '../../../kit/reducers';
import { MeltEvent } from '../../melt-connect-talks/types';

export interface ProfileState {
  readonly profile: DetailBasicState<Profile>;
  readonly roles: Array<Role>;
  readonly list: PluralBasicState<Profile>;
  readonly currentUserProfile: DetailBasicState<Profile>;
  readonly birthdays: PluralBasicState<Profile>;
  readonly reviews_recived: PluralBasicState<Review>;
  readonly reviews_given: PluralBasicState<Review>;
  readonly reviewForm: DetailBasicState<Review>;
  readonly talks: PluralBasicState<MeltEvent>;
  readonly skills: PluralBasicState<Skill>;
}

export type OwnState = { profiles: ProfileState };

export const INITIAL_STATE: ProfileState = {
  profile: {
    loading: false,
    item: null,
    error: null,
  },
  roles: [
    { name: 'All', id: 'all' },
    { name: 'Team', id: 'Team' },
    { name: 'Team_Manager', id: 'Team_Manager' },
    { name: 'Client', id: 'Client' },
  ],
  list: {
    loading: false,
    items: [],
    error: null,
  },
  reviews_recived: {
    loading: false,
    items: [],
    error: null,
  },
  reviews_given: {
    loading: false,
    items: [],
    error: null,
  },
  talks: {
    loading: false,
    items: [],
    error: null,
  },
  currentUserProfile: {
    item: null,
    error: null,
    loading: false,
  },
  birthdays: {
    loading: false,
    items: [],
    error: null,
  },
  reviewForm: {
    loading: false,
    item: null,
    error: null,
  },
  skills: {
    loading: false,
    items: [],
    error: null,
  },
};

export default produce(
  (draft: Draft<ProfileState>, action: ProfileActionTypes): void => {
    listReducer(draft.list, listProfiles, action);
    detailReducer(draft.profile, loadProfile, action);
    detailReducer(draft.currentUserProfile, loadCurrentUserProfile, action);
    listReducer(draft.birthdays, listThisMonthsBirthdays, action);
    listReducer(draft.list, loadPeople, action);
    listReducer(draft.list, loadPeople, action);
    listReducer(draft.reviews_recived, loadReviewsByProfile, action);
    listReducer(draft.reviews_given, loadReviewsGivenByProfile, action);
    listReducer(draft.talks, loadTalksByProfile, action);
    detailReducer(draft.reviewForm, createReview, action);
    listReducer(draft.skills, loadSkills, action);
    detailReducer(draft.currentUserProfile, updateProfile, action);
  },
  INITIAL_STATE
);
