import React from 'react';
import { debounce } from 'lodash';

import { useDispatch } from 'react-redux';
import { Input, Typography, Layout } from 'antd';
import { setFilters } from '../../store/actions';
import useNavFiltersSelector from '../../../../hooks/store/use_nav_filters_selector';

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

const PeopleListFilters: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useNavFiltersSelector();

  const handleChange = (field: string, value: string): void => {
    const newFilters = Object.assign({}, filters, { [field]: value });
    dispatch(setFilters(newFilters));
  };

  const debouncedHandleChange = debounce(handleChange, 300);

  return (
    <Content style={{ padding: 24 }}>
      <Title level={4} type="secondary">
        Search
      </Title>
      <Search
        defaultValue={filters.q || ''}
        onChange={(value): void =>
          debouncedHandleChange('q', value.target.value)
        }
      />
    </Content>
  );
};

export default PeopleListFilters;
