import React, { ReactElement, ReactNode } from 'react';
import { debounce } from 'lodash';
import { Alert, List, Spin, Select, Input, Typography, Row } from 'antd';
import PersonCard from './card';
import { Profile, Role } from '../../../types';
import useProfiles from '../../../hooks/use_profiles';
import { Content } from 'antd/lib/layout/layout';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';
const { Option } = Select;
const { Title } = Typography;
const { Search } = Input;
import useNavFiltersSelector from '../../../../../hooks/store/use_nav_filters_selector';
import useRolesSelector from '../../../hooks/store/use_roles_selector';
import { useDispatch } from 'react-redux';
import { Skill } from '../../../../melt-connect-people/types';
import { setFilters } from '../../../store/actions';
import useSkills from '../../../hooks/use_skills';

const PeopleList: React.FC = () => {
  const { profiles, error } = useProfiles();
  const { skills, loading } = useSkills();
  const dispatch = useDispatch();
  const roles = useRolesSelector();
  const filters = useNavFiltersSelector();
  const handleChange = (field: string, value: string | number): void => {
    const newFilters = Object.assign({}, filters, { [field]: value });
    dispatch(setFilters(newFilters));
  };

  const debouncedHandleChange = debounce(handleChange, 300);
  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }
  function getOptions(iterable: Skill[] | Role[]): ReactNode[] {
    return iterable.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
  }

  return (
    <>
      <Content style={{ padding: 24 }}>
        <Row style={{ alignItems: 'flex-end' }}>
          <Title level={4} type="secondary">
            Filter by role
          </Title>
          <Select
            defaultValue={filters.role || 'all'}
            style={{
              width: '14%',
              margin: '0px 20px 0px 0px',
              display: 'flex',
              alignSelf: 'center',
            }}
            onChange={(value): void => handleChange('role', value)}
          >
            {getOptions(roles)}
          </Select>
          <br />
          <br />

          <Title level={4} type="secondary">
            Skill
          </Title>
          <Select
            defaultValue={filters.skill || 'all'}
            style={{
              width: '14%',
              margin: '0px 20px 0px 0px',
              display: 'flex',
              alignSelf: 'center',
            }}
            onChange={(value): void => handleChange('skill', value)}
          >
            {getOptions([{ id: 'all', name: 'All' }, ...skills])}
          </Select>
          <br />
          <br />

          <Title level={4} type="secondary">
            View
          </Title>
          <Select
            defaultValue={filters.view || 'list'}
            style={{
              width: '7%',
              margin: '0px 20px 0px 0px',
              display: 'flex',
              alignSelf: 'center',
            }}
            onChange={(value): void => handleChange('view', value)}
          >
            <Option key="list" value="list">
              List
            </Option>
            <Option key="map" value="map">
              Map
            </Option>
          </Select>
          <br />
          <br />

          <Title level={4} type="secondary">
            Search
          </Title>
          <Search
            style={{ width: '25%', display: 'flex', alignSelf: 'center' }}
            defaultValue={filters.q || ''}
            onChange={(value): void =>
              debouncedHandleChange('q', value.target.value)
            }
          />
        </Row>
      </Content>
      <List
        style={{
          padding: 24,
          margin: 0,
          minHeight: 600,
        }}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 3,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        loading={loading}
        dataSource={profiles}
        renderItem={(profile: Profile): ReactElement => (
          <List.Item>
            <PersonCard profile={profile} />
          </List.Item>
        )}
      />
    </>
  );
};

export default PeopleList;
