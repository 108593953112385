import React from 'react';
import { Layout } from 'antd';
import TalkList from '../components/talk_list';

const { Content } = Layout;

const TalksScreen: React.FC<{}> = () => {
  return (
    <Content
      //TODO: remove inline style
      style={{ margin: '0 24px', padding: 24, backgroundColor: '#141414' }}
    >
      <TalkList />
    </Content>
  );
};

export default TalksScreen;
