import React from 'react';

import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';

import { Layout, Alert, Spin } from 'antd';
const { Content } = Layout;

import useCurrentprofile from '../../hooks/use_current_profile';
import UserProfile from '../profile/user_profile';

const PeopleListFilters: React.FC = () => {
  const { userProfile, loading, error } = useCurrentprofile();
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }
  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }
  if (userProfile == null) {
    return (
      <Alert
        message={'profile not found'}
        description={'the profile has been not found'}
        type="error"
      />
    );
  }

  return (
    <Content>
      <UserProfile profile={userProfile} loading={loading} />
    </Content>
  );
};

export default PeopleListFilters;
