import { Profile } from './../../melt-connect-reviews/types';
import getProfilesByIdQuery from '../graphql/getProfilesByIdQuery.graphql';
import { ApolloError, useQuery } from '@apollo/client';

interface ProfileData {
  profile: Profile | null;
  loading: boolean;
  error: ApolloError | null;
}

const useProfile = (id: string): ProfileData => {
  const { data, loading, error } = useQuery(getProfilesByIdQuery, {
    variables: {
      id: Number(id),
    },
  });

  if (error) {
    return { profile: null, loading, error };
  }

  if (loading) {
    return { profile: null, loading, error: null };
  }

  if (!data) {
    return { profile: null, loading, error: null };
  }

  return { profile: data.people[0], loading, error: null };
};

export default useProfile;
