import React, { useEffect } from 'react';
import { Profile } from '../../types';
import propeller from '@albeiro/propeller';
import { Alert, Layout, Spin } from 'antd';
import getRainbowColor from '../../../../utils/colorUtils';
import useProfiles from '../../hooks/use_profiles';

const { Content } = Layout;

interface PersonProps {
  person: Profile;
  backgroundColor: string | undefined;
  borderRightWidth: number | undefined;
  labelHeight: number | undefined;
  rotateDeg: number;
}

const Person: React.FC<PersonProps> = ({
  person,
  backgroundColor,
  borderRightWidth,
  labelHeight,
  rotateDeg,
}) => {
  return (
    <div
      className="item"
      style={{
        borderTopColor: backgroundColor,
        transform: `rotate(${rotateDeg}deg)`,
        borderRightWidth: `${borderRightWidth}px`,
      }}
    >
      <p
        className="label"
        style={{ height: `${labelHeight}px`, lineHeight: `${labelHeight}px` }}
      >
        <span className="text">{person.firstName}</span>
      </p>
    </div>
  );
};

const RouletteTable: React.FC = () => {
  const { profiles, loading, error } = useProfiles();

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  const roulette = React.useRef<HTMLDivElement>(null);
  // Let's filter the profiles that only send daily report
  const filteredProfiles = profiles
    ? profiles.filter((person: Profile) => person.sendsDailyReport)
    : [];

  useEffect(() => {
    // inertia: 0 means no rotation after mouse was released
    // 1 means inifite rotation
    // https://github.com/PixelsCommander/Propeller
    new propeller(roulette.current, {
      inertia: 0.98,
    });
  }, []);

  return (
    <Content title={`Roulette`}>
      <div className="roulette roulette-items">
        <div className="box-roulette">
          <div className="markers"></div>
          <div className="roulette" ref={roulette} id="roulette">
            {filteredProfiles.map((person, idx) => (
              <Person
                person={person}
                rotateDeg={(360 / filteredProfiles.length) * idx}
                borderRightWidth={3600 / filteredProfiles.length}
                labelHeight={filteredProfiles.length * 3.6}
                backgroundColor={getRainbowColor(filteredProfiles.length, idx)}
                key={person.email}
              />
            ))}
          </div>
        </div>
      </div>
    </Content>
  );
};
export default RouletteTable;
