import moment from 'moment';

const daysInAdvance = -1;
const minDate = moment().add(daysInAdvance, 'days');
const disableWeekends = (date: moment.Moment) => date.isoWeekday() > 5;
const disableTooSoon = (date: moment.Moment): boolean => date.isBefore(minDate);
const disabledDateCondition = (date: moment.Moment): boolean =>
  disableWeekends(date) || disableTooSoon(date);

export const disableDateCondition = disabledDateCondition;
