import React from 'react';
import { Typography, Tag } from 'antd';
import { Skill } from '../../../types';

const { Title } = Typography;

interface Props {
  skills: Skill[];
}

const ProfileSkill: React.FC<Props> = ({ skills }) => {
  return (
    <div>
      <Title type="secondary" level={5}>
        Main skills
      </Title>

      {skills.map(({ name }, index) => (
        <Tag key={index}>{name}</Tag>
      ))}
    </div>
  );
};

export default ProfileSkill;
