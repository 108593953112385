import React, { ReactNode } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Spin, Typography, Timeline, Alert } from 'antd';
import { ArrowRightOutlined } from '../../../../kit/icons';
import { MeltEvent } from '../../types';
import { useQuery } from '@apollo/client';
import getUpcomingEventsQuery from '../../graphql/getUpcomingEventsQuery.graphql';
import { HolidaysCalendary } from '../../components/calendar/meltholidaydays';
import { nationalHolidays } from '../../../../utils/nationalHolidays';
import { Moment } from 'moment';

const { Text } = Typography;
const dateTime = new Date();

const UpcomingEvents: React.FC = () => {
  const { loading, error, data } = useQuery(getUpcomingEventsQuery, {
    variables: {
      sort: 'beginAt:asc',
      limit: 10,
      beginAt: dateTime.toISOString(),
    },
  });

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="warning" />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  const { talks } = data;

  const getEventLink = (event: MeltEvent): string => {
    if (event.type === 'talk') return `/talk/${event.id}`;
    return `/talk/${event.id}`;
  };

  const holidaysDays: HolidaysCalendary[] = [];
  const holidaysInMelt = nationalHolidays;
  Object.keys(holidaysInMelt).forEach((key) => {
    holidaysInMelt[key].forEach((item) => {
      const actuallyHoliday = {
        id: item + ` ${key}`,
        title: key,
        description: `Holiday in ${key}`,
        beginAt: item,
        endAt: item,
        type: '',
        sort: 'beginAt:asc',
        limit: 5,
      };
      holidaysDays.push(actuallyHoliday);
    });
  });
  const dateHolidayFilter = (
    date: Moment,
    holidaysDay: HolidaysCalendary
  ): boolean => {
    const eventHolidayDate = new Date(Date.parse(holidaysDay.beginAt));

    return (
      eventHolidayDate.getFullYear() == date.year() &&
      eventHolidayDate.getMonth() == date.month()
    );
  };
  const getListDataOfHolidays = (date: Moment): HolidaysCalendary[] => {
    return holidaysDays.filter((holidaysDay) =>
      dateHolidayFilter(date, holidaysDay)
    );
  };

  const getEventLinkOfHolidays = () => {
    return `/calendar`;
  };
  const dateHolydaysRender = (date: Moment): ReactNode => {
    const listHolydayData = getListDataOfHolidays(date).sort(
      (a, b) =>
        new Date(Date.parse(a.beginAt)).getDate() -
        new Date(Date.parse(b.beginAt)).getDate()
    );
    return (
      <>
        {listHolydayData.map((item, idx: number) => {
          const text =
            (moment(item.beginAt).isSame(moment(), 'day') ? '🔥' : '') +
            `${item.title} (${moment(item.beginAt).format('l')})`;
          return (
            <Timeline.Item color="green" key={idx}>
              <NavLink to={getEventLinkOfHolidays}>{text}</NavLink>
            </Timeline.Item>
          );
        })}
      </>
    );
  };
  return (
    <>
      <Timeline mode="left">
        {dateHolydaysRender(moment())}
        {talks.map((item: MeltEvent, idx: number) => {
          const text =
            (moment(item.beginAt).isSame(moment(), 'day') ? '🔥' : '') +
            `${item.title} (${moment(item.beginAt).format('l')})`;
          return (
            <Timeline.Item color="green" key={idx}>
              <NavLink to={getEventLink(item)}>{text}</NavLink>
            </Timeline.Item>
          );
        })}
      </Timeline>
      <br />
      <Text>
        <NavLink to="/calendar">
          All events <ArrowRightOutlined />
        </NavLink>
      </Text>
    </>
  );
};

export default UpcomingEvents;
