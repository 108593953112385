import { Manager } from '../types';
import { useQuery, ApolloError } from '@apollo/client';
import listPeopleManagerQuery from '../graphql/listPeopleManagerQuery.graphql';

interface ManagerData {
  managers: Manager[];
  loading: boolean;
  error: ApolloError | null;
}

const useManagers = (): ManagerData => {
  const { data, loading, error } = useQuery(listPeopleManagerQuery);

  if (loading) {
    return {
      managers: [],
      loading,
      error: null,
    };
  }

  if (error) {
    return {
      managers: [],
      loading,
      error,
    };
  }

  return {
    managers: data.people,
    loading,
    error: null,
  };
};

export default useManagers;
