import React from 'react';
import { Layout } from 'antd';
import ProjectsComponent from '../components/projects/index';

const ProjectsScreen: React.FC<{}> = () => {
  return (
    <Layout>
      <ProjectsComponent />
    </Layout>
  );
};

export default ProjectsScreen;
