import React from 'react';
import { shallow, ShallowWrapper } from 'enzyme';
import TextField, { Props } from '../text';

const setup = (): ReturnType<typeof ShallowWrapper> => {
  const props: Props = {
    name: 'username',
    placeholder: 'Type the username...',
    readOnly: false,
  };
  const wrapper = shallow(React.createElement(TextField, props));

  return wrapper;
};

describe('TextField Component', () => {
  it('should render a component', () => {
    const wrapper = setup();

    expect(wrapper).toMatchSnapshot();
  });

  it('should be a field text type', () => {
    const wrapper = setup();

    expect(wrapper.prop('type')).toEqual('text');
    expect(wrapper.prop('name')).toEqual('username');
  });
});
