// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`TextField Component should render a component 1`] = `
<Field
  component={[Function]}
  name="username"
  placeholder="Type the username..."
  readOnly={false}
  type="text"
/>
`;
