import React, { ReactElement } from 'react';
import moment from 'moment';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { List, Avatar, Tag, Spin, Alert } from 'antd';
import { DEFAULT_AVATAR, DEFAULT_VIDEO_IMAGE } from '../../../../kit/images';
import { Profile } from '../../../melt-connect-people/types';
import getListEvents from '../../graphql/getListEventsQuery.graphql';
import { useQuery } from '@apollo/client';
import { MeltEvent } from '../../types';

interface TalkListItem {
  href: string;
  title: string;
  author: Profile;
  description: string;
  content: string;
  comingSoon: boolean;
  thumbnail: string;
}

const TalkList: React.FC = () => {
  const { page } = useParams<{ page: string }>();
  const { loading, error, data } = useQuery(getListEvents, {
    variables: {
      sort: 'beginAt:desc',
      start: Number(page) * 5 - 5,
    },
  });
  const history = useHistory();

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="warning" />
    );
  }

  const setListData = (talks: MeltEvent[]): TalkListItem[] => {
    return talks.map((talk: MeltEvent) => ({
      id: talk.id,
      href: `/talk/${talk.id}`,
      title: talk.title,
      author: talk.author,
      description: talk.description,
      content: talk.longDescription || '...',
      comingSoon: moment(talk.beginAt).isAfter(moment()),
      thumbnail:
        (talk.thumbnail.length && talk.thumbnail[0].url) || DEFAULT_VIDEO_IMAGE,
    }));
  };

  return (
    <List
      loading={loading}
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: (page): void => {
          history.push('/talks/' + page);
          window.scrollTo(0, 0);
        },
        showSizeChanger: false,
        pageSize: 5,
        defaultCurrent: Number(page),
        current: Number(page),
        total: data.talksConnection.aggregate.totalCount,
      }}
      dataSource={setListData(data.talks)}
      renderItem={(item: TalkListItem): ReactElement => {
        const avatar =
          item.author && (item.author.avatar?.url ?? DEFAULT_AVATAR);
        return (
          <List.Item
            key={item.title}
            extra={
              <NavLink to={item.href}>
                <img width={272} alt="logo" src={item.thumbnail} />
              </NavLink>
            }
          >
            <List.Item.Meta
              avatar={<Avatar size={58} src={avatar} />}
              title={
                <>
                  <NavLink to={item.href}>{item.title}</NavLink>
                  {item.comingSoon && (
                    <>
                      {' '}
                      <Tag color="green">Coming soon!</Tag>
                    </>
                  )}
                </>
              }
              description={item.description}
            />
            {item.content}
          </List.Item>
        );
      }}
    />
  );
};

export default TalkList;
