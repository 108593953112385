import React from 'react';

import { Layout } from 'antd';

const { Content, Sider } = Layout;

import PeopleView from '../components/directory';
import PeopleListFilters from '../components/directory_filters';

const PeopleScreen: React.FC = () => {
  return (
    <Layout>
      <Sider
        width={300}
        breakpoint="md"
        collapsedWidth="0"
        className="site-layout-background"
      >
        <PeopleListFilters />
      </Sider>
      <Layout style={{ minHeight: '100vh', padding: '0 0 24px 0' }}>
        <Content>
          <PeopleView />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PeopleScreen;
