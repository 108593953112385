import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, List } from 'antd';
import { Service } from '../../types';
import { useQuery } from '@apollo/client';
import getServicesQuery from '../../graphql/getServicesQuery.graphql';

const ServicesList: React.FC = () => {
  const { data, loading, error } = useQuery(getServicesQuery);

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  const { services } = data || {};
  return (
    <List
      style={{
        padding: 24,
        margin: 0,
        minHeight: 600,
      }}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 3,
        lg: 4,
      }}
      loading={loading}
      dataSource={services}
      renderItem={(service: Service): ReactElement => (
        <List.Item>
          <List.Item.Meta
            title={
              <NavLink to={service.url} target="_blank">
                {service.name}
              </NavLink>
            }
            description={service.url}
          />
        </List.Item>
      )}
    />
  );
};

export default ServicesList;
