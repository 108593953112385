import React, { useCallback, useRef, useMemo } from 'react';
import UserProfile from './user_profile';
import { ReviewFormInput, BadgeFormInput } from '../../types';
import { Row, Col, notification, Tabs, Alert } from 'antd';
import ReviewCard from '../../../melt-connect-reviews/components/review_card';
import { ReviewAchievementForm } from '../review-form/review-form-achievements';
import { ReviewCoinsForm } from '../review-form/review-form-coins';
import { SubmitHandler } from 'react-hook-form';
import ProfileTalksComponent from './profile_talks';
import { DEFAULT_AVATAR } from '../../../../kit/images';
import ProfileDetail from './profile_detail';
import ProfileSettings from './profile_settings';
import usePorfile from '../../hooks/use_profile';
import useCurrentProfile from '../../hooks/use_current_profile';
import useProfileTalks from '../../hooks/use_profile_talks';
import { useParams } from 'react-router';
import useGivenReviews from '../../hooks/use_given_reviews';
import useReceivedReviews from '../../hooks/use_received_reviews';
import { useMutation } from '@apollo/client';
import createReviewMutation from '../../graphql/createReviewMutation.graphql';
import createBadgesTransactionMutation from '../../graphql/createBadgesTransactionMutation.graphql';
import moment from 'moment';

const { TabPane } = Tabs;

const ProfileComponent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { profile, loading, error } = usePorfile(id);
  const { userProfile } = useCurrentProfile();

  const { reviewsGiven } = useGivenReviews(id);
  const { receivedReviews, refetch } = useReceivedReviews(id);
  const { talks } = useProfileTalks();
  const [
    createReview,
    { loading: loadingCreateReview, error: errorCreateReview },
  ] = useMutation(createReviewMutation);
  const [createBadgesTransaction] = useMutation(
    createBadgesTransactionMutation
  );
  const formRef = useRef<HTMLFormElement>(null);

  const isNotCurrentUserNorClient = () =>
    profile?.id !== userProfile?.id && profile?.role !== 'Client';
  const isCurrentUserLogged = () => profile?.id === userProfile?.id;

  const isManager = useMemo(
    () => userProfile?.role === 'Team_Manager',
    [userProfile]
  );

  const displayNotification = () => {
    if (!errorCreateReview) {
      notification.success({
        message: `You sent an recognition to ${profile?.firstName}!`,
      });
    } else {
      notification.error({
        message: `Cant sent an recognition to ${profile?.firstName}!`,
      });
    }
  };
  const handleSubmitBadge: SubmitHandler<BadgeFormInput> = useCallback(
    (values) => {
      for (const achievement of values.achievements) {
        createBadgesTransaction({
          variables: {
            achievement: achievement,
            targetTeamUser: profile?.id,
            originManager: userProfile?.id,
            published_at: moment(),
          },
          onCompleted: () => {
            displayNotification();
            refetch();
          },
        });
      }
    },
    [userProfile, profile]
  );
  const handleSubmit: SubmitHandler<ReviewFormInput> = useCallback(
    (values) => {
      createReview({
        variables: {
          ...values,
          targetUser: profile?.id,
          user: userProfile?.id,
          date: moment(),
        },
        onCompleted: () => {
          displayNotification();
          refetch();
        },
      });
    },
    [userProfile, profile]
  );

  const avatarUrl = profile?.avatar?.url ?? DEFAULT_AVATAR;

  const isMobile = () => window.innerWidth <= 900;

  const isTeam = profile?.role === 'Team' || profile?.role === 'Team_Manager';
  if (error) {
    return <Alert message={error.message} type="error" />;
  }
  return (
    <Row style={{ margin: '20px 0' }} justify="center">
      <Col span={12} xs={20} md={14} lg={14} xl={14} xxl={13}>
        <Row
          gutter={[{ lg: 10 }, { xs: 10, md: 10, lg: 10 }]}
          justify={'space-between'}
        >
          <Col
            span={8}
            xs={24}
            lg={8}
            className={'Cards'}
            style={{
              height: '100%',
            }}
          >
            {profile && <UserProfile profile={profile} loading={loading} />}
          </Col>
          <Col span={15} xs={24} lg={15}>
            <Row gutter={[{}, { xs: 10, md: 10, lg: 20 }]}>
              {isNotCurrentUserNorClient() && (
                <Col span={24} className="Cards">
                  {userProfile && (
                    <>
                      <Tabs>
                        <TabPane tab="Coins" key="1">
                          <ReviewCoinsForm
                            ref={formRef}
                            profile={userProfile}
                            onSubmit={handleSubmit}
                            loading={loadingCreateReview}
                          />
                        </TabPane>
                        {isManager && (
                          <TabPane tab="Achievements" key="2">
                            <ReviewAchievementForm
                              ref={formRef}
                              onSubmit={handleSubmitBadge}
                              loading={loadingCreateReview}
                            />
                          </TabPane>
                        )}
                      </Tabs>
                    </>
                  )}
                </Col>
              )}

              <Col span={24} className="Cards">
                {isTeam && (
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Profile" key="1">
                      <ProfileDetail profile={profile} />
                    </TabPane>
                    <TabPane tab="Recognitions" key="2">
                      <Tabs
                        centered
                        type="card"
                        tabPosition={isMobile() ? 'top' : 'left'}
                        defaultActiveKey="1"
                      >
                        <TabPane tab="Received" key="1">
                          {receivedReviews != null && (
                            <>
                              <ReviewCard reviews={receivedReviews} />
                              {receivedReviews.length === 0 && (
                                <span>No recognitions yet</span>
                              )}
                            </>
                          )}
                        </TabPane>
                        <TabPane tab="Given" key="2">
                          {reviewsGiven != null && (
                            <>
                              <ReviewCard reviews={reviewsGiven} />
                              {reviewsGiven.length === 0 && (
                                <span>No recognitions given yet</span>
                              )}
                            </>
                          )}
                        </TabPane>
                      </Tabs>
                    </TabPane>
                    <TabPane tab="Talks" key="3">
                      <ProfileTalksComponent talks={talks} avatar={avatarUrl} />
                    </TabPane>
                    {isCurrentUserLogged() && (
                      <TabPane tab="Settings" key="4">
                        <ProfileSettings profile={profile} />
                      </TabPane>
                    )}
                  </Tabs>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProfileComponent;
