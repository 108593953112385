import { MeltDaily } from '../types';
import getNextAndPreviousDaily from '../utils/getNextAndPreviousDaily';

const useNextDaily = (
  items: MeltDaily[],
  activeDaily: string
): string | null => {
  if (items.length) {
    return getNextAndPreviousDaily(items, activeDaily).nextDaily;
  } else {
    return null;
  }
};

export default useNextDaily;
