/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Button, DatePicker } from 'antd';
import { LeftOutlined, RightOutlined } from '../../../../../kit/icons';

const { Title } = Typography;

interface DailyHeaderProps {
  loading: boolean;
  displayDate: string;
  reloadDaily?: () => void;
}

const Header: React.FC<DailyHeaderProps> = ({
  loading,
  displayDate,
  reloadDaily,
}) => {
  const [targetDate, setTargetDate] = useState(moment(displayDate));
  const history = useHistory();

  useEffect(() => {
    history.push(`/daily/${targetDate.format('YYYY-MM-DD')}/`);
  }, [targetDate]);

  if (displayDate === null) return null;

  const date = moment(displayDate);

  const isToday = date.isSame(moment().startOf('day'));

  const title = date.format('LL') + (isToday ? ' (today)' : '');

  const getDateLink = (offset: number): string => {
    const link = date.clone().add(offset, 'days').format('YYYY-MM-DD');
    return `/daily/${link}/`;
  };

  return (
    <>
      <Row style={{ padding: '50px 0 0px 0', marginLeft: '40px' }}>
        <Col span={23}>
          <Title level={2}>{title}</Title>
          <h4>Select the date of the Daily Report that you'd like to see 👁️‍🗨️</h4>
        </Col>
      </Row>
      <Row style={{ padding: '10px 0 30px 0', marginLeft: '40px' }}>
        <Col>
          <NavLink to={getDateLink(-1)}>
            <Button>
              <LeftOutlined />
            </Button>
          </NavLink>
        </Col>

        <Col>
          <DatePicker
            onChange={(date) => {
              if (date !== null) {
                setTargetDate(date);
              }
            }}
          />
        </Col>
        <Col style={{ margin: '0 5px' }}>
          {isToday ? null : (
            <NavLink to={getDateLink(1)}>
              <Button>
                <RightOutlined />
              </Button>
            </NavLink>
          )}
        </Col>

        <Col xs={2} offset={0}>
          <Button type="primary" ghost loading={loading} onClick={reloadDaily}>
            Re-check
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
