import { Profile } from '../modules/melt-connect-people/types';

export type CoinGrouping = {
  connection: { aggregate: { sum: { amount: number } } };
  key: string;
};

export const buildLeaderBoard = (
  people: Profile[],
  coins: CoinGrouping[]
): Profile[] =>
  people
    .map((person: Profile) => ({
      ...person,
      coins:
        coins.find(({ key }: { key: string }) => key === person.id)?.connection
          .aggregate.sum.amount ?? -1,
    }))
    .sort((x, y) => y.coins - x.coins);
