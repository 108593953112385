import { ApolloError, useQuery } from '@apollo/client';
import listVacationRequestQuery from '../graphql/listVacationRequestQuery.graphql';
import { VacationRequest } from '../types';

interface VacationRequestData {
  vacationRequests: VacationRequest[];
  loading: boolean;
  error: ApolloError | null;
  refetch: () => void;
}

const useVacationRequest = (id: string | undefined): VacationRequestData => {
  const { data, loading, error, refetch } = useQuery(listVacationRequestQuery, {
    variables: {
      personID: id,
    },
    skip: !id,
  });

  if (loading) {
    return {
      vacationRequests: [],
      loading,
      error: null,
      refetch,
    };
  }

  if (error) {
    return {
      vacationRequests: [],
      loading,
      error,
      refetch,
    };
  }

  if (!data) {
    return {
      vacationRequests: [],
      loading,
      error: null,
      refetch,
    };
  }

  return {
    vacationRequests: data.vacationRequests,
    loading: false,
    error: null,
    refetch,
  };
};

export default useVacationRequest;
