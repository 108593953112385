import { ProjectManager } from '../modules/melt-connect-daily-standup/types';
import { useQuery, ApolloError } from '@apollo/client';
import listPeopleManager from '../graphql/listPeopleManagerQuery.graphql';

interface ProjectManagers {
  projectManagers: ProjectManager[];
  loading: boolean;
  error: ApolloError | null;
}

const useProjectManagers = (): ProjectManagers => {
  const { data, loading, error } = useQuery(listPeopleManager);
  if (loading) {
    return {
      projectManagers: [],
      loading,
      error: null,
    };
  }
  if (error) {
    return {
      projectManagers: [],
      loading,
      error,
    };
  }
  if (!data) {
    return {
      projectManagers: [],
      loading,
      error: null,
    };
  }
  return {
    projectManagers: data.people,
    loading,
    error: null,
  };
};

export default useProjectManagers;
