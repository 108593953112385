// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Profile Screen should render the screen 1`] = `
<Layout>
  <Layout>
    <Content
      style={
        Object {
          "minHeight": 600,
        }
      }
    >
      <ProfileComponent />
    </Content>
  </Layout>
</Layout>
`;
