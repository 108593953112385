import LeaderBoardScreen from './screens/leaderboard';
import HallOfFameScreen from './screens/hall_of_fame';
import LatestReviews from './widgets/latest_reviews';
import moment from 'moment';
import CoinLeader from './widgets/coin_leader';

const DEFAULT_YEAR = moment().year();

const DEFAULT_MONTH = moment().month();

export default {
  routes: [
    {
      exact: true,
      path: '/reviews/coins-table',
      component: LeaderBoardScreen,
      menu: {
        visibility: 1 /* ShowInMenu.AUTHD */,
        name: 'Coins Table',
      },
      data: {
        authRequired: true,
        actions: '',
        filters: {
          time: { defaultValue: 'latest' },
        },
      },
    },
    {
      exact: true,
      path: '/reviews/hall-of-fame',
      component: HallOfFameScreen,
      menu: { visibility: 0 /* ShowInMenu.NEVER */ },
      data: {
        authRequired: true,
        actions: '',
        filters: {
          year: {
            defaultValue: DEFAULT_YEAR,
          },
          month: {
            defaultValue: DEFAULT_MONTH,
          },
        },
      },
    },
  ],
  homepageWidgets: [
    {
      meta: {
        title: 'Latest recognitions',
      },
      component: LatestReviews,
      action: '',
    },
    {
      meta: {
        title: 'Coin leader',
      },
      component: CoinLeader,
      action: '',
    },
  ],
  store: {
    namespace: 'reviews',
  },
};
