import { PluralBasicState, DetailBasicState } from '../../../kit/reducers';

import { MeltDaily } from '../types';
import type { Profile } from '../../melt-connect-people/types';

export interface DailyState {
  readonly displayDate: string | null;
  readonly activeDaily: string;
  readonly daily: PluralBasicState<MeltDaily>;
  readonly detail: DetailBasicState<MeltDaily>;
  readonly missing: PluralBasicState<Profile>;
}

export type OwnState = { daily: DailyState };

export const INITIAL_STATE: DailyState = {
  displayDate: null,
  activeDaily: '0',
  daily: {
    loading: false,
    items: [],
    error: null,
  },
  missing: {
    loading: false,
    items: [],
    error: null,
  },
  detail: {
    loading: false,
    item: null,
    error: null,
  },
};
