import React from 'react';

interface Props {
  children: React.ReactNode;
  onChange: (files: FileList | null) => void;
  accept?: string;
  style?: React.CSSProperties;
}

const Uploader: React.FC<Props> = ({ children, accept, style, onChange }) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <label>
        <div style={{ cursor: 'pointer', borderRadius: '100%', ...style }}>
          {children}
        </div>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={({ target: { files } }) => onChange(files)}
          accept={accept}
        />
      </label>
    </div>
  );
};

export default Uploader;
