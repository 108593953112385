// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint:
    'https://cn3mxmbodvffjbhj4mow776rwm.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:3f0cc726-63e5-40b6-80d5-27fec6e6e1b1',
  aws_cognito_region: 'us-east-1',
  aws_content_delivery_bucket: 'melt-network-hosting-local',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url:
    'https://melt-network-hosting-local.s3.amazonaws.com',
  aws_project_region: 'us-east-1',
  aws_user_files_s3_bucket: 'melt-network-storage-local',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_V5ze3RuM1',
  aws_user_pools_web_client_id: '2o2hcfgtthfi9281kn9plndb5f',
  federationTarget: 'COGNITO_USER_POOLS',
  oauth: {
    domain: 'melt-network-auth-local.auth.us-east-1.amazoncognito.com',
    redirectSignIn: ['http://localhost:8080/'],
    redirectSignOut: ['http://localhost:8080/'],
    responseType: 'code',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
  },
  defaultHandbookTopicId: 'general',
  strapi: {
    uri: 'https://ssl.api.connect.meltstudio.co',
    graphQlEndpoint: 'https://ssl.api.connect.meltstudio.co/graphql',
  },
};

export default awsmobile;
