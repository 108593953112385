import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Modal, Form, Input, notification, DatePicker } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { Moment } from 'moment';
import {
  calcWorkWeekBetweenDates,
  formatLocaleDate,
  holidaysInRange,
} from '../../../../utils/dateUtils';
import profile from '../../../melt-connect-people/components/profile';
import moment from 'moment';
import updateVacationRequestQuery from '../../graphql/updateVacationRequestQuery.graphql';
import { useMutation } from '@apollo/client';

const { Item } = Form;
const { TextArea } = Input;
type DateValue = Moment | null;
const minimumDays = 1;
const daysInAdvance = -2;

type DateStateProps = {
  startDate: DateValue;
  finishDate: DateValue;
  requestedDays: number | null;
};

const initialDateState: DateStateProps = {
  startDate: null,
  finishDate: null,
  requestedDays: null,
};

export const FormManager: React.VFC<{
  showModal: boolean;
  closeModal: () => void;
  id?: string;
  state?: string;
  country?: string;
  startDate?: DateValue;
  finishDate?: DateValue;
  onSumitForm: () => void;
}> = ({
  showModal,
  closeModal,
  id,
  state,
  startDate,
  finishDate,
  country,
  onSumitForm,
}) => {
  const input_feedback = useRef<TextAreaRef>(null);
  const [dateState, setDateState] = useState<DateStateProps>(initialDateState);
  const minDate = moment().add(daysInAdvance, 'days');
  const [updateVacationRequest] = useMutation(updateVacationRequestQuery);
  useEffect(() => {
    if (startDate && finishDate) {
      const startDateMoment = moment(startDate);
      const finishDateMoment = moment(finishDate);

      const requestedDays = calcWorkWeekBetweenDates(
        startDateMoment,
        finishDateMoment,
        country || 'Colombia'
      );

      setDateState({
        startDate: startDateMoment,
        finishDate: finishDateMoment,
        requestedDays,
      });
    }
  }, [startDate, finishDate]);

  const disableWeekends = (date: Moment) => date.isoWeekday() > 5;
  const disableTooSoon = (date: Moment): boolean => date.isBefore(minDate);
  const disabledDateCondition = (date: Moment) =>
    disableWeekends(date) || disableTooSoon(date);
  const handleChangeInDateRange = useCallback(
    (dateRange: [DateValue, DateValue] | null) => {
      // Validate date range is setted
      if (!dateRange) {
        setDateState(initialDateState);
        return;
      }

      const [dateRangeFrom, dateRangeTo] = dateRange;
      // Validate vals (they are never null, it's just to satisfy TS)
      if (!dateRangeFrom || !dateRangeTo) return;

      const requestedDays = calcWorkWeekBetweenDates(
        dateRangeFrom,
        dateRangeTo,
        country || 'Colombia'
      );

      // Validate selected dates
      if (requestedDays < minimumDays) {
        notification.error({
          message: 'Invalid selected dates',
          description: `Please select at least ${minimumDays} days`,
        });
        return;
      }

      setDateState({
        startDate: dateRangeFrom,
        finishDate: dateRangeTo,
        requestedDays: requestedDays,
      });
    },
    [profile, dateState]
  );

  const handleClick = async () => {
    const { startDate, finishDate } = dateState;
    const startDateFormated = moment(startDate).format('YYYY-MM-DD');
    const finishDateFormated = moment(finishDate).format('YYYY-MM-DD');
    await updateVacationRequest({
      variables: {
        id,
        state,
        feedback: input_feedback.current?.resizableTextArea?.props.value || '',
        startDate: startDateFormated,
        finishDate: finishDateFormated,
      },
    });
    onSumitForm();
    closeModal();
  };

  const dateRangeHelper: React.ReactElement = useMemo(() => {
    if (
      !dateState.startDate ||
      !dateState.finishDate ||
      !dateState.requestedDays
    ) {
      return <span></span>;
    }

    // Holidays in range
    const holidays: Moment[] = holidaysInRange(
      dateState.startDate,
      dateState.finishDate,
      country || 'Colombia'
    );

    return (
      <h4>
        <b>
          {dateState.requestedDays}{' '}
          {dateState.requestedDays > 1 ? 'days' : 'day'}
        </b>
        <br />
        Your vacation starts <u>{formatLocaleDate(dateState.startDate)}</u> and
        ends <u>{formatLocaleDate(dateState.finishDate)}</u>
        <br />
        <p>
          National holidays not included in vacation:{' '}
          <u>
            {holidays.length == 0
              ? 'No holidays'
              : holidays.map(formatLocaleDate).join(', ')}
          </u>
        </p>
      </h4>
    );
  }, [dateState, profile]);

  return (
    <Modal
      title={`Are you sure you want to ${state} this request?`}
      visible={showModal}
      onOk={handleClick}
      okText="Save"
      onCancel={closeModal}
    >
      <Form name="create-vacation-request-manager">
        {/* DATE RANGE */}
        <Item label="Date">
          <DatePicker.RangePicker
            value={[dateState.startDate, dateState.finishDate]}
            onChange={handleChangeInDateRange}
            disabledDate={disabledDateCondition}
            style={{ width: '100%' }}
          />
        </Item>
        {/* Feedback */}
        <Item label="Feedback">
          <TextArea
            placeholder="Feedback"
            rows={4}
            ref={input_feedback}
          ></TextArea>
        </Item>
        <div style={{ marginLeft: '20px' }}>{dateRangeHelper}</div>
      </Form>
    </Modal>
  );
};
