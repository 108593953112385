import { ApolloError } from '@apollo/client';
import moment from 'moment';
import { calcWorkWeekBetweenDates } from '../../../utils/dateUtils';
import {
  Person,
  VacationReportRequest,
  VacationRequestStatuses,
} from '../types';
import { calculateTotalDays } from '../utils/calculateTotalDays';
import useVacationReportManager from './use_vacation_report_manager';

interface VacationsReports {
  person: Person;
  totalDays: number;
  takenDays: number;
  availableDays: number;
  key: number;
}

interface VacationRequestsSelectorInterface {
  vacationRequests: VacationsReports[] | null;
  loading: boolean;
  error: ApolloError | null;
}

const calculateVacationReport = (vacationReports: VacationReportRequest) => {
  const dataPeople = vacationReports.people;

  const dataPeopleActive = dataPeople.filter(
    (person) => person.isActive === true
  );

  const approvedVacations = vacationReports.vacationRequests.filter(
    (request) => request.state === VacationRequestStatuses.APPROVED
  );

  const dataPeopleCalculated = dataPeopleActive.map((value) => {
    //set taken days for each person
    const takenDays = approvedVacations.reduce((acc, curr) => {
      if (curr.person.id === value.id) {
        const startDate = moment(curr.startDate);
        const finishDate = moment(curr.finishDate);
        const country = dataPeople.find((person) => person.id === value.id);
        const days = calcWorkWeekBetweenDates(
          startDate,
          finishDate,
          country ? country.country : 'COLOMBIA'
        );
        return acc + days;
      }
      return acc;
    }, 0);

    return {
      person: value,
      totalDays: calculateTotalDays(value.entryDate) || 0,
      takenDays: takenDays,
      availableDays: calculateTotalDays(value.entryDate) - takenDays,
      key: value.id,
    };
  });
  return dataPeopleCalculated;
};

const useVacationReportCalculated = (): VacationRequestsSelectorInterface => {
  const { vacationReportRequestManager, error, loading } =
    useVacationReportManager();

  if (loading) {
    return {
      vacationRequests: null,
      loading,
      error: null,
    };
  }

  if (error) {
    return {
      vacationRequests: null,
      loading,
      error,
    };
  }

  return {
    vacationRequests: calculateVacationReport(vacationReportRequestManager),
    loading,
    error: null,
  };
};

export default useVacationReportCalculated;
