import VacationsScreen from './screens/vacations';
import VacationsManager from './screens/vacationsManager';
import ReportsScreen from './screens/reports';
import { loadCurrentUserProfile } from '../melt-connect-people/store/actions';

export default {
  routes: [
    {
      exact: true,
      path: '/vacations',
      component: VacationsScreen,
      menu: { visibility: 1 /* ShowInMenu.AUTHD */, name: 'Vacations' },
      data: {
        authRequired: true,
        actions: [loadCurrentUserProfile],
      },
    },
    {
      exact: true,
      path: '/vacations/request/',
      component: VacationsManager,
      menu: { visibility: 0 /* ShowInMenu.NEVER */ },
      data: {
        authRequired: true,
        actions: [loadCurrentUserProfile],
      },
    },
    {
      exact: true,
      path: '/vacations/reports/',
      component: ReportsScreen,
      menu: { visibility: 0 },
      data: {
        authRequired: true,
        actions: [loadCurrentUserProfile],
      },
    },
  ],
  store: {
    namespace: 'vacationRequests',
  },
};
