import { takeEvery, ForkEffect, put, call, delay } from 'redux-saga/effects';
import {
  loadUser,
  logOutUser,
  authSignIn,
  getAuthToken,
  setAuthText,
} from './actions';
import { callRoutine, RoutineGenerator } from '../../kit/sagas';
import { User } from '../../types';
import { getConfig } from '../../config';
import { ActionWithPayload } from '../../kit/reducers';
import { gqlClient } from '../../api';

export const config = getConfig();

const getCurrentUser = () => {
  try {
    if (localStorage.getItem('jwt') !== null) {
      const currentUser: User = {
        // Key-pair JWT is *always* created along with key-pair 'username'
        // and 'email', so checking that JWT is not null, also checks that
        // 'username' and 'email' are not null. Typescript does not allow a
        // non-null assertion on local storage, so the warning is disabled.

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        username: localStorage.getItem('username')!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        attributes: { email: localStorage.getItem('email')! },
      };

      return Promise.resolve(currentUser);
    } else {
      throw new Error('User not found');
    }
  } catch (err) {
    return Promise.reject();
  }
};

const signOut = () => {
  try {
    localStorage.removeItem('jwt');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    gqlClient.resetStore();
  } catch (err) {
    // Can't access local storage? No-op.
  }
  return Promise.resolve();
};

function* loadUserSaga(): RoutineGenerator {
  yield callRoutine({
    routine: loadUser,
    fn: getCurrentUser,
  });
}

function* logoutUserSaga(): RoutineGenerator {
  yield callRoutine({
    routine: logOutUser,
    fn: signOut,
  });
}

function* getAuthTokenSaga({ payload: token }: ActionWithPayload) {
  try {
    const response: Response = yield call(() =>
      fetch(`${config.strapi.uri}/auth/auth0/callback?id_token=${token}`)
    );
    if (response.status !== 200) {
      yield put(setAuthText('LOG_IN_FAILED'));
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: Record<string, any> = yield call(() => response.json());
    try {
      localStorage.setItem('jwt', data.jwt);
      localStorage.setItem('username', data.user.username);
      localStorage.setItem('email', data.user.email);
      yield put(setAuthText('LOG_IN_SUCCESS'));
    } catch (err) {
      yield put(setAuthText('LOCAL_STORAGE_ERROR'));
      // Can't access local storage? Uhh...
    }
    yield delay(500);
    yield put(loadUser());
  } catch (e) {
    yield put(setAuthText('LOG_IN_FAILED'));
  }
}

export default function* userSaga(): Generator<ForkEffect, void, void> {
  yield takeEvery(loadUser.TRIGGER, loadUserSaga);
  yield takeEvery(authSignIn, loadUserSaga);
  yield takeEvery(logOutUser.TRIGGER, logoutUserSaga);
  yield takeEvery(getAuthToken, getAuthTokenSaga);
}
