import React from 'react';
import { NavLink } from 'react-router-dom';
import { Player } from 'video-react';
import moment from 'moment';
import { Alert, Layout, Spin, Typography } from 'antd';
import { ArrowRightOutlined } from '../../../../kit/icons';
import getLatestEventQuery from '../../graphql/getLatestEventQuery.graphql';
import { useQuery } from '@apollo/client';

const { Content } = Layout;
const { Text, Title } = Typography;
const dateTime = new Date();

const LatestTalk: React.FC = () => {
  const { loading, error, data } = useQuery(getLatestEventQuery, {
    variables: {
      sort: 'beginAt:desc',
      limit: 1,
      beginAt: dateTime.toISOString(),
    },
  });

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="warning" />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  const talk = data.talks[0];

  return (
    <Content>
      {talk.url && (
        <>
          <Player source src={talk.url} poster={talk.thumbnail[0].url} />
          <br />
        </>
      )}
      <Title level={4}>{talk.title}</Title>
      <Text>{talk.description}</Text>
      <br />
      <Text>{moment(talk.beginAt).format('ll')}</Text>
      <br />
      <br />
      <Text>
        <NavLink to={`/talk/${talk.id}`}>
          Go to talk page <ArrowRightOutlined />
        </NavLink>
      </Text>
    </Content>
  );
};

export default LatestTalk;
