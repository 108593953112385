import React, { useContext } from 'react';
import SettingsContext from './context';

interface Props {
  children?: React.ReactNode;
}

const withSettings = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC => {
  const ComponentWithSetting = ({
    children,
    ...props
  }: Props): React.ReactElement => {
    const settings = useContext(SettingsContext);

    return (
      <WrappedComponent {...(props as P)} settings={settings}>
        {children}
      </WrappedComponent>
    );
  };
  ComponentWithSetting.displayName = `withSettings(${WrappedComponent.name})`;
  return ComponentWithSetting;
};

export default withSettings;
