import { useSelector } from 'react-redux';
import { BaseAppState } from '../../../../redux';

const dailyDisplayDateSelector = (state: BaseAppState): string | null =>
  state.daily.displayDate;

const useDailyDisplayDateSelector = (): string | null =>
  useSelector(dailyDisplayDateSelector);

export default useDailyDisplayDateSelector;
