import type { Model, UploadedImage } from '../../types';

export interface Manager extends Model {
  id: number;
  firstName: string;
  lastName: string;
}
interface PersonPatialProps extends Manager {
  country?: string;
}

export interface VacationRequest extends Model {
  id: number;
  startDate: string;
  finishDate: string;
  state: string;
  person: PersonPatialProps;
  manager: PersonPatialProps;
  reason?: string;
  feedback?: string;
}

export interface VacationRequestPayload {
  startDate: string;
  finishDate: string;
  reason?: string;
  managerId?: number;
  personId?: number;
}

// VacationRequest
export const VacationRequestStatuses = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
};

export interface Person extends Model {
  id: number;
  firstName: string;
  lastName: string;
  entryDate: string;
  country: string;
  isActive: boolean;
  avatar: {
    url: string;
  };
}

export interface Profile extends Model {
  id: string;
  avatar: UploadedImage | null;
  birthday: string | null;
  email: string;
  firstName: string;
  phoneNumber: string;
  lastName: string;
  jobTitle: string;
  location: Location | null;
  userQuestions: userQuestions[];
  organization: Organization;
  projects: Project[];
  coins: number;
  role: string;
  sendsDailyReport: boolean;
  username: string;
  isActive: boolean;
  updated_at: string;
  skills: Skill[];
  entryDate: string | null;
  hobbies: string | null;
  interests: string | null;
  workExperience: string | null;
  country: string | null;
  vacationDays: number | null;
  levelName: string | null;
  levelDescription: string | null;
}

export type userQuestions = {
  id: number;
  question: string;
  answer: string;
};
interface Project {
  id: number;
  name: string;
  client: Organization;
}

export interface Organization extends Model {
  id: number;
  name: string;
  website: string;
  logo?: string;
}

export interface Skill extends Model {
  name: string;
  id: string;
}

export interface VacationReportRequest extends Model {
  people: Person[];
  vacationRequests: VacationRequest[];
}
