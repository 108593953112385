import { Alert } from '@mui/material';
import React from 'react';

interface Props {
  children: string;
}

const Error: React.FC<Props> = ({ children }) => (
  <Alert severity="error">{children}</Alert>
);

export default Error;
