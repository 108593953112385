import React from 'react';
import { Col, Table, Avatar } from 'antd';
import { Typography } from 'antd';
import { Person } from '../../../types';
import useVacationReportCalculated from '../../../hooks/use_vacation_report_calculated';
const { Text } = Typography;

interface VacationReportRow {
  person: Person;
  totalDays: number;
  takenDays: number;
  availableDays: number;
  key: number;
}

const tableColumns = [
  {
    title: '#',
    dataIndex: 'id',
    render: (_: string, record: VacationReportRow) => (
      <Text>{`${record.person.id}`}</Text>
    ),
    defaultSortOrder: 'descend' as const,
  },
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    width: 50,
    maxWidth: 50,
    render: (_: string, record: VacationReportRow) => (
      <Avatar src={record.person.avatar} />
    ),
  },
  {
    title: 'Name',
    dataIndex: 'firstName',
    sorter: (firstRow: VacationReportRow, nextRow: VacationReportRow): number =>
      firstRow.person.firstName.localeCompare(nextRow.person.firstName),
    render: (
      _firstName: VacationReportRow['person']['firstName'],
      record: VacationReportRow
    ) => <Text>{`${record.person.firstName} ${record.person.lastName}`}</Text>,
  },
  {
    title: 'Entry Date',
    dataIndex: 'entryDate',
    render: (_: string, record: VacationReportRow) => (
      <Text>{record.person.entryDate}</Text>
    ),
  },
  {
    title: 'Total Days',
    dataIndex: 'totalDays',
    render: (totalDays: VacationReportRow['totalDays']) => (
      <Text>{totalDays}</Text>
    ),
  },
  {
    title: 'Taken Days',
    dataIndex: 'takenDays',
    sorter: (firstRow: VacationReportRow, nextRow: VacationReportRow): number =>
      firstRow.takenDays - nextRow.takenDays,
    defaultSortOrder: 'descend' as const,
    render: (takenDays: VacationReportRow['takenDays']) => (
      <Text>{takenDays}</Text>
    ),
  },
  {
    title: 'Available Days',
    dataIndex: 'availableDays',
    sorter: (firstRow: VacationReportRow, nextRow: VacationReportRow): number =>
      firstRow.availableDays - nextRow.availableDays,
    defaultSortOrder: 'descend' as const,
    render: (availableDays: VacationReportRow['availableDays']) => (
      <Text>{availableDays}</Text>
    ),
  },
];

const ReportTable: React.VFC = () => {
  const { vacationRequests, loading } = useVacationReportCalculated();

  return (
    <Col span={24} style={{ padding: '0 50px' }}>
      <h1 style={{ textAlign: 'center' }}>Reports</h1>
      <Table
        columns={tableColumns}
        pagination={false}
        dataSource={vacationRequests || []}
        loading={loading}
      />
    </Col>
  );
};

export default ReportTable;
