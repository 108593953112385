import Handbook from './screens/handbook';

export default {
  routes: [
    {
      exact: true,
      path: '/handbook/:slug?',
      component: Handbook,
      menu: {
        visibility: 1 /* ShowInMenu.AUTHD */,
        name: 'Handbook',
        link: '/handbook',
      },
      data: {
        authRequired: true,
        idFromPath: true,
        actions: '',
      },
    },
  ],
  store: {
    namespace: 'handbook',
  },
};
