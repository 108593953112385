import React, { ReactNode } from 'react';
import L, { LatLngTuple } from 'leaflet';
import { Map, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import useProfiles from '../../../hooks/use_profiles';
import { debounce } from 'lodash';
import { Alert, Row, Spin, Select, Input, Typography } from 'antd';
import { Role } from '../../../types';
import { Content } from 'antd/lib/layout/layout';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';
const { Option } = Select;
const { Title } = Typography;
const { Search } = Input;
import useNavFiltersSelector from '../../../../../hooks/store/use_nav_filters_selector';
import useRolesSelector from '../../../hooks/store/use_roles_selector';
import { useDispatch } from 'react-redux';
import { Skill } from '../../../../melt-connect-people/types';
import { setFilters } from '../../../store/actions';
import useSkills from '../../../hooks/use_skills';

const MapView: React.FC = () => {
  const { profiles, error } = useProfiles();
  const { skills, loading } = useSkills();
  const dispatch = useDispatch();
  const roles = useRolesSelector();
  const filters = useNavFiltersSelector();
  const handleChange = (field: string, value: string | number): void => {
    const newFilters = Object.assign({}, filters, { [field]: value });
    dispatch(setFilters(newFilters));
  };
  const debouncedHandleChange = debounce(handleChange, 300);
  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  function getOptions(iterable: Skill[] | Role[]): ReactNode[] {
    return iterable.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
  }
  const warningIcon = L.icon({
    iconSize: [41, 41],
    iconAnchor: [13, 41],
    iconUrl: 'https://img.icons8.com/color/344/cloud-cross.png',
    iconRetinaUrl: markerIcon2x,
  });
  const position = [6.2486, -75.5742];
  const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
  });

  const getMarkers = (): ReactNode[] => {
    if (!profiles) return [];
    return profiles.reduce((acc, p, idx) => {
      if (p.location?.latitude && p.location?.longitude) {
        const userPosition = [p.location.latitude, p.location.longitude];
        acc.push(
          <Marker key={idx} position={userPosition as LatLngTuple} icon={icon}>
            <Popup>
              {<a href={`/u/${p.id}`}>{`${p.firstName} ${p.lastName}`}</a>}
              <br />
              {p.organization?.name ?? 'UNKNOWN_ORG'}
              <br />
              {p.location.location}
            </Popup>
          </Marker>
        );
      } else if (
        p.location?.latitude == null &&
        p.location?.longitude == null &&
        filters.q.length > 0
      ) {
        const random = Math.random() * 2 + 4;
        const userPosition = [random, -74.297333];
        acc.push(
          <Marker
            key={idx}
            position={userPosition as LatLngTuple}
            icon={warningIcon}
          >
            <Popup>
              {
                <h1
                  style={{
                    color: 'red',
                  }}
                >
                  {`Location for ${p.firstName} ${p.lastName} not found 😢 `}
                  <br />
                  {
                    'this user needs to update the profile to be shown on the map '
                  }
                </h1>
              }
            </Popup>
          </Marker>
        );
      }
      return acc;
    }, [] as ReactNode[]);
  };
  return (
    <>
      <Content style={{ padding: 24 }}>
        <Row style={{ alignItems: 'flex-end' }}>
          <Title level={4} type="secondary">
            Filter by role
          </Title>
          <Select
            defaultValue={filters.role || 'all'}
            style={{
              width: '14%',
              margin: '0px 20px 0px 0px',
              display: 'flex',
              alignSelf: 'center',
            }}
            onChange={(value): void => handleChange('role', value)}
          >
            {getOptions(roles)}
          </Select>
          <br />
          <br />

          <Title level={4} type="secondary">
            Skill
          </Title>
          <Select
            defaultValue={filters.skill || 'all'}
            style={{
              width: '14%',
              margin: '0px 20px 0px 0px',
              display: 'flex',
              alignSelf: 'center',
            }}
            onChange={(value): void => handleChange('skill', value)}
          >
            {getOptions([{ id: 'all', name: 'All' }, ...skills])}
          </Select>
          <br />
          <br />

          <Title level={4} type="secondary">
            View
          </Title>
          <Select
            defaultValue={filters.view || 'list'}
            style={{
              width: '7%',
              margin: '0px 20px 0px 0px',
              display: 'flex',
              alignSelf: 'center',
            }}
            onChange={(value): void => handleChange('view', value)}
          >
            <Option key="list" value="list">
              List
            </Option>
            <Option key="map" value="map">
              Map
            </Option>
          </Select>
          <br />
          <br />

          <Title level={4} type="secondary">
            Search
          </Title>
          <Search
            style={{ width: '25%', display: 'flex', alignSelf: 'center' }}
            defaultValue={filters.q || ''}
            onChange={(value): void =>
              debouncedHandleChange('q', value.target.value)
            }
          />
        </Row>
      </Content>
      <div
        style={{
          margin: 0,
          height: '100%',
          minHeight: 600,
          padding: 0,
        }}
      >
        <Map center={position as LatLngTuple} zoom={4} zoomControl={false}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {getMarkers()}
          <ZoomControl position="topright" />
        </Map>
      </div>
    </>
  );
};

export default MapView;
