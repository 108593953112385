import React from 'react';
import moment from 'moment';
import { Profile } from '../../../types';
import ReactMarkdown from 'react-markdown';
import ProfileDetailItem from './profile_detail_item';
import ProfileDetailBiggerItem from './profile_detail_bigger_item';
import {
  Cake,
  Today,
  Work,
  Favorite,
  TravelExplore,
} from '@mui/icons-material';

interface Props {
  profile: Profile;
}

const ProfileDetail: React.FC<Props> = ({ profile }) => {
  const noResponseMessage = 'No response';

  return (
    <div>
      <div>
        <ProfileDetailItem icon={<Today />} title="Start date">
          {profile.entryDate
            ? moment(profile.entryDate).format('LL')
            : noResponseMessage}
        </ProfileDetailItem>
      </div>

      <div>
        <ProfileDetailItem icon={<Cake />} title="Birth date">
          {profile.birthday
            ? moment(profile.birthday).format('MMMM, DD')
            : noResponseMessage}
        </ProfileDetailItem>
      </div>

      <div>
        <ProfileDetailBiggerItem icon={<Work />} title="Work experience">
          <ReactMarkdown>
            {profile.workExperience ?? noResponseMessage}
          </ReactMarkdown>
        </ProfileDetailBiggerItem>
      </div>

      <div>
        <ProfileDetailBiggerItem icon={<Favorite />} title="Hobbies">
          <ReactMarkdown>{profile.hobbies ?? noResponseMessage}</ReactMarkdown>
        </ProfileDetailBiggerItem>
      </div>

      <div>
        <ProfileDetailBiggerItem icon={<TravelExplore />} title="Interests">
          <ReactMarkdown>
            {profile.interests ?? noResponseMessage}
          </ReactMarkdown>
        </ProfileDetailBiggerItem>
      </div>
    </div>
  );
};

export default ProfileDetail;
