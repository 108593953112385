import moment from 'moment';

const VACATIONS_FORMULA = 1.25;

export const calculateTotalDays = (entryDate: string): number => {
  const now = moment();
  const months = now.diff(moment(entryDate), 'months');
  const totalDays: number = months
    ? Number((VACATIONS_FORMULA * months).toFixed(2))
    : 0;
  return totalDays;
};
