import React, { ReactElement } from 'react';

export interface BaseProps {
  input: {
    name: string;
    onBlur: () => void;
    onChange: () => void;
    onFocus: () => void;
  };
  meta: {
    error?: string;
    touched: boolean;
    warning?: string;
  };
  type: string;
}

const FieldTools = {
  capitalize(lower: string): string {
    return lower.replace(/^\w/, (c) => c.toUpperCase());
  },

  format(name: string, error: string | undefined): ReactElement {
    return (
      <>
        {this.capitalize(`${name}`)} {error}
      </>
    );
  },

  renderErrorMessage(fieldProps: BaseProps): ReactElement | null {
    const {
      input: { name },
      meta: { touched, error },
    } = fieldProps;

    if (!touched || !error) {
      return null;
    }

    return (
      <span style={{ color: 'red' }} className="invalid-feedback d-block">
        {this.format(name, error)}
      </span>
    );
  },

  renderWarningMessage(fieldProps: BaseProps): ReactElement | null {
    const {
      input: { name },
      meta: { touched, error, warning },
    } = fieldProps;

    if (!touched || error || !warning) {
      return null;
    }

    return (
      <span className="form-text text-warning">{this.format(name, error)}</span>
    );
  },
};

export default FieldTools;
