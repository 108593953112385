import { Alert, Layout, Spin } from 'antd';
import React from 'react';
import useNavFiltersSelector from '../../../../hooks/store/use_nav_filters_selector';
import ProjectsCardsComponent from './projects_cards';
import ProjectFilters from './projects_filter';
import { setFilters as setFiltersAction } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { NavFilters } from '../../../../types';
import { useQuery } from '@apollo/client';
import getProjectsQuery from '../../graphql/getProjectsQuery.graphql';

const { Content, Sider } = Layout;

const ProjectsComponent: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useNavFiltersSelector();
  const setFilters = (filters: NavFilters) => {
    dispatch(setFiltersAction(filters));
  };
  const { loading, error, data } = useQuery(getProjectsQuery, {
    variables: { search: filters.q },
  });

  const organizations = data?.organizations || [];

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  return (
    <>
      <Sider
        width={300}
        breakpoint="md"
        collapsedWidth="0"
        className="site-layout-background"
      >
        <ProjectFilters filters={filters} setFilters={setFilters} />
      </Sider>
      <Content style={{ padding: 24, minHeight: '100vh' }}>
        {loading && <Spin size="large" tip="Loading..." />}
        <ProjectsCardsComponent projects={organizations} />
      </Content>
    </>
  );
};

export default ProjectsComponent;
