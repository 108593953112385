import { loadUser, logOutUser, UserActionTypes, setAuthText } from './actions';
import produce, { Draft } from 'immer';

import { User } from '../../types';

export interface UserState {
  readonly user: User | null;
  readonly authProcessStateText: string | null;
}

const INITIAL_STATE: UserState = {
  user: null,
  authProcessStateText: null,
};

export default produce(
  (draft: Draft<UserState>, action: UserActionTypes): void => {
    switch (action.type) {
      case loadUser.SUCCESS:
        draft.user = action.payload;
        break;
      case logOutUser.SUCCESS:
        draft.user = null;
        break;
      case setAuthText.toString():
        draft.authProcessStateText = action.payload;
        break;
      default:
        break;
    }
  },
  INITIAL_STATE
);
