import React, { ReactNode, useCallback } from 'react';
import { Row, Col, Spin, Typography, Alert, Avatar, Divider } from 'antd';
import Header from './header';
import DailyUserRow from './column';
import { useDispatch } from 'react-redux';
import CollapseControls from './controls';
import { DEFAULT_AVATAR } from '../../../../kit/images';
import { setDailyFilters as setDailyFiltersAction } from '../../store/actions';
import useNavFiltersSelector from '../../../../hooks/store/use_nav_filters_selector';
import useDialies from '../../hooks/use_dailes';
import { useLocation, useParams } from 'react-router';
import moment from 'moment';
import useNextDaily from '../../hooks/use_next_daily';
import usePreviousDaily from '../../hooks/use_previous_daily';
import { Profile } from '../../../melt-connect-reviews/types';
import { Filters, MeltDaily } from '../../types';
import useProjectManagers from '../../../../hooks/use_project_manager';

const { Text, Title } = Typography;

const DailyComponent: React.FC = () => {
  const { dateStr } = useParams<{ dateStr?: string }>();
  const location = useLocation();
  const activeDaily = location.search.split('=')[1];
  const displayDate = dateStr ? dateStr : moment().format('YYYY-MM-DD');
  const { dailies, loading, error, missing, projects, refetch } =
    useDialies(displayDate);

  const {
    projectManagers,
    loading: loadingProjectManagers,
    error: errorProjectManagers,
  } = useProjectManagers();

  const dispatch = useDispatch();
  const filters = useNavFiltersSelector() as Filters;
  const reloadDaily = useCallback(() => {
    refetch();
  }, [refetch]);

  const setDailyFilters = useCallback(
    (filterParams: Filters): void => {
      dispatch(setDailyFiltersAction(filterParams));
    },
    [setDailyFiltersAction]
  );
  const handleManagerClick = (projectManager: string) => {
    setDailyFilters({ ...filters, selectedManager: projectManager });
  };

  if (loading || loadingProjectManagers) {
    return <Spin />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  if (errorProjectManagers) {
    return <Alert message={errorProjectManagers} type="error" />;
  }

  const missingReports = (): ReactNode => {
    return (
      <>
        {missing.map((profile: Profile) => {
          const avatar = profile.avatar?.url ?? DEFAULT_AVATAR;
          return (
            <div key={profile.id} className="missing-profile">
              <Avatar src={avatar} />
              <Text style={{ paddingLeft: '5px', fontWeight: 'bold' }}>
                {profile.firstName} {profile.lastName}
              </Text>
            </div>
          );
        })}
      </>
    );
  };

  const renderSections = (): JSX.Element[] => {
    const selectedManager = projectManagers.find(
      (pm) => pm.username === filters.selectedManager
    );
    const projectsToShow = selectedManager
      ? selectedManager.projectsManaged.map((p) => p.name)
      : projects;

    return projectsToShow.map((project: string, idxProyect: number) => {
      const projectDailies = dailies.filter(
        (item: MeltDaily) =>
          (item.person.projects.length ? item.person.projects[0].name : '') ==
          project
      );
      if (projectDailies.length === 0) {
        return <></>;
      }

      return (
        <React.Fragment key={project}>
          <Row style={{ padding: '0 10px', margin: '50px 0 0 0' }}>
            <Col>
              <Divider
                style={{ padding: '20px 20px 5px 20px' }}
                orientation="left"
              >
                <Title level={2}>{project}</Title>
              </Divider>
            </Col>
          </Row>
          <Row gutter={20} style={{ margin: '20px 10px 0 10px' }}>
            <DailyUserRow
              items={projectDailies}
              toShow={''}
              filters={filters}
              setDailyFilters={setDailyFilters}
              idxProyect={idxProyect}
            />
          </Row>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <Header
        displayDate={displayDate}
        loading={loading}
        reloadDaily={reloadDaily}
      />
      <Row style={{ margin: '0px' }}>
        {missing.length > 0 && (
          <Col xs={23} style={{ display: 'flex', marginLeft: '40px' }}>
            <Alert
              message="⚠️ Missing reports"
              description={missingReports()}
              type="warning"
            />
          </Col>
        )}
      </Row>

      {projects.length === 0 && loading && (
        <Col xs={12} offset={12}>
          <Spin />
        </Col>
      )}

      {projects.length > 0 && renderSections()}

      <CollapseControls
        reloadDaily={reloadDaily}
        loading={loading}
        previousDaily={usePreviousDaily(dailies, activeDaily)}
        nextDaily={useNextDaily(dailies, activeDaily)}
        filters={filters}
        setDailyFilters={setDailyFilters}
        displayDate={displayDate}
        projectManagers={projectManagers}
        onClick={handleManagerClick}
      />
    </>
  );
};

export default DailyComponent;
