import React, { useState, useMemo } from 'react';
import { Col, Button, Badge } from 'antd';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { VacationFormModal } from './form';
import { NavLink } from 'react-router-dom';
import { Profile } from '../../../melt-connect-reviews/types';
import { VacationRequestStatuses } from '../../types';
import useVacationRequestManager from '../../hooks/use_vacation_resquest_manager';

interface MainProps {
  profile: Profile;
  onSumitForm: () => void;
}

export const Main: React.VFC<MainProps> = ({ profile, onSumitForm }) => {
  const [showModalRequestVacation, setShowModalRequestVacation] =
    useState<boolean>(false);
  const isManager = useMemo(() => profile?.role === 'Team_Manager', [profile]);
  const { vacationRequests, loading } = useVacationRequestManager(profile.id);

  const lengthPendingRequests: number = useMemo(
    () =>
      vacationRequests?.filter(
        (request) => request.state == VacationRequestStatuses.PENDING
      ).length,
    [vacationRequests]
  );

  const openModal = () => setShowModalRequestVacation(true);
  const closeModal = () => setShowModalRequestVacation(false);

  return (
    <React.Fragment>
      <Col span={20}>
        <h2>
          Hi, {profile.firstName} {profile.lastName}!
        </h2>
        <p>Now you can easily request vacations</p>

        <Button type="primary" icon={<PlusOutlined />} onClick={openModal}>
          Request Vacation
        </Button>

        <VacationFormModal
          profile={profile}
          showModal={showModalRequestVacation}
          closeModal={closeModal}
          onSubmit={onSumitForm}
        />
      </Col>
      <Col span={4}>
        {isManager && (
          <NavLink to="/vacations/request" style={{ float: 'right' }}>
            <Badge count={lengthPendingRequests} color="red" overflowCount={10}>
              <Button loading={loading} disabled={loading} type="primary">
                View Requests
              </Button>
            </Badge>
          </NavLink>
        )}
        {isManager && (
          <NavLink to="/vacations/reports" style={{ float: 'right' }}>
            <Badge count={lengthPendingRequests} color="red" overflowCount={10}>
              <Button loading={loading} disabled={loading} type="primary">
                View Reports
              </Button>
            </Badge>
          </NavLink>
        )}
      </Col>
    </React.Fragment>
  );
};
