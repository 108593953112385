import React, { ReactNode, useState } from 'react';
import { Card, Typography, Avatar, Empty, Row, Col } from 'antd';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { ProjectDailyInterface } from '../../../types';

const { Text } = Typography;

interface Props {
  daily: ProjectDailyInterface;
}

type AvailableTabs = 'yesterday' | 'today' | 'blockers';

const DailyCard: React.FC<Props> = ({ daily }) => {
  const [activeTabKey, setActiveTabKey] = useState<AvailableTabs>('today');

  const onTabChange = (key: string) => {
    if (key === 'yesterday' || key === 'today' || key === 'blockers')
      setActiveTabKey(key);
  };

  const tabList = [
    { key: 'yesterday', tab: 'Yesterday' },
    { key: 'today', tab: 'Today' },
    { key: 'blockers', tab: 'Blockers' },
  ];

  const renderDailyContent = (daily: ProjectDailyInterface): ReactNode => (
    <Text>
      {daily[activeTabKey] ? (
        <ReactMarkdown source={daily[activeTabKey]} />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={`No ${activeTabKey} report here`}
        />
      )}
    </Text>
  );

  return (
    <Card
      style={{ marginBottom: '1rem' }}
      title={
        <Row justify="space-between">
          <Col>
            <Avatar
              size={50}
              src={daily.person.avatar?.url}
              style={{ marginRight: '1rem' }}
            />
            {daily.person.firstName}
          </Col>
          <Col
            style={{ alignItems: 'center', display: 'flex', padding: '1rem' }}
          >
            <Text type="secondary">
              <span style={{ fontWeight: 'bold' }}>Created</span>:{' '}
              <span>{moment(daily.created_at).format('MMM Do, YYYY')}</span>
            </Text>
          </Col>
        </Row>
      }
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
    >
      {renderDailyContent(daily)}
    </Card>
  );
};

export default DailyCard;
