import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

export const loadUser = createRoutine('USER/LOAD');
export const logOutUser = createRoutine('USER/LOGOUT');

export const authSignIn = createAction('AUTH0/SIGN_IN');
export const authSignOut = createAction('AUTH0/SIGN_OUT');

export const getAuthToken = createAction('AUTH0/GET_TOKEN');
export const setAuthText = createAction('AUTH0/SET_AUTH_TEXT');

export type UserActionTypes =
  | ReturnType<typeof loadUser>
  | ReturnType<typeof logOutUser>
  | ReturnType<typeof authSignIn>
  | ReturnType<typeof authSignOut>
  | ReturnType<typeof setAuthText>
  | ReturnType<typeof getAuthToken>;
