import { useQuery, ApolloError } from '@apollo/client';
import moment from 'moment';
import getReportsInDateRangeQuery from '../graphql/getReportsInDateRangeQuery.graphql';
import getPeopleForDailyQuery from '../graphql/getPeopleForDailyQuery.graphql';
import { MeltDaily } from '../types';
import { Profile } from '../../melt-connect-reviews/types';

interface Dialies {
  displayDate?: string;
  dailies: MeltDaily[];
  missing: Profile[];
  projects: string[];
  loading: boolean;
  error: ApolloError | null;
  refetch: () => void | null;
}

const useDialies = (date: string): Dialies => {
  const dateStr = date ? date : moment().format('YYYY-MM-DD');
  const startOfDay = moment(dateStr).startOf('day');
  const endOfDay = moment(dateStr).endOf('day');

  const {
    data,
    loading,
    error,
    refetch: refetchReport,
  } = useQuery(getReportsInDateRangeQuery, {
    variables: { startOfDay, endOfDay },
  });

  const {
    data: peopleReport,
    loading: loadingPeopleReport,
    error: errorPeopleReport,
    refetch: refetchPeople,
  } = useQuery(getPeopleForDailyQuery);

  const refetch = () => {
    refetchReport();
    refetchPeople();
  };

  if (loading || loadingPeopleReport) {
    return {
      displayDate: dateStr,
      dailies: [],
      missing: [],
      projects: [],
      loading: true,
      error: null,
      refetch,
    };
  }

  if (error) {
    return {
      displayDate: dateStr,
      dailies: [],
      missing: [],
      projects: [],
      loading: false,
      error: error,
      refetch,
    };
  }

  if (errorPeopleReport) {
    return {
      displayDate: dateStr,
      dailies: [],
      missing: [],
      projects: [],
      loading: false,
      error: errorPeopleReport,
      refetch,
    };
  }

  const dailyReports: MeltDaily[] = data.dailyReports;

  const reportAuthorIds = dailyReports.map(
    (report: MeltDaily) => report.person.id
  );

  const usersInfo = peopleReport.people;

  const missingReports: Profile[] = usersInfo
    .filter(
      (user: Profile) =>
        typeof reportAuthorIds.find(
          (authorId: string) => authorId === user.id
        ) === 'undefined'
    )
    .filter(
      ({ isActive = false, sendsDailyReport = false }: Profile) =>
        isActive && sendsDailyReport
    );

  const formatedMissingReports: MeltDaily[] = missingReports.map((item) => {
    const new_item: MeltDaily = {
      blockers: '',
      id: '',
      person: item,
      today: '',
      yesterday: '',
    };

    return new_item;
  });

  const dailyReportsAndMissedReports = dailyReports.concat(
    formatedMissingReports
  );

  const sortedDaily = [...dailyReportsAndMissedReports].sort((a, b) => {
    const a_proyect = a.person.projects.map((item) => item.name).join('');
    const b_proyect = b.person.projects.map((item) => item.name).join('');
    if (a_proyect > b_proyect) {
      return 1;
    } else if (a_proyect < b_proyect) {
      return -1;
    }
    return 0;
  });

  const projects = sortedDaily
    .map((item) =>
      item.person.projects.length ? item.person.projects[0].name : ''
    )
    .filter((item, pos, self) => {
      return self.indexOf(item) == pos;
    });

  return {
    displayDate: dateStr,
    dailies: sortedDaily,
    missing: missingReports,
    projects,
    loading: false,
    error: null,
    refetch,
  };
};

export default useDialies;
