import React, { ReactElement } from 'react';
import { Avatar, Typography, Descriptions, Spin, Row, Col, Tag } from 'antd';
import { GithubOutlined } from '../../../../../kit/icons';
import { Profile } from '../../../types';
import { DEFAULT_AVATAR } from '../../../../../kit/images';
import coinLogo from '../../../../melt-connect-reviews/icons/melt-coin.png';

const { Title, Text } = Typography;

interface Props {
  loading: boolean;
  profile: Profile;
}

const ProfileReviews: React.FC<Props> = ({
  profile,
  loading,
}): ReactElement => {
  if (loading)
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    );

  const {
    firstName,
    lastName,
    organization,
    jobTitle,
    avatar,
    location,
    email,
    username,
    coins,
    projects,
  } = profile;

  let projectsView = null;

  if (projects) {
    projectsView = (
      <Descriptions.Item label="Projects" key={6}>
        {projects.map((project) => (
          <Tag
            style={{ margin: '3px', fontWeight: 'bold', fontSize: '13px' }}
            key={project.name}
            icon={<GithubOutlined />}
          >
            {project.name}
          </Tag>
        ))}
      </Descriptions.Item>
    );
  }

  const avatarUrl = avatar?.url ?? DEFAULT_AVATAR;

  return (
    <Row
      style={{
        padding: '15px',
        borderRadius: '10px',
        marginTop: '10px',
        justifyContent: 'center',
      }}
    >
      <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: 'center' }}>
        <Title level={3}>
          {firstName} {lastName}
        </Title>
        <Avatar size={200} src={avatarUrl} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} style={{ padding: '10px' }}>
        <Title level={4}>{jobTitle}</Title>
        <Descriptions column={1}>
          <Descriptions.Item label="Nick" key={1}>
            <Text underline>{username}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Company" key={2}>
            <Text strong>{organization.name}</Text>
          </Descriptions.Item>
          {projects && projectsView}
          <Descriptions.Item label="Address" key={3}>
            {location?.location}
          </Descriptions.Item>
          <Descriptions.Item label="Email" key={4}>
            {email}
          </Descriptions.Item>
          <Descriptions.Item label="Coins" key={4}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {coins} <Avatar src={coinLogo} />
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default ProfileReviews;
