import Geocode from 'react-geocode';

type LatLng = {
  lat: number;
  lng: number;
};

export const getLatLng = async (address: string): Promise<LatLng> => {
  Geocode.setApiKey('AIzaSyBKnxx4nDEsihHwzVhhxCNSpuETW12_KrM');
  Geocode.setLanguage('en');
  Geocode.setRegion('us');
  Geocode.setLocationType('ROOFTOP');
  const response = await Geocode.fromAddress(address);
  const { lat, lng } = response.results[0].geometry.location;
  return { lat, lng };
};
