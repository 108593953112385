import { Profile } from '../../src/modules/melt-connect-people/types';

const mockedProfile: Profile = {
  id: 'mockId',
  firstName: 'mockUser',
  lastName: 'mockUser',
  username: 'mockUser',
  organization: { id: 0, name: 'mock LLC ', website: 'mock.llc' },
  avatar: null,
  email: 'workmate2@meltstudio.co',
  birthday: '1999-01-01',
  jobTitle: 'JobTitle',
  location: {
    latitude: null,
    longitude: null,
    location: null,
    address: null,
  },
  userQuestions: [
    {
      id: 0,
      question: '',
      answer: '',
    },
  ],
  coins: 100,
  projects: [
    {
      name: 'Melt Connect',
      id: 0,
      client: {
        id: 0,
        name: '',
        website: 'https://website.com',
      },
    },
  ],
  role: 'Team',
  isActive: true,
  updated_at: '',
  sendsDailyReport: true,
  skills: [
    {
      id: '1',
      name: 'dummySkill',
    },
  ],
  phoneNumber: '123123123',
  hobbies: 'hobbie',
  workExperience: 'workExperience',
  entryDate: '2021-12-01',
  interests: 'interests',
  country: 'Colombia',
  vacationDays: 15,
  levelName: 'E2',
  levelDescription: 'Descripción del nivel',
};

export default mockedProfile;
