import { useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery, ApolloError } from '@apollo/client';
import getPeopleByIdsQuery from '../graphql/getPeopleByIdsQuery.graphql';
import getLeaderBoardQuery from '../graphql/getLeaderBoardQuery.graphql';
import moment from 'moment';
import { buildLeaderBoard } from '../../../utils/buildLeaderBoard';
import { Profile } from '../types';

const from = moment().subtract(28, 'days').toISOString();
const to = moment().toISOString();

interface LeaderBoard {
  data: Profile[] | null;
  loading: boolean;
  error: ApolloError | null;
}

interface Variables {
  variables?: {
    from?: string;
    to?: string;
  };
}

const useCoinsLeaderboard = (time: string): LeaderBoard => {
  const variables: Variables = useMemo(() => {
    if (time !== 'all-time') {
      return {
        variables: {
          from,
          to,
        },
      };
    }
    return {};
  }, [time]);

  const {
    data: coinsData,
    loading: coinsLoading,
    error: coinsError,
  } = useQuery(getLeaderBoardQuery, variables);

  const [
    getPeople,
    { loading: peopleLoading, error: peopleError, data: peopleData },
  ] = useLazyQuery(getPeopleByIdsQuery);

  useEffect(() => {
    if (coinsData == null) {
      return;
    }
    const {
      currencyTransactionsConnection: {
        groupBy: { targetUser: coinsGrouping },
      },
    } = coinsData;

    const peopleIds = coinsGrouping.map(({ key }: { key: string }) => key);

    getPeople({
      variables: {
        ids: peopleIds.length == 0 ? ['-1'] : peopleIds,
      },
    });
  }, [coinsData]);

  const error = coinsError || peopleError;

  if (error) {
    return {
      data: null,
      loading: false,
      error,
    };
  }

  const loading = coinsLoading || peopleLoading;
  if (coinsData == null || loading || peopleData == null) {
    return {
      data: null,
      loading,
      error: null,
    };
  }

  const {
    currencyTransactionsConnection: {
      groupBy: { targetUser: coinsGrouping },
    },
  } = coinsData;

  return {
    data: buildLeaderBoard(peopleData.people, coinsGrouping),
    loading: false,
    error: null,
  };
};

export default useCoinsLeaderboard;
