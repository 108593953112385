import React, { FormEventHandler, useCallback, forwardRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Button, Row, Col, Typography } from 'antd';
import { BadgeFormInput, Badges } from '../../types';
import bookwormreward from '../review-form/bookwormreward.png';
import evolutionreward from '../review-form/evolutionreward.png';
import meltalismreward from '../review-form/meltalismreward.png';
interface ReviewAchievementFormProps {
  readonly onSubmit: SubmitHandler<BadgeFormInput>;
  readonly loading: boolean;
}
const badges = [
  {
    name: 'Melt-volution',
    value: Badges.Evolution,
    img: evolutionreward,
  },
  {
    name: 'Melt-BookWorm',
    value: Badges.Bookworm,
    img: bookwormreward,
  },
  { name: 'Melt-alism', value: Badges.Meltalism, img: meltalismreward },
];

const { Title } = Typography;
export const ReviewAchievementForm = forwardRef<
  HTMLFormElement,
  ReviewAchievementFormProps
>(({ onSubmit, loading }, ref) => {
  const { handleSubmit, reset, register } = useForm<BadgeFormInput>({
    mode: 'all',
    defaultValues: {
      achievements: [],
    },
  });

  const onFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => handleSubmit(onSubmit)(e).then(() => reset()),
    [onSubmit, handleSubmit]
  );
  const handleReset: FormEventHandler<HTMLFormElement> = useCallback(
    () => reset({ achievements: [] }),
    [reset]
  );
  return (
    <>
      <form
        ref={ref}
        noValidate
        autoComplete="off"
        onSubmit={onFormSubmit}
        onReset={handleReset}
      >
        <Title style={{ fontSize: 14 }} level={5}>
          Send a Achievement Badge:
        </Title>
        <Row gutter={[16, 16]}>
          <Col flex="auto">
            <Row gutter={[0, 16]}>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  <>
                    {badges.map((badge) => (
                      <label key={badge.name} style={{ textAlign: 'center' }}>
                        <input
                          style={{ marginLeft: 30 }}
                          placeholder={badge.name}
                          type="checkbox"
                          value={badge.value}
                          defaultValue={0}
                          {...register('achievements')}
                        />
                        <img src={badge.img} alt="alt" width={75} />
                        <br />
                        {badge.name}
                      </label>
                    ))}
                  </>
                </div>
              </Col>
              <Col span={24}>
                <Row justify="end" gutter={[16, 16]}>
                  <Col>
                    <Button htmlType="submit" loading={loading}>
                      {loading ? 'Sending...' : 'Send'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </>
  );
});
