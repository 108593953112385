import React from 'react';

import { Layout } from 'antd';

import WidgetGrid from '../components/home_widget_grid';
import PolyglotBar from '../components/polyglot_bar';
import useAuthSelector from '../hooks/store/use_auth_selector';

const { Content } = Layout;

export const HomeScreen: React.FC = () => {
  const user = useAuthSelector();

  return (
    <Layout>
      <PolyglotBar />
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
        }}
      >
        {user ? <WidgetGrid /> : <h1>Please log in </h1>}
      </Content>
    </Layout>
  );
};

export default HomeScreen;
