import React, { ReactElement, useEffect, useRef } from 'react';
import { Col, Typography, Avatar, Divider, Row, List } from 'antd';
import { Filters, MeltDaily } from '../../types';
import ReactMarkdown from 'react-markdown';
import { DEFAULT_AVATAR } from '../../../../kit/images';
const { Text } = Typography;

interface ProjectItemProps {
  item: MeltDaily;
  filters: Filters;
  setDailyFilters: ({ activeDaily, selectedManager }: Filters) => void;
  idxUser: number;
  idxProyect: number;
}

const DailyUserReportsStyle = {
  backgroundColor: '#181818',
  padding: '20px',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  border: '1px solid #3b3b3b',
};

const ProjectItem: React.FC<ProjectItemProps> = ({
  item,
  filters,
  setDailyFilters,
  idxUser,
  idxProyect,
}) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const getAvatar = (item: MeltDaily) =>
    item.person.avatar?.url ?? DEFAULT_AVATAR;
  const { activeDaily, selectedManager } = filters;
  const getIsUserActive = (excludeAll: boolean): boolean =>
    activeDaily === item.person.username ||
    (activeDaily === 'all' && !excludeAll);

  const handleActiveDaily = (value: string | null) => {
    setDailyFilters({ activeDaily: value, selectedManager });
  };

  const isMissing = () => item.today === '';

  useEffect(() => {
    const { current } = ref;

    if (getIsUserActive(true)) {
      setTimeout(() => {
        current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, 600);
    }

    if (idxUser === 0 && idxProyect === 0 && !activeDaily) {
      handleActiveDaily(item.person.username);
    }
  }, [activeDaily, item]);

  return (
    <List.Item key={item.id} className="daily-project-user-list">
      <List.Item.Meta
        avatar={<Avatar size={40} src={getAvatar(item)} />}
        title={
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => handleActiveDaily(item.person.username)}
          >
            <p
              ref={ref}
              className={
                isMissing() ? 'ant-typography ant-typography-warning' : ''
              }
            >
              <b>{isMissing() ? '⚠️ ' : ''}</b>
              {`${item.person.firstName} ${item.person.lastName}`}
              <span>
                {isMissing() ? " - has not submitted today's report" : ''}
              </span>
            </p>
            <Text type={'secondary'} style={{ marginLeft: '5px' }}>
              {item.person.projects.map((item) => item.name).join('')}{' '}
            </Text>
          </div>
        }
        description={
          <Row
            className={
              'daily-collapse ' +
              (getIsUserActive(false) ? 'daily-collapse-active' : '')
            }
            justify={'space-between'}
            gutter={[{}, { xs: 10, md: 10, lg: 0 }]}
          >
            <Col
              xs={{ span: 23 }}
              sm={{ span: 11 }}
              md={{ span: 11 }}
              lg={{ span: 7 }}
              style={DailyUserReportsStyle}
            >
              <Divider
                style={{ padding: '0px 20px 5px 20px', color: '#a7a7a7e6' }}
                orientation="center"
              >
                <p>{"Yesterday's progress"}</p>
              </Divider>
              <ReactMarkdown source={item.yesterday} />
            </Col>
            <Col
              xs={{ span: 23 }}
              sm={{ span: 11 }}
              md={{ span: 11 }}
              lg={{ span: 7 }}
              style={DailyUserReportsStyle}
            >
              <Divider
                style={{ padding: '0px 20px 5px 20px', color: '#ffffff' }}
                orientation="center"
              >
                <p>Plans for today</p>
              </Divider>
              <ReactMarkdown source={item.today} />
            </Col>
            <Col
              xs={{ span: 23 }}
              sm={{ span: 11 }}
              md={{ span: 11 }}
              lg={{ span: 7 }}
              style={DailyUserReportsStyle}
            >
              <Divider
                style={{ padding: '0px 20px 5px 20px', color: '#b71c1c' }}
                orientation="center"
              >
                <p>Any blockers?</p>
              </Divider>
              <ReactMarkdown source={item.blockers} />
            </Col>
          </Row>
        }
      />
    </List.Item>
  );
};

const DailyUserRow = ({
  items,
  toShow,
  filters,
  setDailyFilters,
  idxProyect,
}: {
  items: Array<MeltDaily>;
  toShow: string;
  filters: Filters;
  setDailyFilters: ({ activeDaily, selectedManager }: Filters) => void;
  idxProyect: number;
}): ReactElement => {
  const filtered = items.filter(
    (item) => (toShow === 'blockers' && item.blockers) || toShow !== 'blockers'
  );

  return (
    <Col
      style={{ marginLeft: '40px' }}
      xs={{ span: 23 }}
      sm={{ span: 23 }}
      md={{ span: 23 }}
      lg={{ span: 23 }}
    >
      <div></div>
      <List
        itemLayout="horizontal"
        dataSource={filtered}
        renderItem={(item, idxUser) => (
          <ProjectItem
            filters={filters}
            item={item}
            key={item.person.username}
            setDailyFilters={setDailyFilters}
            idxUser={idxUser}
            idxProyect={idxProyect}
          />
        )}
      ></List>
    </Col>
  );
};

export default DailyUserRow;
