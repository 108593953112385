import { takeEvery, call, CallEffect, ForkEffect } from 'redux-saga/effects';
import { notification } from 'antd';
import { Action } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* notifyFailureSaga(action: any): Generator<CallEffect, void, void> {
  // TODO
  try {
    yield call([console, 'error'], 'Routine Failure Watcher', action.payload);
    notification.error({
      message: 'Error',
      description: action.payload.toString(),
    });
  } catch {
    console.error('Error logging error :)');
  }
}

const failureMatcher = (action: Action): boolean => {
  return action && action.type && action.type.endsWith('/FAILURE');
};

export default function* notificationsSaga(): Generator<
  ForkEffect,
  void,
  void
> {
  yield takeEvery(failureMatcher, notifyFailureSaga);
}
