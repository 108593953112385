import React from 'react';
import { Typography, Divider } from '@mui/material';

interface Props {
  children: string | React.ReactNode;
  variant?:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2';
  gutterBottom?: boolean;
  style?: React.CSSProperties;
  textAlign?: 'center' | 'right' | 'left';
}

const Title: React.FC<Props> = ({
  children,
  style,
  variant = 'h6',
  gutterBottom = false,
  textAlign = 'center',
}) => (
  <div style={style}>
    <Divider textAlign={textAlign}>
      <Typography variant={variant} gutterBottom={gutterBottom}>
        {children}
      </Typography>
    </Divider>
  </div>
);

export default Title;
