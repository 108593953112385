import saga from './store/saga';
import ProjectScreen from './screens/project';
import ProjectsScreen from './screens/projects';

export default {
  routes: [
    {
      exact: true,
      path: '/projects/',
      component: ProjectsScreen,
      menu: {
        visibility: 1 /* ShowInMenu.AUTHD */,
        name: 'Projects',
      },
      data: {
        authRequired: true,
        idFromPath: true,
        actions: '',
        filters: { q: { defaultValue: '' } },
      },
    },
    {
      exact: true,
      path: '/organization/:o_id/project/:p_id',
      component: ProjectScreen,
      menu: {
        visibility: 0 /* ShowInMenu.NEVER */,
      },
      data: {
        authRequired: true,
        idFromPath: true,
        actions: '',
      },
    },
  ],
  homepageWidgets: [],
  store: {
    namespace: 'projects',
    saga,
  },
};
