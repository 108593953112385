import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
// import Loadable from 'react-loadable';
import React from 'react';
import { Store, Reducer } from 'redux';
import { History } from 'history';
import { Routine } from 'redux-saga-routines';

import createBaseRoutes from './routes';
import { createStore, history } from '../redux';
import { config, gqlClient } from '../api';
import userSaga from '../redux/user/saga';
import navSaga from '../redux/nav/saga';
import notificationsSaga from '../redux/notifications/saga';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { RouteSpec, WidgetOptions } from '../types';

import { loadUser } from '../redux/user/actions';
import {
  getAuthenticatedUser,
  getAuthStateText,
} from '../redux/user/selectors';
import { setUrlFilters } from '../redux/nav/actions';
import { getNavFilters } from '../redux/nav/selectors';

import settings from '../connect';
import type { ModuleSettingOptions } from '../connect';

interface AppModuleWidgetSpec {
  meta: { title: string };
  component: React.ComponentType;
  action: Routine;
}

export interface AppSettings {
  modules: unknown[];
  routes: RouteSpec[];
  store: Store;
  history: History;
  homeWidgets: HomeWidgetsList;
  rootReducer: Reducer;
  rootSaga: () => Generator;
  gqlClient: ApolloClient<NormalizedCacheObject>;
}

export interface AppModuleCreateSagaArgs {
  gqlClient: typeof gqlClient;
  config: typeof config;
  userLoadedAction: string;
  userSelector: typeof getAuthenticatedUser;
  authStateSelector: typeof getAuthStateText;
  navFiltersSelector: typeof getNavFilters;
  setUrlFilters: typeof setUrlFilters;
}

export type SagaType = () => IterableIterator<unknown>;
export type SagaCreator = (args: AppModuleCreateSagaArgs) => SagaType;

interface AppModuleStoreSpec {
  namespace: string;
  reducer: Reducer;
  saga: SagaCreator;
}

interface AppModuleIndex {
  homepageWidgets: AppModuleWidgetSpec[];
  store: AppModuleStoreSpec;
  routes: RouteSpec[];
  dependsOn?: string[];
}

interface AppModule {
  name: string;
  content: AppModuleIndex;
}

interface WidgetSpec {
  meta: WidgetOptions;
  component: React.ComponentType;
}

interface HomeWidgetsList {
  widgets: WidgetSpec[];
  actions: Routine[];
}

const loadSettings = (): AppSettings => {
  const modules = settings.modules.map((module) => {
    let moduleName = '';
    let moduleOptions: ModuleSettingOptions = {};
    if (Array.isArray(module)) {
      moduleName = module[0];
      moduleOptions = module[1];
    } else {
      moduleName = module;
    }
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const loadedModule = require(`../modules/${moduleName}`);
    return {
      name: module,
      content: loadedModule.default,
      options: moduleOptions,
    } as AppModule;
  });

  modules.forEach((module) => {
    if (!module.content.dependsOn) return;
    module.content.dependsOn.forEach((dep) => {
      if (!settings.modules.includes(dep)) {
        console.error(
          `Module depends on ${dep} which was not found in config.`
        );
        throw new Error('DependencyNotFoundError');
      }
    });
  });

  const homeWidgets: HomeWidgetsList = modules.reduce(
    (acc, module: AppModule) => {
      const {
        content: { homepageWidgets },
      } = module;
      if (homepageWidgets)
        homepageWidgets.forEach(({ meta, component, action }) => {
          acc.widgets.push({ meta, component }); // WidgetSpec
          if (action) acc.actions.push(action);
        });
      return acc;
    },
    { widgets: [], actions: [] } as HomeWidgetsList
  );

  const routes: RouteSpec[] = [];

  const baseRoutes = createBaseRoutes({ homeActions: homeWidgets.actions });
  baseRoutes.forEach((route) => routes.push(route));

  modules.forEach((module) => {
    module.content.routes.forEach((route: RouteSpec) => {
      const component: unknown = route.component;
      /* if (route.screen) {
       *   const screenPath = `src/modules/${module.name}/screens/${route.screen}`;
       *   const component = Loadable({
       *     loader: () => import(screenPath),
       *     loading: () => null,
       *   })
       *   component = require(screenPath);
       * } */
      const loadableRoute: RouteSpec = Object.assign({}, route, {
        component,
      });
      routes.push(loadableRoute);
    });
  });

  const reducers = modules.reduce((acc, module) => {
    const {
      content: { store },
    } = module;
    if (store && store.namespace && store.reducer) {
      return Object.assign(acc, { [store.namespace]: store.reducer });
    }
    return acc;
  }, {});

  const sagas = modules.reduce((acc, module) => {
    const {
      content: { store },
    } = module;
    if (store && store.saga) {
      const sagaWithConfig = store.saga({
        gqlClient,
        config,
        setUrlFilters,
        userSelector: getAuthenticatedUser,
        authStateSelector: getAuthStateText,
        navFiltersSelector: getNavFilters,
        userLoadedAction: loadUser.SUCCESS,
      });
      acc.push(sagaWithConfig);
    }
    return acc;
  }, [] as SagaType[]);

  sagas.push(navSaga(routes));
  sagas.push(userSaga);
  sagas.push(notificationsSaga);
  sagas.push(routinePromiseWatcherSaga);

  const { store, rootReducer, rootSaga } = createStore(reducers, sagas);

  return {
    modules,
    routes,
    store,
    history,
    homeWidgets,
    rootReducer,
    rootSaga,
    gqlClient,
  };
};

export default loadSettings;
