// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Hidden Component should render a component 1`] = `
<Field
  component="input"
  disabled={true}
  name="id-hidden"
  type="hidden"
/>
`;
