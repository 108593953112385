import React from 'react';
import { NavLink } from 'react-router-dom';

import { Card, Avatar, Typography } from 'antd';
import { GlobalOutlined } from '../../../../../kit/icons';

const { Meta } = Card;
const { Text } = Typography;

import { Profile } from '../../../types';
import { DEFAULT_AVATAR } from '../../../../../kit/images';

interface Props {
  profile: Profile;
}

const PersonCard: React.FC<Props> = ({ profile }) => {
  if (profile == null) return null;
  const avatar = profile.avatar?.url ?? DEFAULT_AVATAR;
  return (
    <Card style={{ height: 150 }}>
      <Meta
        avatar={<Avatar src={avatar} />}
        title={
          <NavLink
            to={`/u/${profile.id}`}
          >{`${profile.firstName} ${profile.lastName}`}</NavLink>
        }
        description={`${profile.organization?.name ?? 'UNKNOWN_ORG'}: ${
          profile.jobTitle ?? ''
        }`}
      />
      <br />
      {profile.location && (
        <>
          <Text type="secondary">
            <GlobalOutlined /> {profile.location.location}
          </Text>
          <br />
        </>
      )}
    </Card>
  );
};

export default PersonCard;
