import React from 'react';
import type { Profile } from '../../types';
import { Avatar, Row, Col } from 'antd';
import coinLogo from '../../icons/melt-coin.png';
import { DEFAULT_AVATAR } from '../../../../kit/images';

interface Props {
  hallOfFame: Profile;
}

const EmployeeCard: React.FC<Props> = ({ hallOfFame }) => {
  const getAvatar = (profile: Profile) => profile.avatar?.url ?? DEFAULT_AVATAR;

  return (
    <>
      <Row justify="start" align="middle">
        <Col style={{ marginRight: 5 }}>
          <div>
            <Avatar src={getAvatar(hallOfFame)} />
            <a
              style={{ marginLeft: 10 }}
              href={`/u/${hallOfFame.id}`}
            >{`${hallOfFame.firstName} ${hallOfFame.lastName}`}</a>
          </div>
        </Col>
        <Col>
          <span>
            with {hallOfFame.coins}
            <Avatar src={coinLogo} />
          </span>
        </Col>
      </Row>
    </>
  );
};

export default EmployeeCard;
