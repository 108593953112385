import { ApolloError, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Skill } from '../types';
import getListSkills from '../graphql/getListSkills.graphql';

interface SkillsData {
  skills: Skill[] | [];
  loading: Boolean;
  error: ApolloError | null;
}

const useSkills = (): SkillsData => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const { data, loading, error } = useQuery(getListSkills);

  useEffect(() => {
    if (data) {
      setSkills(data.skills);
    }
  }, [data]);

  if (loading) return { skills: [], loading, error: null };
  if (error) return { skills: [], loading: false, error };

  return { skills, loading: false, error: null };
};

export default useSkills;
