import React, { ReactElement } from 'react';

import { Card } from 'antd';

import type { WidgetOptions } from '../../types';

const createWidget =
  (
    options: WidgetOptions,
    WidgetContent: React.ComponentType
    // eslint-disable-next-line react/display-name
  ): React.FC<{}> =>
  // eslint-disable-next-line react/display-name
  (): ReactElement => {
    return (
      <Card title={options.title}>
        <WidgetContent />
      </Card>
    );
  };

export default createWidget;
