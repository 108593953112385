import React from 'react';
import { Layout, Spin, Alert } from 'antd';
import EmployeeCard from '../../components/employee_card';
import useHallOfFame from '../../hooks/use_hall_of_fame';
import moment from 'moment';

const { Content } = Layout;
const CoinLeader: React.FC = () => {
  const { data, loading, error } = useHallOfFame(
    moment().month(),
    moment().year()
  );

  if (loading) return <Spin />;
  if (error) return <Alert message={error} type="error" />;
  const hallOfFame = data || [];
  return (
    <Content>
      {hallOfFame?.length > 0 ? (
        <EmployeeCard hallOfFame={hallOfFame[0]} />
      ) : (
        <span>No coin leader of the month yet</span>
      )}
    </Content>
  );
};

export default CoinLeader;
