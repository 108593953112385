import React from 'react';
import { Layout } from 'antd';
import VacationsRequestManager from '../components/manager';

const { Content } = Layout;

const VacationsManager: React.VFC = () => {
  return (
    <Content style={{ minHeight: 600 }}>
      <VacationsRequestManager />
    </Content>
  );
};

export default VacationsManager;
