import { ApolloError, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import getReviewsGivenByUsernameQuery from '../graphql/getReviewsGivenByUsernameQuery.graphql';
import { Review } from '../types';

interface GivenReviews {
  reviewsGiven?: Review[];
  loading: boolean;
  error: ApolloError | null;
}

const useGivenReviews = (id: string): GivenReviews => {
  const [reviewsGiven, setReviewsGiven] = useState<Review[]>();
  const { data, loading, error } = useQuery(getReviewsGivenByUsernameQuery, {
    variables: {
      id,
    },
  });

  if (error) {
    return { reviewsGiven, loading, error };
  }

  useEffect(() => {
    if (data) {
      setReviewsGiven(data.reviews);
    }
  }, [data]);

  return { reviewsGiven, loading, error: null };
};

export default useGivenReviews;
