import { Card, Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_AVATAR } from '../../../../../kit/images';
import { UploadedImage } from '../../../../../types';
import { ProjectInterface } from '../../../types';
const { Meta } = Card;
export interface Props {
  projects: ProjectInterface[] | [];
}

const ProjectsCardsComponent: React.FC<Props> = ({ projects }) => {
  const getLogo = (logo: UploadedImage[]) =>
    logo.length ? logo[0].url : DEFAULT_AVATAR;
  return (
    <Row justify="start">
      <Col span={24}>
        <Row
          gutter={[
            { xs: 10, lg: 10, xxl: 10 },
            { xs: 10, lg: 10, xxl: 10 },
          ]}
        >
          {projects.map((project) => {
            return (
              <Col key={project.id}>
                <Link
                  to={`/organization/${project.id}/project/${project.project.id}`}
                >
                  <Card
                    style={{ width: 300 }}
                    cover={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: 'white',
                          padding: '10px',
                        }}
                      >
                        <img
                          alt="example"
                          height="100"
                          style={{ width: 'auto', margin: 'auto' }}
                          src={getLogo(project.logo)}
                        />
                      </div>
                    }
                  >
                    <Meta title={project.name} />
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectsCardsComponent;
