import { MeltDaily } from '../types';

const getNextAndPreviousDaily = (
  dailyReposts: MeltDaily[],
  activeDaily: string | null
): {
  nextDaily: string | null;
  previousDaily: string | null;
} => {
  const isActiveDailyIndex = (element: MeltDaily) =>
    element.person.username === activeDaily;

  const index = dailyReposts.findIndex(isActiveDailyIndex);

  const nextIndex = index < dailyReposts.length - 1 ? index + 1 : index;

  const previousIndex = index > 0 ? index - 1 : 0;

  const nextDaily: string = dailyReposts[nextIndex].person.username;

  const previousDaily: string = dailyReposts[previousIndex].person.username;

  return {
    nextDaily: nextDaily,
    previousDaily: previousDaily,
  };
};

export default getNextAndPreviousDaily;
