import { useQuery, ApolloError } from '@apollo/client';
import { Filters } from '../types';
import useNavFiltersSelector from '../../../hooks/store/use_nav_filters_selector';
import getPeopleQuery from '../graphql/getPeopleQuery.graphql';
import { Profile } from '../types';
import { useEffect, useState } from 'react';

interface ProfilesData {
  profiles?: Profile[];
  loading: Boolean;
  error: ApolloError | null;
}

const useProfiles = (): ProfilesData => {
  const filters = useNavFiltersSelector();
  const [profiles, setProfiles] = useState<Profile[]>();
  const filtersMaker = (): Filters => {
    const parsedFilters: Filters = { search: filters.q };

    if (filters.skill !== 'all')
      parsedFilters.skill = parseInt(filters.skill) || 0;
    if (filters.role !== 'all') parsedFilters.role = filters.role || '';

    return parsedFilters;
  };

  const { data, loading, error } = useQuery(getPeopleQuery, {
    variables: {
      search: filtersMaker()?.search,
      skill: filtersMaker()?.skill,
      role: filtersMaker()?.role,
    },
  });

  if (error) {
    return { profiles, loading, error };
  }

  useEffect(() => {
    if (data) {
      setProfiles(data.people);
    }
  }, [data]);

  return { profiles, loading, error: null };
};

export default useProfiles;
