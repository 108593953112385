import React from 'react';
import SettingsContext from './context';
import { AppSettings } from './loader';

interface Props {
  settings: AppSettings;
  children: React.ReactNode;
}

const SettingsProvider: React.FC<Props> = ({ children, settings }: Props) => {
  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
