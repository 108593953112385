import { MeltDaily } from './../types';
import getNextAndPreviousDaily from '../utils/getNextAndPreviousDaily';

const usePreviousDaily = (
  items: MeltDaily[],
  activeDaily: string
): string | null => {
  if (items.length) {
    return getNextAndPreviousDaily(items, activeDaily).previousDaily;
  } else {
    return null;
  }
};

export default usePreviousDaily;
