import { useQuery, ApolloError } from '@apollo/client';
import getVacationsReportsQuery from '../graphql/getVacationsReportsQuery.graphql';
import { VacationReportRequest } from '../types';

interface VacationRequestManagerData {
  vacationReportRequestManager: VacationReportRequest;
  loading: boolean;
  error: ApolloError | null;
}

const useVacationReportManager = (): VacationRequestManagerData => {
  const { data, loading, error } = useQuery(getVacationsReportsQuery);

  if (loading) {
    return {
      vacationReportRequestManager: {
        people: [],
        vacationRequests: [],
      },
      loading,
      error: null,
    };
  }

  if (error) {
    return {
      vacationReportRequestManager: {
        people: [],
        vacationRequests: [],
      },
      loading,
      error,
    };
  }

  return {
    vacationReportRequestManager: {
      people: data.people,
      vacationRequests: data.vacationRequests,
    },
    loading,
    error: null,
  };
};

export default useVacationReportManager;
