import React, { useState } from 'react';

import moment from 'moment';
import { Save } from '@mui/icons-material';
import { TextField, Grid, Button, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Title from '../../../../../components/common/title';
import { DEFAULT_AVATAR } from '../../../../../kit/images';
import DatePicker from '../../../../../components/common/date_picker';
import AvatarUploader from '../../../../../components/common/avatar_uploader';
import Error from '../../../../../components/common/error';
import { getCountries } from '../../../utils/getCountries';
import { Country, Profile } from '../../../types';
import updateProfileMutation from '../../../graphql/updateProfileMutation.graphql';
import uploadFileMutation from '../../../graphql/uploadFileMutation.graphql';
import { message } from 'antd';
import { useMutation } from '@apollo/client';
interface Props {
  profile: Profile;
}
import { getLatLng } from '../../../utils/getLatLng';

const ProfileSettings: React.FC<Props> = ({ profile }) => {
  const [updateProfile, { error, loading }] = useMutation(
    updateProfileMutation
  );
  const [updateProfilePicture] = useMutation(uploadFileMutation);
  const [birthDate, setBirthDate] = useState(
    profile?.birthday ? moment(profile.birthday) : moment()
  );
  const [entryDate, setEntryDate] = useState(
    profile?.entryDate ? moment(profile.entryDate) : moment()
  );
  const [newAvatar, setNewAvatar] = useState<File | null>(null);
  const [address, setAddress] = useState(profile?.location?.address || '');
  const [city, setCity] = useState(profile?.location?.location || '');
  const [phoneNumber, setPhoneNumber] = useState(profile?.phoneNumber || '');
  const [workExperience, setWorkExperience] = useState(
    profile?.workExperience || ''
  );
  const [hobbies, setHobbies] = useState(profile?.hobbies || '');
  const [interests, setInterests] = useState(profile?.interests || '');
  const [country, setCountry] = useState(profile?.country || '');
  const contries = getCountries();

  if (!profile) return <Error>Error, please reaload</Error>;

  const messages = () => {
    message.loading('Updating..', 1).then(() => {
      if (error) {
        message.error(error.message);
      } else {
        message.success('Profile updated successfully');
      }
    });
  };

  const handleSubmit = async () => {
    const { lat, lng } = await getLatLng(city + ' ' + country);
    if (newAvatar) {
      updateProfilePicture({
        variables: {
          ref: 'person',
          refId: profile.id,
          field: 'avatar',
          file: newAvatar,
        },
      });
    }
    updateProfile({
      variables: {
        idUser: profile.id,
        location: city,
        birthdate: birthDate.format('YYYY-MM-DD'),
        phoneNumber,
        address,
        hobbies,
        interests,
        entryDate: entryDate.format('YYYY-MM-DD'),
        workExperience,
        country,
        latitude: lat,
        longitude: lng,
      },
    });
    messages();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container justifyContent="center" alignItems="center">
          <AvatarUploader
            src={profile.avatar?.url ?? DEFAULT_AVATAR}
            onChange={setNewAvatar}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container justifyContent="center" alignItems="center">
          <h6>
            <b>File size cannot be bigger than: 1024Kb</b>
          </h6>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <InputLabel
          id="country-select-label"
          variant="standard"
          style={{ fontSize: '0.8rem', marginBottom: '-0.1rem' }}
        >
          Country
        </InputLabel>
        <Select
          id="country-select"
          value={country}
          onChange={({ target: { value } }) => setCountry(value)}
          variant="standard"
          fullWidth
        >
          {contries.map((country: Country) => (
            <MenuItem value={country.value} key={country.value}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          label="Address"
          value={address}
          onChange={({ target: { value } }) => setAddress(value)}
          variant="standard"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          label="City"
          variant="standard"
          value={city}
          onChange={({ target: { value } }) => setCity(value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <DatePicker
          fullWidth
          value={birthDate}
          label="Birth date"
          onChange={setBirthDate}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <DatePicker
          fullWidth
          value={entryDate}
          label="Start date"
          onChange={setEntryDate}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          label="Phone number"
          variant="standard"
          value={phoneNumber}
          onChange={({ target: { value } }) => setPhoneNumber(value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Title style={{ marginTop: '10px' }}>Aditional information</Title>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          label="Work experience"
          variant="standard"
          value={workExperience}
          onChange={({ target: { value } }) => setWorkExperience(value)}
          multiline
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          label="Hobbies"
          variant="standard"
          value={hobbies}
          onChange={({ target: { value } }) => setHobbies(value)}
          multiline
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          label="Interests"
          variant="standard"
          value={interests}
          onChange={({ target: { value } }) => setInterests(value)}
          multiline
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container justifyContent="center">
          <Button
            disabled={loading}
            variant="contained"
            startIcon={<Save />}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileSettings;
