import reducer from './store/reducer';
import saga from './store/saga';
import ThisMonthsBirthdays from './widgets/this_months_birthdays';
import SlotsWidget from './widgets/slotMachine';
import {
  listProfiles,
  loadProfile,
  listThisMonthsBirthdays,
  loadPeople,
  loadReviewsByProfile,
  loadTalksByProfile,
  loadReviewsGivenByProfile,
  loadSkills,
} from './store/actions';
import PeopleScreen from './screens/people';
import ProfileScreen from './screens/profile';
import RedirectToProfile from './components/redirect_to_profile';

export default {
  routes: [
    {
      exact: true,
      path: '/people',
      // screen: 'people',
      component: PeopleScreen,
      menu: { visibility: 1 /* ShowInMenu.AUTHD */, name: 'People' },
      data: {
        authRequired: true,
        actions: [listProfiles, loadSkills],
        filters: {
          role: { defaultValue: 'all' },
          view: { defaultValue: 'list', options: ['list', 'map'] },
          skill: { defaultValue: 'all' },
          q: { defaultValue: '' },
        },
      },
    },
    {
      exact: false,
      path: '/u/:id',
      // screen: 'profile',
      component: ProfileScreen,
      menu: { visibility: 0 /* ShowInMenu.NEVER */ },
      data: {
        idFromPath: true,
        authRequired: true,
        actions: [
          loadProfile,
          loadReviewsByProfile,
          loadReviewsGivenByProfile,
          loadTalksByProfile,
        ],
      },
    },
    {
      exact: true,
      path: '/redirect/profile/',
      component: RedirectToProfile,
      menu: { visibility: 0 /* ShowInMenu.NEVER */ },
      data: {
        authRequired: true,
        actions: [],
      },
    },
  ],
  homepageWidgets: [
    {
      meta: {
        title: '🎉 Birthdays this month 🎉',
      },
      component: ThisMonthsBirthdays,
      action: listThisMonthsBirthdays,
    },
    {
      meta: {
        title: 'Slots Machine',
      },
      component: SlotsWidget,
      action: loadPeople,
    },
  ],
  store: {
    namespace: 'profiles',
    reducer,
    saga,
  },
};
