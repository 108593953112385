import { updateFiltersFromUrl, NavActionTypes } from './actions';
import produce, { Draft } from 'immer';
import { NavFilters } from '../../types';

export interface NavState {
  readonly filters: NavFilters;
}

const INITIAL_STATE: NavState = {
  filters: {},
};

export default produce(
  (draft: Draft<NavState>, action: NavActionTypes): void => {
    switch (action.type) {
      case updateFiltersFromUrl.toString():
        draft.filters = action.payload;
        break;
    }
  },
  INITIAL_STATE
);
