import { useEffect, useState } from 'react';
import { Profile } from './../types';
import getProfilesByEmailQuery from '../graphql/getProfilesByEmailQuery.graphql';
import { ApolloError, useQuery } from '@apollo/client';

interface ProfileData {
  userProfile?: Profile;
  loading: boolean;
  error: ApolloError | null;
}

const useCurrentprofile = (): ProfileData => {
  const [userProfile, setuserProfile] = useState<Profile>();
  const email = localStorage.getItem('email');

  const { data, loading, error } = useQuery(getProfilesByEmailQuery, {
    variables: {
      email,
    },
  });

  if (!data && !loading && !error) {
    throw new Error('Cant find Current user profile');
  }

  useEffect(() => {
    if (data) {
      setuserProfile(data.people[0]);
    }
  }, [data]);

  return { userProfile, loading, error: null };
};

export default useCurrentprofile;
