import React from 'react';
import { Layout } from 'antd';
import ReportsManager from '../components/report';

const { Content } = Layout;

const ReportsScreen: React.VFC = () => {
  return (
    <Content style={{ minHeight: 600 }}>
      <ReportsManager />
    </Content>
  );
};

export default ReportsScreen;
