import React from 'react';
import { shallow } from 'enzyme';
import ProfileScreen from '../profile';

describe('Profile Screen', () => {
  let wrapper: ReturnType<typeof shallow>;

  beforeEach(() => {
    wrapper = shallow(React.createElement(ProfileScreen));
  });

  it('should render the screen', () => {
    expect(wrapper).toMatchSnapshot();
  });
});
