import { takeEvery, ForkEffect, put, PutEffect } from 'redux-saga/effects';

import { SagaCreator } from '../../../settings';
import { SERVICES_FILTER, setFilters } from './actions';

const createSaga: SagaCreator = ({ setUrlFilters }) => {
  function* setFiltersSaga({
    payload,
  }: ReturnType<typeof setFilters>): Generator<PutEffect> {
    try {
      yield put(setUrlFilters(payload));
    } catch (e) {
      throw new Error(e as string);
    }
  }

  function* servicesSaga(): Generator<ForkEffect, void, void> {
    yield takeEvery(SERVICES_FILTER, setFiltersSaga);
  }

  return servicesSaga;
};

export default createSaga;
