import React from 'react';
import { NavLink } from 'react-router-dom';

import { Menu } from 'antd';

const { ItemGroup, Item } = Menu;

const SettingsMenu: React.FC<{}> = () => {
  return (
    <Menu defaultSelectedKeys={['1']} mode="inline">
      <ItemGroup title="General">
        <Item key="1">
          <NavLink to="/settings">Profile</NavLink>
        </Item>
        <Item key="2">
          <NavLink to="/settings/security">Security</NavLink>
        </Item>
        <Item key="3">
          <NavLink to="/settings/emails">Emails</NavLink>
        </Item>
        <Item key="4">
          <NavLink to="/settings/notifications">Notifications</NavLink>
        </Item>
      </ItemGroup>
      <ItemGroup title="Item 1">
        <Item key="SSH and GPG keys">
          <NavLink to="/settings/ssh">SSH and GPG keys</NavLink>
        </Item>
      </ItemGroup>
    </Menu>
  );
};

export default SettingsMenu;
