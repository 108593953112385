import React from 'react';

import { Alert, Avatar, Button, Col, Row, Spin, Table, Tabs } from 'antd';

import { Profile } from '../../types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { DEFAULT_AVATAR } from '../../../../kit/images';
import { setUrlFilters } from '../../../../redux/nav/actions';
import useNavFiltersSelector from '../../../../hooks/store/use_nav_filters_selector';
import useCoinsLeaderboard from '../../hooks/use_coins_leaderboard';

const LeaderboardTable: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useNavFiltersSelector();
  const handleTabChange = (key: string) => {
    const newFilters = Object.assign({}, filters, { time: key });
    dispatch(setUrlFilters(newFilters));
  };

  const {
    data: leaderboard,
    loading,
    error,
  } = useCoinsLeaderboard(filters.time);

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  if (!leaderboard) return null;

  const columns = [
    {
      title: '',
      dataIndex: 'avatar',
      key: 'avatar',
      // eslint-disable-next-line react/display-name
      render: (a: { url: string }) => <Avatar src={a?.url ?? DEFAULT_AVATAR} />,
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      // eslint-disable-next-line react/display-name
      render: (_name: string, items: Profile) => {
        return (
          <NavLink to={`/u/${items.id}`}>
            {items.firstName} {items.lastName}
          </NavLink>
        );
      },
      key: 'name',
    },
    {
      title: 'Coins',
      dataIndex: 'coins',
      key: 'coins',
      render: (c: number | null) => c ?? 0,
      width: '20%',
    },
  ];

  const hallOfFameButton = (
    <NavLink to={`/reviews/hall-of-fame`}>
      <Button type="primary">Hall of Fame</Button>
    </NavLink>
  );

  return (
    <Row>
      <Col span={16} offset={4}>
        <Tabs
          defaultActiveKey={filters.time}
          onChange={handleTabChange}
          tabBarExtraContent={hallOfFameButton}
        >
          <Tabs.TabPane tab="Last 28 days" key="latest">
            <Table
              columns={columns}
              dataSource={leaderboard}
              pagination={false}
              loading={loading}
              rowKey={(d) => d.id}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="All Time" key="all-time">
            <Table
              columns={columns}
              dataSource={leaderboard}
              pagination={false}
              loading={loading}
              rowKey={(d) => d.id}
            />
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default LeaderboardTable;
