import React from 'react';
import { MeltEvent } from '../../../../melt-connect-talks/types';
import { Avatar, List } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DEFAULT_VIDEO_IMAGE } from '../../../../../kit/images';
export interface Props {
  talks: MeltEvent[];
  avatar: string;
}

const ProfileTalksComponent: React.FC<Props> = ({ talks, avatar }) => {
  const getDate = (date: string) => moment(date).format('YYYY-MM-DD');

  return (
    <div>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          pageSize: 3,
        }}
        dataSource={talks}
        renderItem={(item) => (
          <List.Item
            key={item.title}
            extra={
              <Link to={`/talk/${item.id}`}>
                <img
                  className="Profile-talks-thumbnail"
                  alt="logo"
                  src={
                    (item.thumbnail && item.thumbnail[0]?.url) ||
                    DEFAULT_VIDEO_IMAGE
                  }
                />
              </Link>
            }
            className="Profile-talks-item"
          >
            <List.Item.Meta
              avatar={<Avatar src={avatar} />}
              title={<Link to={`/talk/${item.id}`}>{item.title}</Link>}
              description={getDate(item.beginAt)}
            />
            <p
              style={{
                maxHeight: '20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {item.description}
            </p>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ProfileTalksComponent;
