import { createStore, applyMiddleware, compose, Reducer, Store } from 'redux';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function initStore(
  rootReducer: Reducer,
  rootSaga: Saga,
  history: History
): Store {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
