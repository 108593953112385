import { Alert, Col, Row, Spin, Tabs } from 'antd';
import React from 'react';
import ProjectDetail from './project_details';
import ProjectUsers from './project_users';
import ProjectDailies from './project_dailies';
import getProjectsByIdsQuery from '../../graphql/getProjectsByIdsQuery.graphql';
import getPeopleByProjectIdQuery from '../../graphql/getPeopleByProjectIdQuery.graphql';
import getPeopleByOrganitazionIdQuery from '../../graphql/getPeopleByOrganitazionIdQuery.graphql';
import getDailiesByProjectId from '../../graphql/getDailiesByProjectId.graphql';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import moment from 'moment';

const { TabPane } = Tabs;

const dateStr = moment().format('YYYY-MM-DD');
const startOfDay = moment(dateStr).startOf('day');
const endOfDay = moment(dateStr).endOf('day');

const ProjectComponent: React.FC = () => {
  const { o_id, p_id } = useParams<{ o_id?: string; p_id?: string }>();
  const {
    loading,
    error,
    data: dataProject,
  } = useQuery(getProjectsByIdsQuery, {
    variables: { id: Number(o_id) },
  });
  const { loading: projectUsersLoading, data: dataProjectUsers } = useQuery(
    getPeopleByProjectIdQuery,
    {
      variables: { id: Number(p_id) },
    }
  );
  const { loading: organizationUsersLoading, data: dataOrganizationUsers } =
    useQuery(getPeopleByOrganitazionIdQuery, {
      variables: { id: Number(o_id) },
    });

  const { loading: dailiesLoading, data: dataDailies } = useQuery(
    getDailiesByProjectId,
    {
      variables: { id: Number(p_id), startOfDay, endOfDay },
    }
  );

  const project = dataProject?.organizations[0] || {};
  const project_users = dataProjectUsers?.people || [];
  const organization_users = dataOrganizationUsers?.people || [];
  const dailies = dataDailies?.dailyReports || [];

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  if (
    loading ||
    projectUsersLoading ||
    dailiesLoading ||
    organizationUsersLoading
  ) {
    return <Spin size="large" tip="Loading..." />;
  }

  return (
    <Row justify="center">
      <Col xl={20}>
        <Row
          justify="center"
          style={{ maxWidth: '100%' }}
          gutter={[{ md: 40 }, 40]}
        >
          <Col xl={7} className="Cards">
            <Row>
              <Col span={24} className="">
                <ProjectDetail project={project} />
              </Col>
            </Row>
          </Col>
          <Col xl={17}>
            <Row>
              <Col span={24} className="Cards">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Team" key="1">
                    <Row gutter={[{}, { xs: 10, md: 10, lg: 40 }]}>
                      <Col span={24} className="">
                        <ProjectUsers
                          title={project?.name + ' team'}
                          loading={organizationUsersLoading}
                          users={organization_users}
                        />
                      </Col>
                      <Col span={24} className="">
                        <ProjectUsers
                          title={'Melt Studio team'}
                          loading={projectUsersLoading}
                          users={project_users}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Daily" key="2">
                    <Row gutter={[{}, { xs: 10, md: 10, lg: 40 }]}>
                      <Col span={24}>
                        <ProjectDailies
                          title={`${project?.name} daily reports`}
                          loading={dailiesLoading}
                          dailies={dailies}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="..." key="3"></TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectComponent;
