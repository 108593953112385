import React, { useState } from 'react';
import { Layout } from 'antd';
import { Button } from 'antd/lib/radio';
import MEDICAL_URL from '../../config';
import NewWindow from '../../../../utils/common-component';

const { Content } = Layout;
const MedicalReport: React.FC = () => {
  const [isOpen, setOpenState] = useState(false);

  return (
    <Content>
      <Button onClick={() => setOpenState(true)}>Submit Medical Report</Button>
      {isOpen && (
        <NewWindow url={MEDICAL_URL} close={() => setOpenState(false)}>
          Medical report
        </NewWindow>
      )}
    </Content>
  );
};

export default MedicalReport;
