import LatestTalkWidget from './widgets/latest_talk';
import UpcomingEventsWidget from './widgets/upcoming_events';
import TalkScreen from './screens/talk';
import TalkListScreen from './screens/talks';
import CalendarScreen from './screens/calendar';

export default {
  dependsOn: ['melt-connect-people'],
  routes: [
    {
      exact: true,
      path: '/talk/:id',
      // screen: 'talk',
      component: TalkScreen,
      menu: { visibility: 0 /* ShowInMenu.NEVER */ },
      data: {
        authRequired: true,
        idFromPath: true,
        actions: '',
      },
    },
    {
      exact: true,
      path: '/talks/:page',
      // screen: 'talks',
      component: TalkListScreen,
      menu: {
        link: '/talks/1',
        visibility: 1 /* ShowInMenu.AUTHD */,
        name: 'Talks',
      },
      data: {
        authRequired: true,
        actions: '',
        filters: { eventType: { defaultValue: 'talk' } },
      },
    },
    {
      exact: true,
      path: '/calendar',
      // screen: 'calendar',
      component: CalendarScreen,
      menu: { visibility: 1 /* ShowInMenu.AUTHD */, name: 'Calendar' },
      data: {
        authRequired: true,
        actions: '',
      },
    },
  ],
  homepageWidgets: [
    {
      meta: {
        title: '🎤 Latest talk',
      },
      component: LatestTalkWidget,
      action: '',
    },
    {
      meta: {
        title: '🗓 Upcoming events',
      },
      component: UpcomingEventsWidget,
      action: '',
    },
  ],
  store: {
    namespace: 'events',
  },
};
