import React from 'react';
import { Row, Col } from 'antd';
import { Main } from './main';
import { TableManager } from './tableManager';
import useCurrentprofile from '../../../melt-connect-people/hooks/use_current_profile';

const VacationsRequestManager: React.VFC = () => {
  const { userProfile } = useCurrentprofile();

  return (
    <Row style={{ marginTop: '25px' }} justify="center">
      <Col span={23} md={22}>
        <Row gutter={[15, 25]} justify="center">
          <Main profile={userProfile} />
          <TableManager id={userProfile?.id ? userProfile.id : ''} />
        </Row>
      </Col>
    </Row>
  );
};

export default VacationsRequestManager;
