import React from 'react';
import { Affix, Button, Col, Row, Tooltip } from 'antd';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { useHistory, useParams } from 'react-router';
import ProjectManagers from '../../../../components/common/menu_managers/menuManagers';
import { ProjectManager } from '../../types';

export interface ControlsProps {
  reloadDaily: (event: React.MouseEvent) => void;
  loading: boolean;
  previousDaily: string | null;
  nextDaily: string | null;
  filters: { activeDaily?: string | null };
  setDailyFilters: ({ activeDaily }: { activeDaily: string | null }) => void;
  displayDate: string | null;
  projectManagers: ProjectManager[];
  onClick: (projectManager: string) => void;
}

const CollapseControls: React.FC<ControlsProps> = ({
  reloadDaily,
  loading,
  previousDaily,
  nextDaily,
  filters,
  setDailyFilters,
  displayDate,
  projectManagers,
  onClick,
}) => {
  const isExpanded = () => filters.activeDaily == 'all';
  const history = useHistory();
  const { dateStr }: { dateStr?: string } = useParams();
  const handleActiveDaily = (value: string | null) => {
    if (!dateStr) {
      history.push(`/daily/${displayDate}`);
      setDailyFilters({ ...filters, activeDaily: value });
    } else {
      setDailyFilters({ ...filters, activeDaily: value });
    }
  };

  return (
    <Affix style={{ position: 'absolute', right: '10px' }} offsetBottom={10}>
      <Row justify="end" gutter={10}>
        <Col offset={0}>
          <Tooltip title="Reload reports">
            <Button onClick={reloadDaily} loading={loading}>
              <ReloadOutlined />
            </Button>
          </Tooltip>
        </Col>
        <Col offset={0}>
          <ProjectManagers
            projectManagers={projectManagers}
            onClick={onClick}
          />
        </Col>
        <Col offset={0}>
          <Tooltip title="Expand all">
            <Button
              icon={<EyeOutlined />}
              onClick={() => handleActiveDaily(isExpanded() ? '' : 'all')}
            >
              {isExpanded() ? 'Collapse all' : 'Expand all'}
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Button onClick={() => handleActiveDaily(previousDaily)}>
            Previous
          </Button>
        </Col>

        <Col>
          <Button onClick={() => handleActiveDaily(nextDaily)}>Next</Button>
        </Col>
      </Row>
    </Affix>
  );
};

export default CollapseControls;
