import React from 'react';
import { Col, Button, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import { Profile } from '../../../melt-connect-people/types';

interface MainProps {
  profile?: Profile;
}

export const Main: React.VFC<MainProps> = ({ profile }) => {
  /* const [showModalRequestVacation, setShowModalRequestVacation] =
    useState<boolean>(false);

  const openModal = () => setShowModalRequestVacation(true);
  const closeModal = () => setShowModalRequestVacation(false); */

  return !profile ? (
    <Col xs={12} offset={12}>
      <Spin />
    </Col>
  ) : (
    <>
      <Col span={20}>
        <h2>
          Hi, {profile.firstName} {profile.lastName}!
        </h2>
        <p>Now you can easily request vacations</p>
      </Col>
      <Col span={4}>
        <NavLink to="/vacations" style={{ float: 'right' }}>
          <Button type="primary">Vacations</Button>
        </NavLink>
      </Col>
    </>
  );
};
