import DailyStandupWidget from './widgets/daily_standup';
import saga from './store/saga';
import DailyScreen from './screens/daily';
import prevDailyStandup from './widgets/daily_standup/previously_daily';

export default {
  dependsOn: ['melt-connect-people'],
  routes: [
    {
      exact: true,
      path: '/daily/:dateStr?',
      // screen: 'daily',
      component: DailyScreen,
      menu: {
        link: '/daily',
        visibility: 1 /* ShowInMenu.AUTHD */,
        name: 'Daily Standup',
      },
      data: {
        authRequired: true,
        idFromPath: true,
        actions: '',
        filters: {
          activeDaily: { defaultValue: '' },
          selectedManager: { defaultValue: 'All' },
        },
      },
    },
  ],
  homepageWidgets: [
    {
      meta: {
        title: '🎯 Your daily standup from Yesterday',
      },
      component: prevDailyStandup,
      action: '',
    },
    {
      meta: {
        title: '🕴 Your daily standup for Today',
      },
      component: DailyStandupWidget,
      action: '',
    },
  ],
  store: {
    namespace: 'daily',
    saga,
  },
};
