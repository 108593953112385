import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';

import { gqlClient } from './api';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { Layout } from 'antd';

// Shared Components
import Navbar from './components/navbar';
import Footer from './components/footer';
import { setupAmplify } from './config';
import { loadSettings, SettingsProvider } from './settings';

import './App.less';
import CustomThemeProvider from './theme';

const { Content } = Layout;

setupAmplify();

const App = (): ReactElement => {
  const settings = loadSettings();

  const appRoutes = settings.routes.map((r, idx) => <Route key={idx} {...r} />);

  return (
    <ApolloProvider client={gqlClient}>
      <Provider store={settings.store}>
        <ConnectedRouter history={settings.history}>
          <SettingsProvider settings={settings}>
            <CustomThemeProvider>
              <Layout style={{ minHeight: '100vh' }}>
                <Navbar />
                <Content style={{ marginTop: 64 }}>
                  <Switch>{appRoutes}</Switch>
                </Content>
                <Footer />
              </Layout>
            </CustomThemeProvider>
          </SettingsProvider>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
