import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';

interface Props {
  icon: React.ReactNode;
  title: string;
  children: string;
  noBottomDivider?: boolean;
}

const ProfileDetailItem: React.FC<Props> = ({
  icon,
  title,
  children,
  noBottomDivider = false,
}) => (
  <Grid container style={{ marginTop: '8px' }}>
    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
      <Typography
        variant="subtitle1"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {icon}
        <span style={{ marginLeft: '10px' }}>{title}</span>
      </Typography>
    </Grid>

    <Grid
      item
      xs={6}
      sm={6}
      md={6}
      lg={6}
      xl={6}
      style={{ justifyContent: 'right', display: 'flex', alignItems: 'center' }}
    >
      <Typography variant="subtitle1">{children}</Typography>
    </Grid>

    {noBottomDivider || (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '8px' }}
      >
        <Divider />
      </Grid>
    )}
  </Grid>
);

export default ProfileDetailItem;
