import React, { FC } from 'react';
import packageJson from '../../../package.json';

const Footer: FC = () => {
  return (
    <div style={{ width: '100%', textAlign: 'center', padding: 30 }}>
      Melt Connect v{packageJson.version}
    </div>
  );
};

export default Footer;
