import { shallowEqual, useSelector } from 'react-redux';
import { MeltDaily } from '../../types';
import { BaseAppState } from '../../../../redux';

interface OwnDailySelectorInterface {
  loading: boolean;
  ownDaily: MeltDaily | null;
  error: string | null;
}

export const ownDailySelector = (
  state: BaseAppState
): OwnDailySelectorInterface => ({
  loading: state.daily.detail.loading,
  ownDaily: state.daily.detail.item,
  error: state.daily.detail.error,
});

const useOwnDailySelector = (): OwnDailySelectorInterface =>
  useSelector(ownDailySelector, shallowEqual);

export default useOwnDailySelector;
