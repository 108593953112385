import saga from './store/saga';
import ServicesScreen from './screens/services';

export default {
  routes: [
    {
      exact: true,
      path: '/services',
      // screen: 'services',
      component: ServicesScreen,
      menu: { visibility: 1 /* ShowInMenu.AUTHD */, name: 'Services' },
      data: {
        authRequired: true,
        actions: '',
        filters: {
          q: { defaultValue: '' },
        },
      },
    },
  ],
  store: {
    namespace: 'services',
    saga,
  },
};
