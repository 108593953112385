import React from 'react';

import MapView from './map';
import ListView from './list';
import useFilterViewSelector from '../../../../hooks/store/use_filter_view_selector';

const PeopleView: React.FC = () => {
  const view = useFilterViewSelector();

  return view === 'map' ? <MapView /> : <ListView />;
};

export default PeopleView;
