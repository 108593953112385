import React from 'react';
import { Layout, Typography, Spin } from 'antd';
import OwnDailyView from '../../components/own_daily';

import useCurrentprofile from '../../../melt-connect-people/hooks/use_current_profile';

import moment from 'moment';
import { useQuery } from '@apollo/client';

import getLastDailyReportForUser from '../../graphql/getLastDailyReportForUser.graphql';

const { Content } = Layout;
const { Text } = Typography;

const prevDailyStandup: React.FC = () => {
  const {
    userProfile: profile,
    error,
    loading: LoadingProfile,
  } = useCurrentprofile();
  const endOfDayYesterday = moment().subtract(1, 'days').endOf('day');
  const { data } = useQuery(getLastDailyReportForUser, {
    variables: {
      userId: profile?.id,
      endOfYesterday: endOfDayYesterday,
    },
  });

  const ownDaily = data?.dailyReports[0];
  if (error) {
    return <Text>{error.message}</Text>;
  }

  if (LoadingProfile) {
    return <Spin />;
  }

  return (
    <Content>
      <OwnDailyView ownDaily={ownDaily} isEditable={false} />
    </Content>
  );
};

export default prevDailyStandup;
