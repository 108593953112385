import { ApolloError, useQuery } from '@apollo/client';
import moment from 'moment';
import { useState, useEffect } from 'react';
import getPeopleWithBirthdayQuery from '../graphql/getPeopleWithBirthdayQuery.graphql';
import { Profile } from '../types';

interface PeopleWithBirthday {
  birthdays: Profile[];
  loading: boolean;
  error: ApolloError | null;
}

const useBirthdays = (): PeopleWithBirthday => {
  const [birthdays, setBirthdays] = useState([]);
  const { data, loading, error } = useQuery(getPeopleWithBirthdayQuery);

  if (error) {
    return { birthdays, loading: false, error };
  }

  useEffect(() => {
    if (data) {
      setBirthdays(
        data.people.filter(
          (p: Profile) => moment().month() === moment(p.birthday).month()
        )
      );
    }
  }, [data]);

  return { birthdays, loading, error: null };
};

export default useBirthdays;
