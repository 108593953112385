import React from 'react';
import ProfileView from '../components/profile';
import { Layout } from 'antd';

const { Content } = Layout;

const ProfileScreen: React.FC<{}> = () => {
  return (
    <Layout>
      <Layout>
        <Content style={{ minHeight: 600 }}>
          <ProfileView />
        </Content>
      </Layout>
    </Layout>
  );
};

export default ProfileScreen;
