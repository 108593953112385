import {
  LOCATION_CHANGE,
  matchSelectorFn,
  RouterRootState,
} from 'connected-react-router';

import { defaults, pickBy, mapValues } from 'lodash';

import {
  put,
  call,
  fork,
  take,
  takeEvery,
  select,
  PutEffect,
  SelectEffect,
  Effect,
  ForkEffect,
  CallEffect,
  TakeEffect,
  ChannelTakeEffect,
  ChannelPutEffect,
} from 'redux-saga/effects';
import { createMatchSelector, push } from 'connected-react-router';

import { RouteDataSpec, RouteSpec } from '../../types';
import {
  updateFiltersFromUrl,
  SET_URL_FILTERS,
  setUrlFilters,
} from './actions';

import { qsToObj, buildQs } from '../../utils/url';
import { loadUser } from '../user/actions';
import { getAuthenticatedUser } from '../user/selectors';

import { matchPath } from 'react-router';
import { channel, Channel } from 'redux-saga';
import { Action } from 'redux';
import { Location } from 'history';

interface NavSpec {
  [path: string]: RouteDataSpec;
}

type TNav = Location &
  ReturnType<matchSelectorFn<RouterRootState, {}>> &
  Channel<Action>;

type TNavEffects =
  | PutEffect
  | SelectEffect
  | CallEffect<Channel<unknown>>
  | ForkEffect
  | ChannelPutEffect<Action>;

declare global {
  interface Window {
    gtag?: (arg0: string, arg1: string, arg2: { [k: string]: string }) => void;
  }
}

type NavSagaCreator = (routes: RouteSpec[]) => () => IterableIterator<unknown>;

const createSaga: NavSagaCreator = (routes: RouteSpec[]) => {
  function* clearWaitingForAuthQueueSaga(
    chan: Channel<Action>
  ): Generator<
    PutEffect | TakeEffect | ChannelTakeEffect<Action>,
    void,
    Action
  > {
    try {
      yield take(loadUser.SUCCESS);
      while (true) {
        const action = yield take(chan);
        yield put(action);
      }
    } catch (e) {
      console.log('Error PUTting action waiting for auth', e);
      throw e;
    }
  }

  function* navigationLoaderSaga(): Generator<TNavEffects, void, TNav> {
    // Build data specs from routes
    const NAV_SPEC: NavSpec = routes.reduce((acc, r) => {
      if (r.data) acc[r.path] = r.data;
      return acc;
    }, {} as NavSpec);

    try {
      const currentLocation = yield select((s) => s.router.location);
      const match = routes
        .filter((path) => path.data)
        .find((path) => matchPath(currentLocation.pathname, path));

      if (window.gtag) {
        window.gtag('config', 'G-1LRQFZR5K5', {
          page_path: currentLocation.pathname,
        });
      }

      if (!match) return;
      const cleanPath: string = match.path;

      const spec = NAV_SPEC[cleanPath];

      const isValidOption = (v: string, k: string): boolean => {
        if (!spec.filters || !spec.filters[k]) return false;
        const options = spec.filters[k].options;
        if (!options) return true;
        return v in options;
      };

      // Convert qs to object and remove inclaid options
      const cleanFilters = pickBy(
        qsToObj(currentLocation.search),
        isValidOption
      );

      // Add defaults to filter from defaultValue
      const filters = defaults(
        cleanFilters,
        mapValues(spec.filters, (f) => f.defaultValue)
      );

      yield put(updateFiltersFromUrl(filters));

      const user = yield select(getAuthenticatedUser);

      let userLoadedChan = null;
      if (spec.authRequired && !user) {
        userLoadedChan = yield call(channel);
        yield fork(clearWaitingForAuthQueueSaga, userLoadedChan);
      }

      for (const actionCreator of spec.actions) {
        let action = actionCreator();
        if (spec.idFromPath) {
          const matchSelector = createMatchSelector(match.path);
          const paramsMatch = yield select(matchSelector);

          action = actionCreator(paramsMatch.params);
        }

        if (spec.authRequired && !user && userLoadedChan) {
          yield put(userLoadedChan, action);
        } else {
          yield put(action);
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  function* setUrlQuerystringSaga({
    payload,
  }: ReturnType<typeof setUrlFilters>): Generator<
    Effect,
    void,
    { pathname: string }
  > {
    try {
      const currentLocation = yield select((s) => s.router.location);
      const qs = buildQs(payload);
      let path = currentLocation.pathname;
      if (qs) {
        path += `?${qs}`;
      }
      yield put(push(path));
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  function* navSaga(): Generator<ForkEffect, void, void> {
    yield takeEvery(LOCATION_CHANGE, navigationLoaderSaga);
    yield takeEvery(SET_URL_FILTERS as string, setUrlQuerystringSaga);
  }
  return navSaga;
};

export default createSaga;
