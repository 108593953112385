import React, { ReactNode } from 'react';
import { Col, Popover, Row, Tooltip } from 'antd';
import CardPosition from './card';
import { Select, Typography, Layout, Spin, Alert } from 'antd';
import moment from 'moment';
import { range } from 'lodash';
import { FilterFilled } from '../../../../kit/icons';
import useNavFiltersSelector from '../../../../hooks/store/use_nav_filters_selector';
import getFirstReviewDate from '../../graphql/getFirstReviewDate.graphql';
import { useDispatch } from 'react-redux';
import { setUrlFilters } from '../../../../redux/nav/actions';
import useHallOfFame from '../../hooks/use_hall_of_fame';
import { useQuery } from '@apollo/client';

const { Option } = Select;
const { Title, Text } = Typography;
const { Content } = Layout;

interface MonthValueProps {
  key: string;
  value: number;
  label: string;
}

const HallOfFamePodium: React.FC = () => {
  const dispatch = useDispatch();
  const {
    data,
    loading: loadingFirstReview,
    error: errorFirstReview,
  } = useQuery(getFirstReviewDate);
  const filters = useNavFiltersSelector() as {
    month?: number | null;
    year?: number | null;
  };

  const {
    data: hallOfFame,
    loading,
    error,
  } = useHallOfFame(
    filters.month || moment().month(),
    filters.year || moment().year()
  );

  if (error || errorFirstReview) {
    return <Alert type="error" message="Error loading hall of fame" />;
  }

  if (loading || loadingFirstReview) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  const { date } = data;

  const handleYearChange = (field: string, value: number): void => {
    const newFilters = Object.assign({}, filters, { [field]: value });
    dispatch(setUrlFilters(newFilters));
  };

  const handleMonthChange = (field: string, value: MonthValueProps): void => {
    const newFilters = Object.assign({}, filters, { [field]: value.value });
    dispatch(setUrlFilters(newFilters));
  };

  const getMonths = (): ReactNode[] => {
    const currentMonth: number = moment().month();
    const currentYear: number = moment().year();
    return moment.months().map((month, index) => {
      if (filters.year && filters.year < currentYear) {
        return (
          <Option key={index} value={index}>
            {month}
          </Option>
        );
      }
      if (filters.year == currentYear) {
        return (
          index <= currentMonth && (
            <Option key={index} value={index}>
              {month}
            </Option>
          )
        );
      }
      return null;
    });
  };

  const getYears = (): ReactNode[] => {
    const firstYear = moment(date).year();
    return range(firstYear, moment().year() + 1).map((year) => (
      <Option key={year} value={year}>
        {year}
      </Option>
    ));
  };

  const defaultYearValue = () => {
    if (moment().month() === 0) {
      return moment().year() - 1;
    }
    return moment().year();
  };

  const defaultMonthValue = () => {
    if (filters.month) {
      return {
        key: moment.months()[filters.month],
        value: filters.month,
        label: moment.months()[filters.month],
      };
    }

    if (moment().month() === 0) {
      return {
        key: moment.months()[moment().month()],
        value: moment().month(),
        label: moment.months()[moment().month()],
      };
    }

    return {
      key: moment.months()[moment().month() - 1],
      value: moment().month() - 1,
      label: moment.months()[moment().month() - 1],
    };
  };

  const FiltersOptions = () => {
    return (
      <Content style={{ padding: 24 }}>
        <Title level={4} type="secondary">
          Month
        </Title>
        <Select
          defaultValue={defaultMonthValue()}
          labelInValue
          style={{ width: '100%' }}
          onChange={(value): void =>
            handleMonthChange('month', value as MonthValueProps)
          }
        >
          {getMonths()}
        </Select>
        <br />
        <br />
        <Title level={4} type="secondary">
          Year
        </Title>
        <Select
          defaultValue={filters.year || defaultYearValue()}
          style={{ width: '100%' }}
          onChange={(value): void => handleYearChange('year', value as number)}
        >
          {getYears()}
        </Select>
      </Content>
    );
  };

  if (!hallOfFame) return <FiltersOptions />;

  return (
    <Row justify="center" style={{ height: '100%' }}>
      <Col xl={12} lg={16} xs={24} style={{ height: '80%', padding: '40px 0' }}>
        <Row justify="center">
          <Col xs={23} lg={24}>
            <Col span={24} className="Cards Leaderboard-list">
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Title level={3} style={{ padding: '5px 10px', margin: '0' }}>
                    Leaderboard
                  </Title>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Text type="secondary">
                    {moment.months(Number(filters.month))}
                  </Text>
                </Col>
              </Row>
              <Row
                style={{ height: '50%', marginTop: '70px' }}
                justify="space-around"
              >
                {hallOfFame.length === 0 ? (
                  <Text>No data yet</Text>
                ) : (
                  hallOfFame[0] && (
                    <>
                      {hallOfFame[1] && (
                        <Col span={7}>
                          <CardPosition
                            person={hallOfFame[1]}
                            coins={hallOfFame[1].coins}
                            position="2nd"
                          />
                        </Col>
                      )}
                      <Col span={7}>
                        <CardPosition
                          person={hallOfFame[0]}
                          coins={hallOfFame[0].coins}
                          position="1st"
                        />
                      </Col>
                      {hallOfFame[2] && (
                        <Col span={7}>
                          <CardPosition
                            person={hallOfFame[2]}
                            coins={hallOfFame[2].coins}
                            position="3rd"
                          />
                        </Col>
                      )}
                    </>
                  )
                )}
              </Row>
              <Popover
                placement="topLeft"
                title={'Filter by'}
                content={<FiltersOptions />}
                trigger="click"
              >
                <Tooltip title="Filter by">
                  <FilterFilled className="Leaderboard-filter" />
                </Tooltip>
              </Popover>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HallOfFamePodium;
