import React, { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Calendar, Badge, Alert, Spin } from 'antd';
import { MeltEvent } from '../../types';
import { Moment } from 'moment';
import getListEventCalendarQuery from '../../graphql/getListEventCalendarQuery.graphql';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { nationalHolidays } from '../../../../utils/nationalHolidays';
import { HolidaysCalendary } from '../calendar/meltholidaydays';

const CalendarScreen: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [value, setValue] = useState(moment());
  const [from, setFrom] = useState(moment(selectedDate).utc().startOf('month'));
  const [to, setTo] = useState(moment(selectedDate).utc().endOf('month'));
  const { loading, error, data } = useQuery(getListEventCalendarQuery, {
    variables: {
      sort: 'beginAt:desc',
      from,
      to,
    },
  });

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="warning" />
    );
  }
  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  const getEventLink = (event: MeltEvent): string => {
    switch (event.type?.toLowerCase()) {
      case 'talk':
        return `/talk/${event.id}`;
      default:
        return '#';
    }
  };
  const holidaysDays: HolidaysCalendary[] = [];
  const holidaysInMelt = nationalHolidays;
  const date = new Date();
  let dateOffset = date.getTimezoneOffset() / 60;
  let timeZoneSign = '-';
  if (dateOffset > 0) {
    timeZoneSign = '-';
  } else if (dateOffset < 0) {
    timeZoneSign = '+';
    dateOffset = -dateOffset;
  }
  const zeroPad = (num: number) => String(num).padStart(2, '0');
  const utcOffset = zeroPad(dateOffset);
  Object.keys(holidaysInMelt).forEach((key) => {
    holidaysInMelt[key].forEach((item) => {
      const beginAt = item + `T00:00:00.000${timeZoneSign}${utcOffset}:00`;
      const actuallyHoliday = {
        id: item + ` ${key}`,
        title: key,
        description: `Holiday in ${key}`,
        beginAt,
        endAt: beginAt,
        type: '',
      };
      holidaysDays.push(actuallyHoliday);
    });
  });
  const events: MeltEvent[] = data.talks;
  const dateFilter =
    (date: Moment) =>
    (e: MeltEvent): boolean => {
      if (date === null) throw new Error('Calendar date is null');
      const eventDate = new Date(Date.parse(e.beginAt));
      return (
        eventDate.getFullYear() == date.year() &&
        eventDate.getDate() == date.date() &&
        eventDate.getMonth() == date.month()
      );
    };
  const getListData = (date: Moment): MeltEvent[] => {
    return events.filter(dateFilter(date));
  };
  const dateHolidayFilter =
    (date: Moment) =>
    (e: HolidaysCalendary): boolean => {
      if (date === null) throw new Error('Calendar date is null');
      const eventHolidayDate = new Date(Date.parse(e.beginAt));
      return (
        eventHolidayDate.getFullYear() == date.year() &&
        eventHolidayDate.getDate() == date.date() &&
        eventHolidayDate.getMonth() == date.month()
      );
    };
  const getListDataOfHolidays = (date: Moment): HolidaysCalendary[] => {
    return holidaysDays.filter(dateHolidayFilter(date));
  };

  const dateCellRender = (date: Moment): ReactNode => {
    const listData = getListData(date);
    const listHolydayData = getListDataOfHolidays(date);
    return (
      <>
        <ul className="events">
          {listData.map((item) => (
            <li key={item.id}>
              <NavLink to={getEventLink(item)}>
                <Badge status="success" text={item.title} />
              </NavLink>
            </li>
          ))}
        </ul>
        <ul className="events">
          {listHolydayData.map((item) => (
            <li key={item.id}>
              <Badge status="success" text={item.description} />
            </li>
          ))}
        </ul>
      </>
    );
  };

  const handleOnSelect = (date: Moment): void => {
    const selectedEvents = getListData(date);
    if (selectedEvents.length > 1) {
      alert('TODO: Show event select');
    }
    setValue(date);
    setSelectedDate(date);
    setFrom(moment(date).utc().startOf('month'));
    setTo(moment(date).utc().endOf('month'));
  };

  const onPanelChange = (newValue: Moment) => {
    setValue(newValue);
  };
  return (
    <Calendar
      value={value}
      style={{ padding: 24 }}
      dateCellRender={dateCellRender}
      onSelect={handleOnSelect}
      onPanelChange={onPanelChange}
    />
  );
};

export default CalendarScreen;
