import React from 'react';

import { Layout } from 'antd';

const { Content, Sider } = Layout;

import ServicesView from '../components/services';
import ServiceListFilters from '../components/service_filters';

const PeopleScreen: React.FC = () => {
  return (
    <Layout>
      <Sider
        width={300}
        breakpoint="md"
        collapsedWidth="0"
        className="site-layout-background"
      >
        <ServiceListFilters />
      </Sider>
      <Layout style={{ minHeight: '100vh', padding: '0 0 24px 0' }}>
        <Content>
          <ServicesView />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PeopleScreen;
