import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Menu, Spin, Alert, Layout } from 'antd';
import { groupBy } from '../../../../utils/groupby';
import { useQuery } from '@apollo/client';
import { Topic } from '../../types';
import listTopicsQuery from '../../graphql/listTopicsQuery.graphql';
import getTopicQuery from '../../graphql/getTopicQuery.graphql';

const { SubMenu } = Menu;
const { Content } = Layout;

const HandbookMenu: React.FC = () => {
  const { data: dataTopics, loading, error } = useQuery(listTopicsQuery);

  const { slug } = useParams<{ slug?: string }>();
  const {
    data,
    loading: topicLoading,
    error: topicError,
  } = useQuery(getTopicQuery, {
    variables: {
      slug: slug ? slug : 'general',
    },
  });

  const initialThemeState: { current: string; theme: string } = {
    current: '',
    theme: '',
  };
  const [themeState, setThemeState] = useState(initialThemeState);

  if (loading || topicLoading) return <Spin />;
  if (error) return <Alert message={error} type="error" />;
  if (topicError) return <Alert message={topicError} type="error" />;

  const topics: Topic[] = dataTopics?.topics || [];
  const currentTopic = data?.topics[0] || {};
  const topicsByCategory = groupBy(
    ({ category }) => category,
    (topic) => topic,
    (category, items) =>
      [
        category,
        items
          .sort((x, y) => x.order - y.order)
          .filter(
            (topic) => topic.id !== '5' && topic.category !== topic.title
          ),
      ] as const,
    topics
  );

  const handleClick = (e: { key: string }): void => {
    setThemeState({
      ...themeState,
      current: e.key,
    });
  };
  const menuItems = () =>
    topicsByCategory.map(([category, items]) => (
      <SubMenu
        key={category}
        title={
          <NavLink
            style={{
              overflow: 'auto',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            to={`/handbook/${category.toLowerCase()}`}
          >
            <span>
              <span>{category}</span>
            </span>
          </NavLink>
        }
      >
        {Array.from(items, (topic) => (
          <Menu.Item key={topic.id}>
            <NavLink
              style={{
                overflow: 'auto',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              to={`/handbook/${topic.slug}`}
            >
              {topic.title}
            </NavLink>
          </Menu.Item>
        ))}
      </SubMenu>
    ));

  const selected = [];
  const open = [];

  if (currentTopic) {
    selected.push(currentTopic.category.toLowerCase(), currentTopic.id);
    open.push(currentTopic.category.toLowerCase());
  }

  return (
    <Content>
      <Menu
        mode="inline"
        defaultSelectedKeys={selected}
        defaultOpenKeys={open}
        onClick={handleClick}
        selectedKeys={[themeState.current]}
        style={{ height: '100%', borderRight: 0, textTransform: 'capitalize' }}
      >
        {menuItems()}
      </Menu>
    </Content>
  );
};

export default HandbookMenu;
