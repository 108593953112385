import React, { useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { DeleteFilled } from '../../../kit/icons';

interface Props {
  task: () => void;
  message?: string;
}

const ConfirmDialogBox: React.FC<Props> = ({
  task,
  message = 'Are you sure you want to delete this request?',
}) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(message);

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setModalText('Deleting...');
    task();
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Tooltip title="Delete" color="#e73125">
        <DeleteFilled onClick={showModal} style={{ color: '#e73125' }} />
      </Tooltip>

      <Modal
        title="Confirm Delete"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
};

export default ConfirmDialogBox;
