import Amplify from '@aws-amplify/core';

import prodConfig from './aws-exports.production';
import localConfig from './aws-exports.local';

interface ConfigSpec {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [propName: string]: any;
}

interface ConfigsSpec {
  [propName: string]: ConfigSpec;
}

const configs: ConfigsSpec = {
  localhost: localConfig,
  'connect.meltstudio.co': prodConfig,
  'connect-vercel.meltstudio.co': prodConfig,
};

export function getConfig(): ConfigSpec {
  const host = window.location.hostname || 'localhost';
  return configs[host];
}

export function setupAmplify(): void {
  Amplify.configure(getConfig());
}
