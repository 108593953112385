import React, { useEffect } from 'react';
import { Row, Col, Spin, notification } from 'antd';
import { Overview } from './overview';
import { RequestsTable } from './table';
import { Main } from './main';
import useCurrentprofile from '../../../melt-connect-people/hooks/use_current_profile';
import useVacationRequest from '../../hooks/use_vacation_request';

const VacationsManager: React.VFC = () => {
  const { userProfile } = useCurrentprofile();
  const { vacationRequests, loading, refetch } = useVacationRequest(
    userProfile?.id
  );

  useEffect(() => {
    if (!userProfile) return;

    if (!userProfile.country) {
      notification.warn({
        message: (
          <p>
            You do not have a country assigned, please select one in{' '}
            <a href="/redirect/profile/">your settings</a>
          </p>
        ),
      });
    }
  }, [userProfile]);

  return !userProfile ? (
    <Col span={24} style={{ textAlign: 'center' }}>
      <Spin size="large" />
    </Col>
  ) : (
    <Row style={{ marginTop: '25px' }} justify="center">
      <Col span={23} md={22}>
        <Row gutter={[15, 25]} justify="center">
          <Main profile={userProfile} onSumitForm={refetch} />
          <Overview
            vacationRequests={vacationRequests}
            profile={userProfile}
            loading={loading}
          />
          <RequestsTable
            vacationRequests={vacationRequests}
            profile={userProfile}
            loading={loading}
            refetch={refetch}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default VacationsManager;
