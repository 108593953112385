import { all, AllEffect } from 'redux-saga/effects';
import { combineReducers, Store, Reducer } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import usersReducer /* , { UserState } */ from './user/reducer';
import navReducer /* , { NavState } */ from './nav/reducer';
import { initStore } from './store';
import type { SagaType } from '../settings/loader';
import { ProfileState } from '../modules/melt-connect-people/store/reducer';
import { DailyState } from '../modules/melt-connect-daily-standup/store/reducer';

export const history = createBrowserHistory();

export type BaseAppState = {
  router: ReturnType<typeof connectRouter>;
  nav: ReturnType<typeof navReducer>;
  users: ReturnType<typeof usersReducer>;
  profiles: ProfileState;
  daily: DailyState;
};

type CreatedStore = {
  store: Store;
  rootReducer: Reducer;
  rootSaga: () => Generator;
};

export const createStore = (
  reducers: { [namespace: string]: Reducer },
  sagas: SagaType[]
): CreatedStore => {
  const rootReducer = combineReducers(
    Object.assign(
      {
        router: connectRouter(history),
        nav: navReducer,
        users: usersReducer,
      },
      reducers
    )
  );

  function* rootSaga(): Generator<
    AllEffect<IterableIterator<unknown>>,
    void,
    void
  > {
    try {
      yield all(sagas.map((s) => s()));
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  return {
    store: initStore(rootReducer, rootSaga, history),
    rootReducer,
    rootSaga,
  };
};
