import { getConfig } from './config';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

export const config = getConfig();

const baseLink = createUploadLink({
  uri: config.strapi.graphQlEndpoint,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('jwt');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const gqlClient = new ApolloClient({
  link: authLink.concat(baseLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
  cache: new InMemoryCache(),
});
