import React from 'react';
import { Layout } from 'antd';
import HandbookContent from '../components/handbook_content';
import HandbookMenu from '../components/handbook_menu';

const { Sider } = Layout;

const Handbook: React.FC<{}> = () => {
  return (
    <Layout>
      <Sider
        width={300}
        breakpoint="md"
        collapsedWidth="0"
        className="site-layout-background"
      >
        <HandbookMenu />
      </Sider>
      <Layout style={{ minHeight: '100vh', padding: '0 24px 24px' }}>
        <HandbookContent />
      </Layout>
    </Layout>
  );
};

export default Handbook;
