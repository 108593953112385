import React from 'react';
import Title from '../../../../../components/common/title';

interface Props {
  icon: React.ReactNode;
  title: string;
  children: string | React.ReactNode;
}

const ProfileDetailBiggerItem: React.FC<Props> = ({
  icon,
  title,
  children,
}) => {
  return (
    <div style={{ marginTop: '15px' }}>
      <Title textAlign="left" variant="subtitle1">
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <span>{icon}</span>
          <span style={{ marginLeft: '10px' }}>{title}</span>
        </div>
      </Title>
      <div style={{ marginTop: '10px' }}>{children}</div>
    </div>
  );
};

export default ProfileDetailBiggerItem;
