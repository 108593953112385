import { shallowEqual, useSelector } from 'react-redux';
import { BaseAppState } from '../../redux';
import { User } from '../../types';

export const authSelector = (state: BaseAppState): User | null =>
  state.users.user;

const useAuthSelector = (): User | null =>
  useSelector(authSelector, shallowEqual);

export default useAuthSelector;
