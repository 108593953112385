import { takeEvery, ForkEffect, PutEffect, put } from 'redux-saga/effects';

import { PROJECTS_SET_FILTERS, setFilters } from './actions';

import { SagaCreator } from '../../../settings';

const createSaga: SagaCreator = ({ setUrlFilters }) => {
  function* setFiltersSaga({
    payload,
  }: ReturnType<typeof setFilters>): Generator<PutEffect> {
    try {
      yield put(setUrlFilters(payload));
    } catch (e) {
      throw new Error(e as string);
    }
  }

  function* projectsSaga(): Generator<ForkEffect, void, void> {
    yield takeEvery(PROJECTS_SET_FILTERS, setFiltersSaga);
  }
  return projectsSaga;
};

export default createSaga;
