import React from 'react';
import { Button, Input, notification, Row, Tooltip } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SaveOutlined, UndoOutlined } from '../../../../kit/icons';
import createDailyReportMutation from '../../graphql/createDailyReportMutation.graphql';
import updateDailyReportMutation from '../../graphql/updateDailyReportMutation.graphql';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { debounce } from 'lodash';

const { TextArea } = Input;
interface controlDailysReports {
  id?: string;
  yesterday?: string;
  today?: string;
  blockers?: string;
}
export interface Props {
  visible?: () => void;
  onSubmit?: () => void;
  data?: controlDailysReports;
  userId?: string;
}

const dailySchema = Yup.object().shape({
  yesterday: Yup.string().required('Yesterday is required'),
  today: Yup.string().required('Today is required'),
});

const DailyForm: React.FC<Props> = ({ data, visible, userId, onSubmit }) => {
  const [createDaily, { error: errorCreate }] = useMutation(
    createDailyReportMutation
  );
  const [updateDaily, { error: errorUpdate }] = useMutation(
    updateDailyReportMutation
  );
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const handleSubmit = async (values: controlDailysReports) => {
    setDisableSubmitButton(true);
    if (data?.id) {
      await updateDaily({
        variables: {
          ...values,
        },
      });
      if (!errorUpdate) success(true);
      else error(true);
      if (visible) visible();
    } else {
      await createDaily({
        variables: {
          userId,
          yesterday: values.yesterday,
          today: values.today,
          blockers: values.blockers,
        },
      });
      if (!errorCreate) {
        success(false);
        onSubmit?.();
      } else {
        error(false);
      }
    }
  };
  const debouncedHandleSubmit = debounce(handleSubmit, 500);

  const success = (isUpdated: boolean) => {
    if (isUpdated) {
      notification.success({
        message: 'Success',
        description: 'Daily report has been updated',
      });
    } else {
      notification.success({
        message: 'Success',
        description: 'Daily report has been created',
      });
    }
  };

  const error = (isUpdatedError: boolean) => {
    if (isUpdatedError) {
      notification.error({
        message: 'Error',
        description: 'Daily report has not been updated',
      });
    } else {
      notification.error({
        message: 'Error',
        description: 'Daily report has not been created',
      });
    }
  };

  return (
    <Formik
      initialValues={{
        id: data?.id || '',
        yesterday: data ? data.yesterday : '',
        today: data ? data.today : '',
        blockers: data ? data.blockers : '',
      }}
      validationSchema={dailySchema}
      onSubmit={debouncedHandleSubmit}
    >
      {({ handleSubmit, values, handleChange, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <div style={{ paddingBottom: '5px' }}>
            <TextArea
              name="yesterday"
              placeholder="What did you work on yesterday?"
              value={values.yesterday}
              onChange={handleChange}
            />
            {errors.yesterday && touched.yesterday ? (
              <div style={{ color: 'red' }}>{errors.yesterday}</div>
            ) : null}
          </div>

          <div style={{ paddingBottom: '5px' }}>
            <TextArea
              name="today"
              placeholder="What are you going to work on today?"
              value={values.today}
              onChange={handleChange}
            />
            {errors.today && touched.today ? (
              <div style={{ color: 'red' }}>{errors.today}</div>
            ) : null}
          </div>

          <div style={{ paddingBottom: '5px' }}>
            <TextArea
              name="blockers"
              placeholder="Do you have any blockers? if not is the case let it blank."
              value={values.blockers}
              onChange={handleChange}
            />
          </div>

          {visible ? (
            <Row
              style={{ marginTop: '1rem', fontSize: '1.2rem' }}
              justify={'end'}
            >
              <Tooltip title="Save" color={'#25b6ea'}>
                <SaveOutlined
                  style={{
                    marginRight: '1rem',
                    color: '#25b6ea',
                  }}
                  onClick={() => handleSubmit()}
                />
              </Tooltip>
              <Tooltip title="Cancel" color={'#e73125'}>
                <UndoOutlined style={{ color: '#e73125' }} onClick={visible} />
              </Tooltip>
            </Row>
          ) : (
            <div style={{ paddingBottom: '5px' }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disableSubmitButton}
              >
                Submit
              </Button>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default DailyForm;
