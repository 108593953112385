import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

export const PROFILE_SET_FILTERS = 'PROFILE/SET_FILTERS';

export const listProfiles = createRoutine('PROFILE/LIST');
export const filterProfileList = createAction('PROFILE/LIST/FILTER');
export const loadProfile = createRoutine('PROFILE/LOAD');
export const loadCurrentUserProfile = createRoutine('PROFILE/LOAD_CURRENT');
export const listThisMonthsBirthdays = createRoutine('PROFILE/LIST_BIRTHDAYS');
export const setFilters = createAction('PROFILE/SET_FILTERS');
export const loadPeople = createRoutine('PROFILE/LOAD_PEOPLE');
export const loadReviewsByProfile = createRoutine('PROFILE/LOAD_REVIEWS');
export const loadSkills = createRoutine('PROFILE/LOAD_SKILLS');
export const loadReviewsGivenByProfile = createRoutine(
  'PROFILE/LOAD_REVIEWS_GIVEN'
);
export const loadTalksByProfile = createRoutine('PROFILE/LOAD_TALKS');
export const createReview = createRoutine('PROFILE/CREATE_REVIEW');
export const updateProfile = createRoutine('PROFILE/UPDATE_PROFILE');

export type ProfileActionTypes =
  | ReturnType<typeof listProfiles>
  | ReturnType<typeof loadProfile>
  | ReturnType<typeof loadCurrentUserProfile>
  | ReturnType<typeof listThisMonthsBirthdays>
  | ReturnType<typeof setFilters>
  | ReturnType<typeof loadPeople>
  | ReturnType<typeof createReview>
  | ReturnType<typeof loadReviewsGivenByProfile>
  | ReturnType<typeof loadTalksByProfile>
  | ReturnType<typeof loadSkills>
  | ReturnType<typeof updateProfile>;
