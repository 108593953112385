export type ModuleSettingOptions = { [key: string]: unknown };
type ModuleSetting = string | [string, ModuleSettingOptions];

const modules: ModuleSetting[] = [
  'melt-connect-daily-standup',
  'melt-connect-talks',
  'melt-connect-people',
  'melt-connect-reviews',
  'melt-connect-projects',
  'melt-connect-vacation-request',
  'melt-connect-medical',
  [
    'melt-connect-handbook',
    {
      defaultHandbookTopicId: 'general',
    },
  ],
];

export default {
  modules,
};
