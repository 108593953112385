import { useQuery, ApolloError } from '@apollo/client';
import moment from 'moment';
import getReportsForUserInDateRangeQuery from '../graphql/getReportsForUserInDateRangeQuery.graphql';
import { MeltDaily } from '../types';

interface Report {
  ownDaily: MeltDaily | null;
  loading: boolean;
  error: ApolloError | null;
  refetch: () => void;
}

const useOwnDaily = (id: string | undefined): Report => {
  const startOfDay = moment().startOf('day');
  const endOfDay = moment().endOf('day');
  const {
    data,
    loading,
    error,
    refetch: refetchOwnDail,
  } = useQuery(getReportsForUserInDateRangeQuery, {
    variables: {
      userId: id,
      startOfDay,
      endOfDay,
    },
  });

  const refetch = () => {
    refetchOwnDail();
  };

  if (loading) {
    return {
      ownDaily: null,
      loading,
      error: null,
      refetch,
    };
  }

  if (error) {
    return {
      ownDaily: null,
      loading,
      error,
      refetch,
    };
  }

  if (!data) {
    return {
      ownDaily: null,
      loading,
      error: null,
      refetch,
    };
  }

  return {
    ownDaily: data.dailyReports[0],
    loading,
    error: null,
    refetch,
  };
};

export default useOwnDaily;
