import React from 'react';
import { Field } from 'redux-form';

export interface Props {
  name: string;
}

const HiddenField: React.FC<Props> = (props) => (
  <Field type="hidden" component="input" disabled {...props} />
);

export default HiddenField;
