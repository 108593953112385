import React, { useState, useMemo } from 'react';
import createVacationRequestQuery from '../../graphql/createVacationRequestQuery.graphql';
import { Input, Row, Modal, DatePicker, Select } from 'antd';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import {
  calcWorkWeekBetweenDates,
  holidaysInRange,
  formatLocaleDate,
} from '../../../../utils/dateUtils';
import { useMutation } from '@apollo/client';
import useManagers from '../../hooks/use_managers';
import { Profile } from '../../../melt-connect-people/types';
import { disableDateCondition } from '../../utils/disableDateCondition';
import { getManagerOptions } from './optionsManager';

const { TextArea } = Input;

type DateValue = Moment | null;

type DateStateProps = {
  startDate: DateValue;
  finishDate: DateValue;
  requestedDays: number | null;
};

interface Props {
  showModal: boolean;
  closeModal: () => void;
  profile: Profile;
  onSubmit: () => void;
}

interface InitialValues {
  startDate: DateValue;
  finishDate: DateValue;
  reason: string;
  managerId: string;
  personId: number;
}

const initialDateState: DateStateProps = {
  startDate: null,
  finishDate: null,
  requestedDays: null,
};

const formSchema = Yup.object().shape({
  startDate: Yup.string().nullable().required('Dates are required'),
  finishDate: Yup.string().nullable().required('Dates are required'),
  managerId: Yup.string().nullable().required('Manager is required'),
});

export const VacationFormModal: React.VFC<Props> = ({
  showModal,
  closeModal,
  profile,
  onSubmit,
}) => {
  const [dateState, setDateState] = useState<DateStateProps>(initialDateState);
  const [createVacationRequest] = useMutation(createVacationRequestQuery);
  const { managers, loading: loadingManagers } = useManagers();

  const dateRangeHelper: React.ReactElement = useMemo(() => {
    if (
      !dateState.startDate ||
      !dateState.finishDate ||
      !dateState.requestedDays
    ) {
      return <span></span>;
    }

    // Holidays in range
    const holidays: Moment[] = holidaysInRange(
      dateState.startDate,
      dateState.finishDate,
      profile.country
    );

    return (
      <h4>
        <b>
          {dateState.requestedDays}{' '}
          {dateState.requestedDays > 1 ? 'days' : 'day'}
        </b>
        <br />
        Your vacation starts <u>{formatLocaleDate(dateState.startDate)}</u> and
        ends <u>{formatLocaleDate(dateState.finishDate)}</u>
        <br />
        <p>
          National holidays not included in vacation:{' '}
          <u>
            {holidays.length == 0
              ? 'No holidays'
              : holidays.map(formatLocaleDate).join(', ')}
          </u>
        </p>
      </h4>
    );
  }, [dateState, profile]);

  const initialValues: InitialValues = {
    startDate: null,
    finishDate: null,
    reason: '',
    managerId: '',
    personId: Number(profile.id),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={async (values) => {
        await createVacationRequest({
          variables: {
            ...values,
          },
        });
        values = initialValues;
        onSubmit();
        closeModal();
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <Row>
          <Form onSubmit={handleSubmit}>
            <Modal
              title="Request Vacation"
              visible={showModal}
              onCancel={() => closeModal()}
              onOk={() => handleSubmit()}
            >
              <div>
                <label> Date: </label>
                <DatePicker.RangePicker
                  onChange={(_, dateString) => {
                    handleChange({
                      target: {
                        name: 'startDate',
                        value: dateString[0],
                      },
                    });
                    handleChange({
                      target: {
                        name: 'finishDate',
                        value: dateString[1],
                      },
                    });
                    setDateState({
                      startDate: moment(dateString[0]),
                      finishDate: moment(dateString[1]),
                      requestedDays: calcWorkWeekBetweenDates(
                        moment(dateString[0]),
                        moment(dateString[1]),
                        profile.country
                      ),
                    });
                  }}
                  style={{
                    width: '100%',
                    marginBottom: '1rem',
                    marginTop: '4px',
                  }}
                  disabledDate={disableDateCondition}
                />
                {errors.startDate && touched.startDate ? (
                  <div style={{ color: 'red', marginBottom: '1rem' }}>
                    {errors.startDate}
                  </div>
                ) : null}
              </div>

              <div>
                <label> Manager: </label>
                <Select
                  defaultActiveFirstOption={true}
                  value={values.managerId}
                  onChange={(manager) => {
                    handleChange({
                      target: {
                        name: 'managerId',
                        value: manager,
                      },
                    });
                  }}
                  loading={loadingManagers}
                  disabled={loadingManagers}
                  style={{ width: '100%', marginBottom: '1rem' }}
                >
                  {getManagerOptions(managers)}
                </Select>
                {errors.managerId && touched.managerId ? (
                  <div style={{ color: 'red', marginBottom: '1rem' }}>
                    {errors.managerId}
                  </div>
                ) : null}
              </div>

              <div>
                <label> Reason: </label>
                <TextArea
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                />
              </div>

              <div style={{ marginTop: '1rem' }}>{dateRangeHelper}</div>
            </Modal>
          </Form>
        </Row>
      )}
    </Formik>
  );
};
