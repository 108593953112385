import React from 'react';
import { getConfig } from '../config';

export const config = getConfig();

const AuthScreen: React.FC = () => {
  React.useEffect(() => {
    window.location.href = `${config.strapi.uri}/connect/auth0`;
  }, []);

  return (
    <h1>
      You will be automatically redirected. If all else fails,
      <a href={`${config.strapi.uri}/connect/auth0`}> click here.</a>
    </h1>
  );
};

export default AuthScreen;
