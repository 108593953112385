import { useSelector, shallowEqual } from 'react-redux';
import { Profile } from '../../../melt-connect-people/types';
import { BaseAppState } from '../../../../redux';

interface DailyMissingSelectorInterface {
  missingDailies: Profile[];
  loading: boolean;
  error: string | null;
}

const dailyMissingSelector = (
  state: BaseAppState
): DailyMissingSelectorInterface => ({
  missingDailies: state.daily.missing.items,
  loading: state.daily.missing.loading,
  error: state.daily.missing.error,
});

const useDailyMissingSelector = (): DailyMissingSelectorInterface =>
  useSelector(dailyMissingSelector, shallowEqual);

export default useDailyMissingSelector;
