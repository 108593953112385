import React from 'react';
import { Row, Col, Typography, Tooltip } from 'antd';
import ReactMarkdown from 'react-markdown';
import { EditOutlined } from '../../../../kit/icons';
import DailyForm from '../../forms/daily/form';
import { MeltDaily } from '../../types';

const { Text } = Typography;

interface OwnDailyProps {
  ownDaily: MeltDaily | null;
  isEditable?: boolean;
}

const DailyComponent: React.FC<OwnDailyProps> = ({
  ownDaily,
  isEditable = true,
}) => {
  const [editDaily, setEditDaily] = React.useState(false);

  function handleEdit() {
    setEditDaily(!editDaily);
  }

  if (!ownDaily) return null;
  return editDaily ? (
    <>
      <DailyForm visible={handleEdit} data={ownDaily} />
    </>
  ) : (
    <>
      <Row>
        <Col>
          <Text type="secondary">Yesterday: </Text>
          <ReactMarkdown source={ownDaily.yesterday} />
          <Text type="secondary">Today: </Text>
          <ReactMarkdown source={ownDaily.today} />
          <Text type="secondary">Blockers:</Text>
          <ReactMarkdown source={ownDaily.blockers} />
        </Col>
      </Row>
      <Row
        style={{ marginRight: '2rem', color: '#ffcc00', marginTop: '2rem' }}
        justify={'end'}
      >
        {isEditable && (
          <Tooltip title="Edit Daily" color={'#ffcc00e6'}>
            <EditOutlined onClick={handleEdit} />
          </Tooltip>
        )}
      </Row>
    </>
  );
};

export default DailyComponent;
