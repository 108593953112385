import React from 'react';
import moment from 'moment';

const PolyglotBar: React.FC<{}> = () => {
  if (moment().isoWeekday() !== 5 /* FRIDAY */) {
    return null;
  }
  return (
    <div
      style={{
        backgroundColor: '#FFCC00',
        padding: '12px',
        color: 'black',
        textAlign: 'center',
      }}
    >
      🌍 Today is Polyglot Friday! 🌍
    </div>
  );
};

export default PolyglotBar;
