import React, { useMemo } from 'react';
import DailyForm from '../../forms/daily/form';
import { NavLink } from 'react-router-dom';
import { Layout, Typography, Spin } from 'antd';
import OwnDailyView from '../../components/own_daily';
import { ArrowRightOutlined } from '../../../../kit/icons';
import useCurrentprofile from '../../../melt-connect-people/hooks/use_current_profile';
import useOwnDaily from '../../hooks/use_own_daily';

const { Content } = Layout;
const { Text } = Typography;

const DailyStandup: React.FC = () => {
  const {
    userProfile: profile,
    error,
    loading: LoadingProfile,
  } = useCurrentprofile();
  const { ownDaily, loading, refetch } = useOwnDaily(profile?.id);

  const canReportDaily = useMemo(
    () => profile !== null && profile !== undefined && profile.sendsDailyReport,
    [ownDaily]
  );

  if (error) {
    return <Text>{error.message}</Text>;
  }

  if (LoadingProfile) {
    return <Spin />;
  }
  if (loading) return <Spin />;

  let dailyView = null;

  if (ownDaily === null || typeof ownDaily === 'undefined') {
    dailyView = (
      <div>
        <Text strong>Ready for your daily report?</Text>
        <DailyForm userId={profile?.id} onSubmit={refetch} />
      </div>
    );
  }
  return (
    <Content>
      {canReportDaily ? (
        dailyView ? (
          dailyView
        ) : (
          <OwnDailyView ownDaily={ownDaily} />
        )
      ) : null}
      <br />
      <NavLink to={`/daily`}>
        Go to daily page <ArrowRightOutlined />
      </NavLink>
    </Content>
  );
};

export default DailyStandup;
