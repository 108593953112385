import React, { useEffect, useMemo, useState } from 'react';
import { Col, Card, Typography, Spin } from 'antd';
import { Profile } from '../../../../melt-connect-reviews/types';
import moment from 'moment';
import {
  calcWorkWeekBetweenDates,
  formatLocaleDate,
} from '../../../../../utils/dateUtils';
import { VacationRequest, VacationRequestStatuses } from '../../../types';
import COLOMBIA from '../../../../../utils/countries';
import { DISCLAIMER_MESSAGE } from '../../../../../utils/constans';

const { Title } = Typography;

interface OverviewData {
  profile: Profile;
  vacationRequests: VacationRequest[];
  loading: boolean;
}

export const Overview: React.VFC<OverviewData> = ({
  profile,
  vacationRequests,
  loading,
}) => {
  const [approvedVacationsDays, setApprovedVacationsDays] = useState<number>(0);
  const [vacationsDays, setVacationsDays] = useState<number>(0);
  const now = moment();
  // 1.25 total vacation for moth / 30 days month
  const VACATIONS_FORMULA = 1.25;
  useEffect(() => {
    const months = now.diff(moment(profile.entryDate), 'months');

    setVacationsDays(VACATIONS_FORMULA * months);
  }, []);

  useEffect(() => {
    // Get approved vacation
    const approvedVacations = vacationRequests.filter(
      (request) => request.state == VacationRequestStatuses.APPROVED
    );
    approvedVacations.map((data) => {
      const approvedDays = calcWorkWeekBetweenDates(
        moment(data.startDate),
        moment(data.finishDate),
        profile?.country || COLOMBIA
      );

      setApprovedVacationsDays(approvedDays);
    });
  }, [vacationRequests]);

  const lastVacationHelper: React.ReactElement = useMemo(() => {
    if (loading) return <Spin />;

    // Get last vacation
    const lastVacation = vacationRequests
      .filter((request) => request.state == VacationRequestStatuses.APPROVED)
      .slice(-1)[0];

    // If no vacationRequest approved
    if (!lastVacation) {
      return <Title level={4}>You have not scheduled a vacation yet</Title>;
    }

    const startDate = moment(lastVacation.startDate);
    const finishDate = moment(lastVacation.finishDate);

    // Find out if it's current, scheduled or past
    let helperText: React.ReactElement;

    if (now.isAfter(finishDate)) {
      helperText = (
        <span>
          Your last vacation was from <u>{formatLocaleDate(startDate)}</u> to{' '}
          <u>{formatLocaleDate(finishDate)}</u>
        </span>
      );
    } else if (now.isBetween(startDate, finishDate)) {
      helperText = (
        <span>
          Your vacation started <u>{formatLocaleDate(startDate)}</u> and ends{' '}
          <u>{formatLocaleDate(finishDate)}</u>
        </span>
      );
    } else {
      helperText = (
        <span>
          Your vacation starts <u>{formatLocaleDate(startDate)}</u> and ends{' '}
          <u>{formatLocaleDate(finishDate)}</u>
        </span>
      );
    }

    return <Title level={4}>{helperText}</Title>;
  }, [vacationRequests, loading]);

  return (
    <React.Fragment>
      {/* Vacation Days */}
      <Col sm={24} lg={12}>
        <Card style={{ height: '100%' }}>
          <Title level={4}>
            You have {(vacationsDays - approvedVacationsDays).toFixed(2) || 0}{' '}
            available vacation days!
          </Title>
        </Card>
        <div style={{ width: '100%' }}>
          Note:{' '}
          <span style={{ fontWeight: 'bold', color: 'yellow' }}>
            {`${DISCLAIMER_MESSAGE}`}
          </span>
        </div>
      </Col>

      {/* lastVacationHelper */}
      <Col sm={24} lg={12}>
        <Card style={{ height: '100%' }}>{lastVacationHelper}</Card>
      </Col>
    </React.Fragment>
  );
};
