import React, { useEffect, useMemo, useState } from 'react';
import { Col, Select, Space, Table, Tooltip } from 'antd';
import { VacationRequest } from '../../types';
import { formatLocaleDate } from '../../../../utils/dateUtils';
import moment, { Moment } from 'moment';
import { FormManager } from './formManager';
import { Typography } from 'antd';
import { calculateRequestedTotalDays } from '../../utils/calculateRequestedTotalDays';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '../../../../kit/icons';
import useVacationRequestManager from '../../hooks/use_vacation_resquest_manager';
const { Text } = Typography;
interface VacationRequestRow extends VacationRequest {
  key: number;
}

const parseToLocaleDate = (date: string): string =>
  formatLocaleDate(moment(date));

const filters: string[] = ['Pending', 'Approved', 'Declined'];
const { Option } = Select;
type DateValue = Moment | null;

interface CurrentRequest {
  id: string;
  state: string;
  startDate: DateValue;
  finishDate: DateValue;
}

interface TableManagerProps {
  id: string;
}

export const TableManager: React.VFC<TableManagerProps> = ({ id }) => {
  const { vacationRequests, loading, refetch } = useVacationRequestManager(id);
  const [tableData, setTableData] = useState<VacationRequestRow[]>([]);
  const [current, setCurrent] = useState<CurrentRequest>();
  const [filter, setFilter] = useState<string>('Pending');
  const [showModalRequestVacation, setShowModalRequestVacation] =
    useState<boolean>(false);

  const openModal = () => setShowModalRequestVacation(true);
  const closeModal = () => setShowModalRequestVacation(false);
  const handleClick = (
    state: string,
    id: string,
    startDate: DateValue,
    finishDate: DateValue
  ) => {
    setCurrent({ id, state, startDate, finishDate });
    openModal();
  };
  const tableColumns = useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'id',
        sorter: (
          firstRow: VacationRequestRow,
          nextRow: VacationRequestRow
        ): number => firstRow.id - nextRow.id,
        defaultSortOrder: 'descend' as const,
      },
      {
        title: 'Person',
        dataIndex: 'person',
        render: (field: VacationRequestRow['person']) => (
          <Text>
            {field.firstName} {field.lastName}
          </Text>
        ),
      },
      {
        title: 'From',
        dataIndex: 'startDate',
        render: (startDate: VacationRequestRow['startDate']) => (
          <Text>{parseToLocaleDate(startDate)}</Text>
        ),
      },
      {
        title: 'To',
        dataIndex: 'finishDate',
        render: (finishDate: VacationRequestRow['finishDate']) => (
          <Text>{parseToLocaleDate(finishDate)}</Text>
        ),
      },
      {
        title: 'Total Days',
        dataIndex: 'id',
        render: (_id: VacationRequestRow['id'], row: VacationRequest) => (
          <Text>
            {calculateRequestedTotalDays(
              moment(row.startDate),
              moment(row.finishDate),
              row.person?.country || 'Colombia'
            )}
          </Text>
        ),
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        render: (reason: VacationRequestRow['reason']) => (
          <Text style={{ textTransform: 'capitalize' }}>{reason}</Text>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id: string, row: VacationRequest) => (
          <Space size="middle">
            <Tooltip title="Approve" color={'#26b9cb'}>
              <CheckCircleOutlined
                onClick={() =>
                  handleClick(
                    'Approved',
                    id,
                    moment(row.startDate),
                    moment(row.finishDate)
                  )
                }
                style={{ fontSize: '1.5rem', color: '#26b9cb' }}
              />
            </Tooltip>
            <Tooltip title="Approve" color={'#E73125'}>
              <CloseCircleOutlined
                onClick={() =>
                  handleClick(
                    'Declined',
                    id,
                    moment(row.startDate),
                    moment(row.finishDate)
                  )
                }
                style={{ fontSize: '1.5rem', color: '#E73125' }}
              />
            </Tooltip>
          </Space>
        ),
      },
    ],
    [vacationRequests]
  );

  const tableColumnsShort = useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'id',
        sorter: (
          firstRow: VacationRequestRow,
          nextRow: VacationRequestRow
        ): number => firstRow.id - nextRow.id,
        defaultSortOrder: 'descend' as const,
      },
      {
        title: 'Person',
        dataIndex: 'person',
        render: (field: VacationRequestRow['person']) => (
          <Text>
            {field.firstName} {field.lastName}
          </Text>
        ),
      },
      {
        title: 'From',
        dataIndex: 'startDate',
        render: (startDate: VacationRequestRow['startDate']) => (
          <Text>{parseToLocaleDate(startDate)}</Text>
        ),
      },
      {
        title: 'To',
        dataIndex: 'finishDate',
        render: (finishDate: VacationRequestRow['finishDate']) => (
          <Text>{parseToLocaleDate(finishDate)}</Text>
        ),
      },
      {
        title: 'Total Days',
        dataIndex: 'id',
        render: (_id: VacationRequestRow['id'], row: VacationRequest) => (
          <Text>
            {calculateRequestedTotalDays(
              moment(row.startDate),
              moment(row.finishDate),
              row.person?.country || 'Colombia'
            )}
          </Text>
        ),
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        render: (reason: VacationRequestRow['reason']) => (
          <Text style={{ textTransform: 'capitalize' }}>{reason}</Text>
        ),
      },
      {
        title: 'Feedback',
        dataIndex: 'feedback',
        render: (feedback: VacationRequestRow['feedback']) => (
          <Text style={{ textTransform: 'capitalize' }}>{feedback}</Text>
        ),
      },
    ],
    [vacationRequests]
  );

  useEffect(() => {
    if (vacationRequests?.length > 0) {
      const data: VacationRequestRow[] = [];
      vacationRequests.forEach((props) => {
        if (props.state === filter) {
          data.push({
            ...props,
            key: props.id,
          });
        }
      });
      setTableData(data);
    }
  }, [vacationRequests, filter]);

  return (
    <Col span={24}>
      <h2>Requests Manager</h2>

      <Select
        defaultValue={filter}
        style={{ width: 120, marginBottom: '1rem' }}
        onChange={(value) => setFilter(value)}
      >
        {filters.map((filter) => (
          <Option value={filter} key={filter}>
            {filter}
          </Option>
        ))}
      </Select>
      <Table
        columns={filter === 'Pending' ? tableColumns : tableColumnsShort}
        dataSource={tableData}
        pagination={false}
        loading={loading}
      />
      <FormManager
        onSumitForm={refetch}
        showModal={showModalRequestVacation}
        closeModal={closeModal}
        id={current?.id}
        state={current?.state}
        startDate={current?.startDate}
        finishDate={current?.finishDate}
      />
    </Col>
  );
};
