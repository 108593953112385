import React from 'react';
import { Layout } from 'antd';
import ProjectComponent from '../components/project/index';

const ProjectScreen: React.FC<{}> = () => {
  return (
    <Layout style={{ margin: '20px 0' }}>
      <ProjectComponent />
    </Layout>
  );
};

export default ProjectScreen;
