import React from 'react';
import { Col, Row, Space, Typography, Spin, Empty } from 'antd';
import { ProjectDailyInterface } from '../../../types';
import DailyCard from './daily_card';

const { Title } = Typography;

interface Props {
  loading: boolean;
  dailies: ProjectDailyInterface[] | null;
  title: string;
}

const ProjectDailies: React.FC<Props> = ({ loading, dailies, title }) => {
  //TODO: Search dailies by date filter
  // const [dateFilter, setDateFilter] = useState<moment.Moment | null>(() =>
  //   moment()
  // );

  // const onChangeDate = (date: moment.Moment | null) => {
  //   setDateFilter(date);
  // };

  return !loading ? (
    <Row justify={'start'} className="Project-dailies">
      <Col span={23}>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <Title
            level={5}
            style={{
              borderBottom: '1px solid #cccccc1f',
              paddingBottom: '0.5rem',
            }}
          >
            {title}
          </Title>
          {/*
          TODO: Search dailies by date filter
          <Row
            gutter={[
              { xs: 10, xxl: 10 },
              { xs: 15, xxl: 15 },
            ]}
            style={{ marginLeft: '4px' }}
            align="middle"
          >
            <Text type="secondary" style={{ marginRight: '1rem' }}>
              Search by date
            </Text>
            <DatePicker
              onChange={onChangeDate}
              defaultValue={dateFilter ?? undefined}
            />
          </Row> */}
          {/* <Divider /> */}
          <Row
            gutter={[
              { xs: 10, xxl: 10 },
              { xs: 15, xxl: 15 },
            ]}
            style={{ marginLeft: '4px' }}
          >
            <Col span={24}>
              {dailies && dailies.length > 0 ? (
                dailies?.map((daily, index) => (
                  <DailyCard daily={daily} key={index} />
                ))
              ) : (
                <Empty description="No daily reports found yet" />
              )}
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  ) : (
    <Row justify="center">
      <Col>
        <Spin />
      </Col>
    </Row>
  );
};

export default ProjectDailies;
