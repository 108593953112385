import React, { ReactElement, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Layout, Dropdown } from 'antd';
import { DownOutlined } from '../../kit/icons';
import logo from '../../../assets/nav_logo.png';
import { ShowInMenu } from '../../enums';
import { withSettings, AppSettings } from '../../settings';

const { Header } = Layout;

import { useDispatch } from 'react-redux';
import { loadUser, logOutUser } from '../../redux/user/actions';
import useAuthSelector from '../../hooks/store/use_auth_selector';

type NavbarProps = { settings: AppSettings };

const Navbar: React.FC<NavbarProps> = ({ settings }) => {
  const dispatch = useDispatch();
  const user = useAuthSelector();

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  const handleLogOut = () => {
    dispatch(logOutUser());
  };

  const getItemForVisibility = (visibility: ShowInMenu): ReactElement[] => {
    const { routes } = settings;
    return routes
      .filter((item) => item.menu && item.menu.visibility === visibility)
      .map((item) => (
        <Menu.Item key={item.path}>
          <NavLink activeClassName="active" to={item.menu.link || item.path}>
            {item.menu.name}
          </NavLink>
        </Menu.Item>
      ));
  };

  const getAuthItem = (): ReactElement => {
    const accountMenuItems = [
      ...getItemForVisibility(ShowInMenu.ACCOUNT),
      <Menu.Item key="redirecttoprofile">
        {/* Link to melt-connect-people RedirectToProfile component
        since we can't access profile.id in this component */}
        <NavLink
          activeClassName="active"
          to={user ? '/redirect/profile/' : '/'}
        >
          My Profile
        </NavLink>
      </Menu.Item>,
      <Menu.Item key="logout">
        <a data-test="log-out-button" onClick={handleLogOut}>
          Log out
        </a>
      </Menu.Item>,
    ];

    const accountMenu = <Menu>{accountMenuItems}</Menu>;
    const dropDown = (name: string): React.ReactChild => (
      <Dropdown overlay={accountMenu}>
        <a
          className="ant-dropdown-link"
          data-test="account-dropdown-menu"
          onClick={(e): void => e.preventDefault()}
        >
          {name} <DownOutlined />
        </a>
      </Dropdown>
    );

    const login = (
      <NavLink activeClassName="active" to="/login">
        Log in
      </NavLink>
    );

    const email = user?.attributes.email || '';

    return (
      <Menu.Item
        key="3"
        style={{ backgroundColor: 'transparent', float: 'right' }}
      >
        {user ? dropDown(email) : login}
      </Menu.Item>
    );
  };

  const getMenuItems = (): ReactElement[] => {
    return [
      ...getItemForVisibility(user ? ShowInMenu.AUTHD : ShowInMenu.UNAUTHD),
      ...getItemForVisibility(ShowInMenu.ALWAYS),
      getAuthItem(),
    ];
  };

  return (
    <Header
      className="header"
      style={{ position: 'fixed', zIndex: 3, width: '100%' }}
    >
      <NavLink to="/">
        <div
          className="logo"
          style={{
            width: '120px',
            height: '31px',
            background: `url("${logo}") no-repeat center`,
            backgroundSize: 'contain',
            margin: '16px 0 16px 0',
            float: 'left',
          }}
        />
      </NavLink>
      <Menu
        theme="dark"
        mode="horizontal"
        style={{ lineHeight: '64px', justifyContent: 'end' }}
      >
        {getMenuItems()}
      </Menu>
    </Header>
  );
};

export default withSettings(Navbar);
