import React, { FC } from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { ProjectManager } from '../../../modules/melt-connect-daily-standup/types';

interface Props {
  projectManagers: ProjectManager[];
  onClick: (projectManager: string) => void;
  name?: string;
}
const ProjectManagers: FC<Props> = ({ projectManagers, name, onClick }) => {
  const manager = name ?? 'All';
  const menu = (
    <Menu>
      <Menu.Item onClick={() => onClick('All')} key={'All'}>
        All
      </Menu.Item>
      {projectManagers.map((projectManager: ProjectManager) => {
        return (
          <Menu.Item
            onClick={() => onClick(projectManager.username)}
            key={projectManager.id}
          >
            {projectManager.firstName} {projectManager.lastName}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const selectedManager = projectManagers.find((pm) => pm.username === name);
  const managerName =
    selectedManager?.firstName + ' ' + selectedManager?.lastName;
  const buttonName = manager !== 'All' ? managerName : 'Project Managers';

  return (
    <Dropdown overlay={menu} placement="topLeft">
      <Button>{buttonName}</Button>
    </Dropdown>
  );
};
export default ProjectManagers;
