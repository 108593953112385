import React from 'react';
import { Layout } from 'antd';
import Daily from '../components/daily';

const { Content } = Layout;

const DailyScreen: React.FC<{}> = () => {
  return (
    <Layout>
      <Layout style={{ padding: '0 0 24px 0', minHeight: '600px' }}>
        <Content>
          <Daily />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DailyScreen;
