import React from 'react';
import { Layout } from 'antd';
import SettingsMenu from '../screens/settings_menu';

const { Content, Sider } = Layout;

const SettingsScreen: React.FC = () => {
  return (
    <Layout>
      <Sider
        width={300}
        breakpoint="lg"
        collapsedWidth="0"
        className="site-layout-background"
      >
        <SettingsMenu />
      </Sider>
      <Layout style={{ minHeight: '100vh', padding: '0 0 24px 0' }}>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        ></Content>
      </Layout>
    </Layout>
  );
};

export default SettingsScreen;
