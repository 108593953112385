import { Alert, Button, Col, Row, Space, Spin } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useEffect, useState } from 'react';
import { DEFAULT_AVATAR } from '../../../../kit/images';
import { Profile } from '../../types';
import useSlotMachine from '../../hooks/use_slot_machine';
import ProjectManagers from '../../../../components/common/menu_managers/menuManagers';
import useProjectManagers from '../../../../hooks/use_project_manager';

const SlotMachine: React.VFC = () => {
  const height = 100;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [started, setStarted] = useState(false);
  const [speed, setSpeed] = useState(50);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slowing, setSlowing] = useState(false);
  const [selectedManager, setSelectedManager] = useState('All');

  const { profiles, loading, error } = useSlotMachine();
  const { projectManagers } = useProjectManagers();

  const manager =
    selectedManager !== 'All' &&
    projectManagers.find((pm) => pm.username === selectedManager);

  const projectsToSearch =
    manager && manager.projectsManaged.map((p) => p.name);

  const usersAtProject =
    profiles && projectsToSearch
      ? profiles.filter(
          (person) =>
            projectsToSearch.includes(person.projects[0]?.name) &&
            person.sendsDailyReport
        )
      : [];

  const allUsers = profiles
    ? profiles.filter((person) => person.sendsDailyReport)
    : [];

  const filteredProfiles =
    selectedManager === 'All' || usersAtProject.length === 0
      ? allUsers
      : usersAtProject;

  const handleManagerClick = (projectManager: string) => {
    setSelectedManager(projectManager);
  };

  useEffect(() => {
    setTimeout(() => {
      if (started && currentIndex === selectedIndex) {
        setSlowing(true);
      }
    }, 3000);
  }, [currentIndex, selectedIndex, started]);

  useEffect(() => {
    let inter: ReturnType<typeof setTimeout>;
    if (started && slowing) {
      inter = setTimeout(() => {
        setSpeed((s) => s * 1.2);
      }, speed);
    }
    return () => clearTimeout(inter);
  }, [started, slowing, speed]);

  useEffect(() => {
    let inter: ReturnType<typeof setTimeout>;
    if (started && speed < 1500) {
      setCurrentIndex((i) => (i + 1) % filteredProfiles.length);
      inter = setInterval(() => {
        setCurrentIndex((i) => (i + 1) % filteredProfiles.length);
      }, speed);
    } else if (started && speed > 1700) {
      setStarted(false);
      setSpeed(50);
      setSlowing(false);
    }

    return () => clearInterval(inter);
  }, [started, speed]);

  if (error) {
    return (
      <Alert message={error.name} description={error.message} type="error" />
    );
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }
  const start = () => {
    setSelectedIndex(Math.floor(Math.random() * filteredProfiles.length));
    setStarted(true);
  };

  const calcPosition = (i: number) => {
    let position;
    const length = filteredProfiles.length;
    const diff = i - currentIndex;
    const abs = Math.abs(diff);

    if (abs > length / 2) {
      if (diff > 0) {
        position = (length - diff) * -1;
      } else {
        position = length + diff;
      }
    } else {
      position = diff;
    }
    return position;
  };

  const getAvatar = (profile: Profile) => profile.avatar?.url ?? DEFAULT_AVATAR;

  const renderItems = () => {
    return filteredProfiles.map((p, i: number) => {
      const position = calcPosition(i);
      if (Math.abs(position) < 3) {
        return (
          <div
            key={p.email}
            className="item"
            style={{
              height: height,
              transform: `translate(0px, ${position * height}px)`,
              transition: `all ${speed * 1.2}ms linear`,
            }}
          >
            <Avatar src={getAvatar(p)}></Avatar>
            <h1 className="item-text">{p.firstName}</h1>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="slots-container">
      <Space size={'large'}>
        <Row gutter={[12, 24]} align="middle" justify="center">
          <Col>
            <div
              className="slot"
              style={{
                height: height + 24,
              }}
            >
              <div className="inner-container" style={{ height: height + 10 }}>
                <div className="items-container" style={{ height: height }}>
                  {renderItems()}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <ProjectManagers
              projectManagers={projectManagers}
              onClick={handleManagerClick}
              name={selectedManager}
            />
            <Button size="middle" loading={started} onClick={start}>
              Press
            </Button>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default SlotMachine;
