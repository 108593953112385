import React from 'react';
import Calendar from '../components/calendar';
import { Layout } from 'antd';

const { Content } = Layout;

const CalendarScreen: React.FC<{}> = () => {
  return (
    <Content style={{ padding: 24 }}>
      <Calendar />
    </Content>
  );
};

export default CalendarScreen;
