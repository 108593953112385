import { ReactChild, ReactChildren } from 'react';
import { createPortal } from 'react-dom';

interface AuxProps {
  children: ReactChild | ReactChildren;
  close: Function;
  url: string;
}

const NewWindow = ({ children, close, url }: AuxProps): JSX.Element => {
  const widowWidth = window.screen.availWidth;
  const widowHeight = window.screen.availHeight;
  const newWindow = window.open(
    url,
    'newWin',
    `width=${widowWidth},height=${widowHeight}`
  );
  if (newWindow) {
    newWindow.onunload = () => {
      close();
    };
    return createPortal(children, newWindow.document.body);
  }
  return createPortal(children, window.document.body);
};

export default NewWindow;
