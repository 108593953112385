import { Routine } from 'redux-saga-routines';

import HomeScreen from '../screens/home';
import AuthScreen from '../screens/auth';
import AuthCallback from '../screens/auth_callback';
import SettingsScreen from '../screens/settings';
import { ShowInMenu } from '../enums';

import { RouteSpec } from '../types';
import { loadCurrentUserProfile } from '../modules/melt-connect-people/store/actions';

interface CreateRoutesArgs {
  homeActions: Routine[];
}

const createBaseRoutes = ({ homeActions }: CreateRoutesArgs): RouteSpec[] => {
  return [
    {
      exact: true,
      path: '/',
      component: HomeScreen,
      menu: { visibility: ShowInMenu.NEVER },
      data: {
        authRequired: true,
        actions: homeActions,
      },
    },
    {
      exact: true,
      path: '/login',
      component: AuthScreen,
      menu: { visibility: ShowInMenu.NEVER },
    },
    {
      exact: true,
      path: '/auth0',
      component: AuthCallback,
      menu: { visibility: ShowInMenu.NEVER },
    },
    {
      exact: true,
      path: '/settings',
      component: SettingsScreen,
      menu: { visibility: ShowInMenu.NEVER, name: 'Settings' },
      data: {
        actions: [loadCurrentUserProfile],
      },
    },
  ];
};

export default createBaseRoutes;
