import { useEffect } from 'react';
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import getLatestLeaderBoardQuery from '../graphql/getLatestLeaderBoardQuery.graphql';
import getPeopleByIdsQuery from '../graphql/getPeopleByIdsQuery.graphql';
import {
  buildLeaderBoard,
  CoinGrouping,
} from '../../../utils/buildLeaderBoard';
import moment from 'moment';
import { Profile } from '../types';

interface HallOfFame {
  data: Profile[] | null;
  loading: boolean;
  error: ApolloError | null;
}

const useHallOfFame = (month: number, year: number): HallOfFame => {
  const from = moment([year, month]).utc().startOf('month').toISOString();
  const to = moment([year, month]).utc().endOf('month').toISOString();

  const {
    data: coinsData,
    loading: coinsLoading,
    error: coinsError,
  } = useQuery(getLatestLeaderBoardQuery, {
    variables: {
      from,
      to,
    },
  });

  const [
    getPeople,
    { loading: peopleLoading, error: peopleError, data: peopleData },
  ] = useLazyQuery(getPeopleByIdsQuery);

  useEffect(() => {
    if (coinsData == null) {
      return;
    }
    const {
      currencyTransactionsConnection: {
        groupBy: { targetUser: coinsGrouping },
      },
    } = coinsData;

    const peopleIds = coinsGrouping
      .slice()
      .sort(
        (x: CoinGrouping, y: CoinGrouping) =>
          y.connection.aggregate.sum.amount - x.connection.aggregate.sum.amount
      )
      .map(({ key }: { key: string }) => key)
      .slice(0, 3);

    getPeople({
      variables: {
        ids: peopleIds.length == 0 ? ['-1'] : peopleIds,
      },
    });
  }, [coinsData]);

  const error = coinsError || peopleError;

  if (error) {
    return {
      data: null,
      loading: false,
      error,
    };
  }

  const loading = coinsLoading || peopleLoading;
  if (coinsData == null || loading || peopleData == null) {
    return {
      data: null,
      loading,
      error: null,
    };
  }

  const {
    currencyTransactionsConnection: {
      groupBy: { targetUser: coinsGrouping },
    },
  } = coinsData;

  return {
    data: buildLeaderBoard(peopleData.people, coinsGrouping),
    loading: false,
    error: null,
  };
};

export default useHallOfFame;
