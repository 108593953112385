import React from 'react';
import { shallow } from 'enzyme';
import Index from '../index';
import mockedProfile from '../../../../../../../__mocks__/common/profile';

jest.mock('@apollo/client', () => {
  const data = null;
  const loading = false;
  const error = false;
  return {
    __esModule: true,
    useQuery: jest.fn(() => ({ data, loading, error })),
  };
});

describe('User profile view', () => {
  let wrapper: ReturnType<typeof shallow>;
  const profile = mockedProfile;

  it('should render the screen', () => {
    const props = { profile, loading: false };
    wrapper = shallow(React.createElement(Index, props));
    expect(wrapper).toMatchSnapshot();
  });

  it('should render loading spinner', () => {
    const props = { profile, loading: true };
    wrapper = shallow(React.createElement(Index, props));
    expect(wrapper).toMatchSnapshot();
  });
});
