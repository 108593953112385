import React from 'react';
import { Redirect } from 'react-router';
import useCurrentprofile from '../hooks/use_current_profile';

const RedirectToProfile: React.FC = () => {
  const { userProfile } = useCurrentprofile();

  return !userProfile ? (
    <div>redirecting...</div>
  ) : (
    <Redirect to={'/u/' + userProfile.id} />
  );
};

export default RedirectToProfile;
